import React, { useState } from "react";
import { Grid, Input, Button, Box } from "@mui/material";
import CustomLink from "../CustomLink";
import westeropsIcon from "../../assets/westeropsIcon.svg";
import mobile from "../../assets/mobileIcon.svg";
import useFooterLogic from "../../hooks/useFooterLogic";
import landPhone from "../../assets/landPhoneIcon.svg";
import mail from "../../assets/mailIcon.svg";
import mapIcon from "../../assets/mapIcon.svg";
import submitIcon from "../../assets/submitIcon.svg";
import sertificate from "../../assets/footer/sertificate.svg";
import List from "@mui/material/List";
import { Container, Image, TopContainer } from "./style";
import CustomTypography from "../../components/CustomTypography";
import axios from "axios";
import { API_BASE_URL } from "../../constants/api";
import kobil from "../../assets/mainpage/kobilLogo.svg";
import { Modal, notifySuccess, notifyFailure } from "../../components/Modal";
import { useTranslation } from "react-i18next";
import iso9001 from "../../assets/footer/iso9001.svg";
import iso27001 from "../../assets/footer/iso27001.svg";

const Footer = () => {
  const { t, socialMedia, subPages } = useFooterLogic();
  const { i18n } = useTranslation();
  const [email, setEmail] = useState("");

  const sendForm = async () => {
    try {
      const response = await axios({
        url: API_BASE_URL + i18n.language + `/newsletter/subscribe/`,
        method: "POST",
        data: {
          email,
        },
      });
      notifySuccess();
      setEmail("");
    } catch (error) {
      notifyFailure();
      setEmail("");
    }
  };

  return (
    <Container>
      <TopContainer item container xs={11.5} lg={9.7}>
        <Grid item xs={10} lg={4} justifyContent="center">
          <Image src={westeropsIcon} alt="Westerops" title="Westerops Logo" />
        </Grid>
        <Modal />
        <Grid
          item
          xs={10}
          lg={8}
          display="flex"
          flexWrap="wrap"
          margin={{ xs: "0", md: "1.5rem 0" }}
        >
          {socialMedia.map(({ name, path, img, isSvg }) => (
            <Grid
              key={name}
              item
              xs={5}
              sm={3}
              md={2}
              lg={2}
              xl={1.5}
              margin={{ md: "0", xs: "1rem 0" }}
              width="auto"
            >
              {isSvg === true ? (
                <img
                  src={img}
                  alt="img"
                  onClick={() => window.open(path, "_blank").focus()}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <Box
                  item
                  className="buttons nav-link nav-link--mobile"
                  activeClassName="nav-link--active"
                  style={{
                    color: "white",
                    fontWeight: "700",
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    height: "auto",
                  }}
                  onClick={() => window.open(path, "_blank").focus()}
                >
                  {img}
                </Box>
              )}
              {/* <img src={instagram} alt="" /> */}
            </Grid>
          ))}
        </Grid>
      </TopContainer>

      <Grid
        item
        container
        md={11.5}
        lg={9.7}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* COMMUNICATION GRID */}
        <Grid item xs={11} md={6} lg={3} margin={"2rem 0"}>
          <h2
            style={{
              fontSize: "1.25rem",
              fontWeight: "semibold",
            }}
          >
            {" "}
            {t("footer.communication")}{" "}
          </h2>
          <Grid item>
            <CustomTypography
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              variant="mediumSubTitle"
            >
              <img
                src={mobile}
                style={{ marginRight: "1rem" }}
                alt="mobile"
                id="image"
              />{" "}
              +90 850 532 3600
            </CustomTypography>
          </Grid>
          <Grid item margin={"2rem 0"}>
            <CustomTypography
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              variant="mediumSubTitle"
            >
              <img
                src={landPhone}
                style={{
                  marginRight: "0.50rem",
                  width: "2.1rem",
                  height: "1.6rem",
                }}
                alt="mobile"
              />{" "}
              +90 212 257 9798
            </CustomTypography>
          </Grid>
          <Grid item margin={"2rem 0"}>
            <CustomTypography
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              variant="mediumSubTitle"
            >
              <img
                src={mail}
                style={{ marginRight: "1rem", height: "1.25rem" }}
                alt="mobile"
              />{" "}
              info@westerops.com
            </CustomTypography>
          </Grid>
          <Grid
            style={{ marginTop: "72px", alignItems: "center", display: "flex" }}
          >
            <img src={sertificate} alt="sertificate" />
            <img
              src={iso9001}
              alt="iso9001"
              style={{ width: 70, marginLeft: "1rem" }}
            />
            <img
              src={iso27001}
              alt="iso2701"
              style={{ width: 70, marginLeft: "1rem" }}
            />
          </Grid>
        </Grid>
        {/* LOCATİON GRID */}
        <Grid item xs={11} md={6} lg={3} margin={"2rem 0"}>
          <h2
            style={{
              fontSize: "1.25rem",
              fontWeight: "semibold",
            }}
          >
            {t("footer.offices")}
          </h2>
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              /* marginTop: "8%", */
            }}
          >
            <CustomTypography
              variant="smallText"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={mapIcon}
                alt="mapIcon"
                style={{ marginRight: "0.7rem" }}
              />
              Istanbul <br />
            </CustomTypography>
            <p>
              {" "}
              Etiler Mah. Toprakkale Sk. <br /> No:2/3 34330 Beşiktaş/İstanbul
            </p>
          </Grid>
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CustomTypography
              variant="smallText"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
              }}
            >
              <img
                src={mapIcon}
                alt="mapIcon"
                style={{ marginRight: "0.7rem" }}
              />
              {t("footer.London")} <br />
            </CustomTypography>
            <p>
              {" "}
              Studio 18 D1 Design District
              <br /> 13 Soames Walk London, SE10 0AX <br />
              United Kingdom
            </p>
          </Grid>
        </Grid>
        {/* MENU*/}
        <Grid item xs={11} md={6} lg={2} margin={"2rem 0"}>
          <h2
            style={{
              fontSize: "1.25rem",
              fontWeight: "semibold",
            }}
          >
            Menu
          </h2>
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "0px",
            }}
            className="mobile-nav-list"
          >
            {subPages.map(({ name, path }) => (
              <CustomLink
                key={name}
                className="buttons nav-link nav-link--mobile"
                activeClassName="nav-link--active"
                style={{
                  color: "white",
                  margin: "0 0 1rem 0",
                }}
                to={path}
              >
                {name}
              </CustomLink>
            ))}
          </List>
        </Grid>
        {/* CONNECTION GRID */}
        <Grid item xs={11} md={6} lg={4} margin={"2rem 0"}>
          <h2
            style={{
              fontSize: "1.25rem",
              fontWeight: "semibold",
            }}
          >
            {t("footer.title")}
          </h2>
          <Grid xs={8} style={{ marginTop: "2%" }}>
            <p>{t("footer.exp")}</p>
          </Grid>
          <Grid>
            <Input
              value={email}
              placeholder={t("footer.placeHolder")}
              sx={{
                backgroundColor: "white",
                padding: "0.5rem",
                borderRadius: "5px",
                width: "15rem",
                "& input::placeholder": {
                  fontSize: "12px",
                  paddingLeft: "10px",
                  fontWeight: "semibold",
                },
              }}
              width={{ xs: "10rem", md: "15rem" }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button>
              <img src={submitIcon} alt="submit" onClick={() => sendForm()} />
            </Button>
          </Grid>
          <Grid style={{ paddingTop: "1rem" }}>
            <img src={kobil} alt="kobil" />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
