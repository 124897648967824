import { Box, Grid } from "@mui/material";
import styled from "styled-components";

export const LogoBox = styled(Box)`
  #logo {
    width: 291px;
    height: 38px;
    @media (max-width: 1200px) {
      width: 235px;
      height: 28px;
    }
    @media (max-width: 900px) {
      width: 168px;
      height: 22px;
    }
  }
`;

export const TeamButton = styled(Box)`
  min-width: 5rem;
  cursor: pointer;
  color: #194491;
  border: 1px solid #194491;
  border-radius: 5px;
  width: 120px;
  height: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  &:hover {
    background-color: #194491;
    color: #fff;
  }
`;

export const GetOfferButton = styled(Box)`
  min-width: 5rem;
  cursor: pointer;
  color: #ffffff;
  background-color: #01d2d6;
  border-radius: 5px;
  width: 120px;
  height: 2.35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  margin-left: 20px;
  &:hover {
    background-color: #08c6c9;
    color: #194491;
  }
`;
export const LgDownGrid = styled(Grid)`
  width: 1516px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
`;
export const LanguageButton = styled(Box)`
  cursor: pointer;
  color: #ff744c;
  border: 1px solid #ff744c;
  border-radius: 5px;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  margin-left: 20px;
  min-width: 2rem;
  /* &:hover {
    background-color: #08c6c9;
    color: #194491;
  } */
`;
