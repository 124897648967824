import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { ArrowLeft, ArrowRight } from "../../assets/icons";
import {
  Container,
  GridContainer,
  LeftContainer,
  RightContainer,
  StyledButton,
  StyledTypography,
  Text,
  Wrapper,
  TitleText,
  DescTypography,
} from "./style";
import Slider from "react-slick";

const PortfolioSlider = ({ item, background }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const slider = useRef();
  const handleArrowLeft = () => {
    slider.current.slickPrev();
  };
  const handleArrowRight = () => {
    slider.current.slickNext();
  };
  const settings = {
    className: "center",
    infinite: true,
    slidesToShow: 1,
    dots: true,
    dotsClass: "slick-dots",
    arrows: false,
    slidesToScroll: 1,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid
      container
      backgroundColor={background}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Wrapper>
        <Container item>
          <Slider ref={slider} {...settings}>
            {item.Designs.map((_item, index) => (
              <GridContainer
                item
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                key={index}
                xs={11}
                md={12}
                lg={12}
              >
                <LeftContainer item md={5}>
                  <img
                    src={_item.design_logo}
                    alt="logo"
                    style={{
                      marginTop: "4rem",
                      height: "65px",
                      width: "auto",
                      objectFit: "fill",
                    }}
                  />
                  <TitleText item>
                    <StyledTypography
                      variant="largeText"
                      fontSize={{ lg: "2.5rem" }}
                      style={{ lineHeight: "56px" }}
                    >
                      <strong>{item.bold} </strong>
                      {item.name}
                      <br />
                      {item.name_two}
                    </StyledTypography>
                  </TitleText>
                  <Grid container>
                    {_item.Tags.map((tag, key) => (
                      <StyledButton
                        key={key}
                        variant="standard"
                        disabled
                        style={{
                          fontFamily: "Open Sans",
                          fontWeight: "semibold",
                          backgroundColor:
                            background === "#FFFFFF" ? "#EDF0F6" : "#FFFF",
                          marginRight: "1rem",
                        }}
                      >
                        {tag.name}
                      </StyledButton>
                    ))}
                  </Grid>
                  <Text item>
                    <DescTypography
                      variant="smallText"
                      color="#4a4a4a !important"
                      style={{
                        lineHeight: "32px",
                      }}
                    >
                      {_item.content}
                    </DescTypography>
                  </Text>
                </LeftContainer>
                <RightContainer
                  item
                  md={7}
                  margin={{ sm: "0 auto" }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid marginTop={{ xl: "5rem", lg: "2rem", md: "4rem" }}>
                    <img
                      src={_item.design_image}
                      alt="logo"
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </Grid>
                </RightContainer>
              </GridContainer>
            ))}
          </Slider>
          {item.Designs.length > 1 && (
            <Box sx={{ float: "right" }}>
              <ArrowLeft
                style={{ cursor: "pointer" }}
                onClick={handleArrowLeft}
                color={"#194591"}
              />
              <ArrowRight
                style={{ cursor: "pointer" }}
                onClick={handleArrowRight}
                color={"#194591"}
                marginLeft="20px"
              />
            </Box>
          )}
        </Container>
      </Wrapper>
    </Grid>
  );
};

export default PortfolioSlider;
