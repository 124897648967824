/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import Slider from "react-slick/lib/slider";
import { Container, Wrapper } from "./style";
import Slash from "../../assets/Slash";

import { ArrowLeft, ArrowRight } from "../../assets/icons";

function index({ images, color, title }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const slider = useRef();
  const handleArrowLeft = () => {
    slider.current.slickPrev();
  };
  const handleArrowRight = () => {
    slider.current.slickNext();
  };
  const settings = {
    className: "center",
    infinite: false,
    slidesToShow: 2,
    dots: true,
    dotsClass: "slick-dots",
    arrows: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 760,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 1.5,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 1.2,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 1,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    slider?.current.slickGoTo(0, true);
  }, [images]);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "40px",
        }}
      >
        <Slash color={color} />
        <Typography
          sx={{
            color: { color },
            fontSize: "16px",
            fontFamily: "Open Sans",
            fontWeight: "600",
            marginLeft: "1rem",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Wrapper>
        <Container>
          <Slider ref={slider} {...settings}>
            {images.map((item) => {
              return (
                <Grid
                  key={index}
                  sx={{
                    maxWidth: "360px",
                  }}
                >
                  <img
                    width={"100%"}
                    height={"auto"}
                    src={item.album_image}
                    alt="logo"
                    style={{
                      borderRadius: "4px",
                      maxHeight: "315px",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
              );
            })}
          </Slider>
        </Container>
        <Box
          sx={{
            float: "right",
            marginTop: "1rem",
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <ArrowLeft onClick={handleArrowLeft} color="#194591" />
          <ArrowRight
            onClick={handleArrowRight}
            color="#194591"
            marginLeft="20px"
          />
        </Box>
      </Wrapper>
    </>
  );
}

export default index;
