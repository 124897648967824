import { Grid } from "@mui/material";
import styled from "styled-components";

export const BenefitLayout = styled.div`
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  gap: 33px;
`;

export const EmployeeBenefitsContainer = styled(Grid)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 1516px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 0rem 2rem 0;
  }
`;

export const EmployeeCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 15rem;
  height: 12rem;
  padding: 1rem;
  text-align: left;
  border: 1px solid white;
  border-radius: 0.5rem;
  background-color: #fff !important;
  box-shadow: ${({ theme }) => theme.main.shadowCard};

  h3 {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: bold;
    color: #ff744c;
    margin-bottom: 0;
  }
  p {
    align-items: flex-start;
    font-family: Open Sans;
    font-size: 14px;
    color: #194591;
  }
`;
