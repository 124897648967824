import { Grid } from "@mui/material";
import styled from "styled-components";
import { between } from "polished";
import Card from "../../../components/Card";

export const CareerCardStyles = styled(Card)`
  height: 255px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 30px;
  border-radius: 8px;
  .button {
    margin: 0;
    margin-top: 20px;
    svg {
      transition: transform 0.15s ease-in;
      margin-left: 10px;
    }
    &:hover {
      svg {
        transform: translateX(10px);
      }
    }
  }
`;
export const CardHeader = styled(Grid)`
  .customize-button {
    background-color: #e6f0f6 !important;
    color: ${({ theme }) => theme.main.secondaryColor} !important;
    font-size: 12px !important;
    line-height: 12px;
    padding-left: ${between("5px", "15px")};
    padding-right: ${between("5px", "15px")};

    margin: 0px;
    &:hover {
      color: ${({ theme }) => theme.main.secondaryColor} !important;
      background-color: #fff !important;
    }
  }
`;

export const CardContext = styled(Grid)``;

export const CardFooter = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
