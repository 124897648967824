import { Box, styled } from "@mui/material";

export const CustomGrid = styled(Box)`
  margin-right: 31px;
  @media (min-width: 1200px) {
    margin-right: 5rem;
  }
  @media (min-width: 1535px) {
    margin-right: 12rem;
  }
`;
