/* eslint-disable no-unused-vars */

import React from "react";
import { useTranslation } from "react-i18next";
import ContentContainer from "../../../../components/ContentContainer";
import PageTitle from "../../../../components/PageTitle";
import theme from "../../../../theme";
import { CareerHeader } from "../styles";
import Slash from "../../../../assets/Slash";
import {
  CultureLayout,
  EmployeeCard,
  MdDownContainer,
  MdUpContainer,
} from "./styles";
import { Box, Grid, Hidden } from "@mui/material";
import CompanyCultureCarousel from "../../../../components/CompanyCultureCarousel";

const CompanyCulture = ({ description, title, companyCultureData, vision }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Hidden lgDown>
        <Box
          style={{ backgroundColor: "#f6f6f6" }}
          paddingTop="4rem"
          paddingBottom="4rem"
        >
          <MdDownContainer item container>
            <Grid>
              <PageTitle
                title={title}
                alignCenter
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              />
              <CareerHeader>
                <Slash color={"#19BDFB"} />
                <Hidden lgDown>
                  <p
                    style={{
                      color: "#194591",
                      justifyContent: "center",
                      fontFamily: "Open Sans",
                      fontSize: "20px",
                      lineHeight: "32px",
                      textAlign: "left",
                      width: "100%",
                      margin: "0 0 0 10px",
                    }}
                  >
                    {description}
                  </p>
                </Hidden>
                <Hidden lgUp>
                  <p
                    style={{
                      justifyContent: "center",
                      color: "#194591",
                      fontFamily: "Open Sans",
                      lineHeight: "26px",
                      fontSize: "16px",
                      textAlign: "left",
                      width: "100%",
                      margin: "0 0 0 10px",
                    }}
                  >
                    {description}
                  </p>
                </Hidden>
              </CareerHeader>
            </Grid>
            <CultureLayout container>
              {companyCultureData?.map((item) => (
                <EmployeeCard item key={item.id}>
                  <Box sx={{ height: "100%" }}>
                    <h3>{item.logo_title}</h3>
                    <p>{item.logo_description}</p>
                  </Box>
                </EmployeeCard>
              ))}
            </CultureLayout>
            <Grid>
              <PageTitle
                title={vision?.[0].title}
                alignCenter
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              />
              <CareerHeader>
                <Slash color={"#19BDFB"} />
                <Hidden lgDown>
                  <p
                    style={{
                      justifyContent: "center",
                      fontFamily: "Open Sans",
                      fontSize: "20px",
                      lineHeight: "32px",
                      textAlign: "left",
                      color: "#194591",
                      width: "100%",
                      margin: "0 0 0 10px",
                    }}
                  >
                    {vision?.[0].description}
                  </p>
                </Hidden>
                <Hidden lgUp>
                  <p
                    style={{
                      justifyContent: "center",
                      fontFamily: "Open Sans",
                      lineHeight: "26px",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#194591",
                      width: "100%",
                      margin: "0 0 0 10px",
                    }}
                  >
                    {vision?.[0].description}
                  </p>
                </Hidden>
              </CareerHeader>
            </Grid>
          </MdDownContainer>
        </Box>
      </Hidden>
      <Hidden lgUp>
        <MdUpContainer style={{ backgroundColor: "#f6f6f6" }} item container>
          <Grid padding={{ xs: "1.5rem 2rem 0rem" }}>
            <PageTitle
              title={title}
              alignCenter
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            />
            <CareerHeader>
              <Slash color={"#19BDFB"} />
              <p
                style={{
                  justifyContent: "center",
                  fontFamily: "Open Sans",
                  lineHeight: "22px",
                  fontSize: "14px",
                  color: "#194591",
                  width: "100%",
                  margin: "0 0 0 10px",
                }}
              >
                {description}
              </p>
            </CareerHeader>
          </Grid>
          <CompanyCultureCarousel companyCultureData={companyCultureData} />

          <Grid padding={{ xs: "4rem 2rem 3.5rem" }}>
            <PageTitle
              title={vision?.[0].title}
              alignCenter
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            />
            <CareerHeader>
              <Slash color={"#19BDFB"} />

              <p
                style={{
                  justifyContent: "center",
                  fontFamily: "Open Sans",
                  lineHeight: "22px",
                  color: "#194591",
                  fontSize: "14px",
                  width: "100%",
                  margin: "0 0 0 10px",
                }}
              >
                {vision?.[0].description}
              </p>
            </CareerHeader>
          </Grid>
        </MdUpContainer>
      </Hidden>
    </>
  );
};

export default CompanyCulture;
