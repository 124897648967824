/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../constants/api";
import PortfolioSlider from "../../components/PorffolioSlider";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import Loading from "../../components/Loading";

const Portfolio = () => {
  const [portfolio, setPortfolio] = useState();
  console.log("portfolio", portfolio);
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${API_BASE_URL}${i18n.language}/portfolio/maindesign/${
          i18n.language === "tr" ? "listTr/" : "listEn/"
        }`
      )
        .then((res) => res.json())
        .then((result) => {
          setPortfolio(result);
        });
    };
    fetchData();
  }, [i18n.language]);

  return (
    <Grid
      container
      sx={{
        margin: "6rem auto 0 auto",
      }}
    >
      <AnimatePresence>
        {!portfolio && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 1 }}
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              width: "1000vw",
              height: "1000vw",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: "999999999",
            }}
          >
            <Loading />
          </motion.div>
        )}
      </AnimatePresence>
      {portfolio?.map((item, index) => (
        <PortfolioSlider
          key={index}
          background={index % 2 === 0 ? "#FFFFFF" : "#edf0f6"}
          item={item}
        />
      ))}
    </Grid>
  );
};

export default Portfolio;
