import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Hidden } from "@mui/material";
import {
  Container,
  ServiceCard,
  ServicesContainer,
  ServicesText,
  ServicesTitle,
  SubTitle,
  SubText,
  SubImage,
  UpGrid,
  DownGrid,
  MyBox,
} from "./style";
import { API_BASE_URL } from "../../../../constants/api";
import ImageCarousel from "../../../../components/ImageCarousel";
import { useLocation, useNavigate } from "react-router-dom";

const ServicesSlider = () => {
  const { i18n, t } = useTranslation();
  const [services, setServices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `${API_BASE_URL}${i18n.language}/project/ourservices/${
        i18n.language === "tr" ? `listTr/` : "listEn/"
      }`
    )
      .then((res) => res.json())
      .then((result) => {
        setServices(result);
      });
  }, [i18n.language]);

  return (
    <Container container>
      <ServicesContainer item container>
        <ServicesTitle variant="mediumTitle">
          {t("ourServices.title")}
        </ServicesTitle>
        <ServicesText variant="mediumSubTitle">
          {" "}
          {t("ourServices.text")}
        </ServicesText>
        <Hidden lgDown>
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <UpGrid container>
              {services
                .filter((item) => item.sequence < 4)
                .map((item, index) => (
                  <ServiceCard
                    key={index}
                    item
                    container
                    index
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/services", { state: { cardIndex: index } })
                    }
                  >
                    <SubImage src={item.services_image} alt="subLogo" />
                    <SubTitle variant="smallTitle">{item.title}</SubTitle>
                    <SubText variant="smallText">{item.raw_content}</SubText>
                  </ServiceCard>
                ))}
            </UpGrid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <DownGrid container justifyContent="flex-end">
              {services
                .filter((item) => item.sequence > 3)
                .map((item, index) => (
                  <ServiceCard
                    key={index}
                    item
                    container
                    index
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate("/services", { state: { cardIndex: index + 3 } })
                    }
                  >
                    <SubImage src={item.services_image} alt="subLogo" />
                    <SubTitle variant="smallTitle">{item.title}</SubTitle>
                    <SubText variant="smallText">{item.raw_content}</SubText>
                  </ServiceCard>
                ))}
            </DownGrid>
          </Grid>
        </Hidden>
        <Hidden lgUp>
          <ImageCarousel slides={services} />
        </Hidden>
      </ServicesContainer>
    </Container>
  );
};

export default ServicesSlider;
