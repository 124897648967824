import styled from "styled-components";
import { between } from "polished";
import { Grid, Button } from "@mui/material";

export const JoinUsContainer = styled(Grid)`
  display: flex;
  width: 1516px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem;
  }
`;
export const CareerStyles = styled(Grid)`
  padding-top: 120px;
`;

export const SelectTitleBar = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: scroll;
`;

export const CareerHeader = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";
  font-size: 34px;

  .sentence-image {
    width: 35px;
    height: 65px;
  }
  h3 {
    color: rgb(35, 63, 141);
    font-size: 34px;
    font-family: "Open Sans";
    font-weight: bold;
    margin: 20px;
  }
`;
export const CareerFooter = styled(Grid)`
  padding-top: ${between("10px", "200px")};
`;
export const CareerContent = styled(Grid)`
  min-height: 66vh;
  &&& {
    padding-top: ${between("10px", "25px")};
    padding-left: ${between("5px", "250px")};
    padding-right: ${between("5px", "250px")};
  }
`;

export const Title = styled(Button)`
  color: rgb(35, 63, 141);
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: bold;
  margin: 15px;
  border: none;
  background-color: initial;
`;
