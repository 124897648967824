import { Box, Grid, Radio, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Container } from "../../style";
import { MyTextField, CustomGrid } from "./style";
import slash from "../../../../assets/getOffer/slash.svg";
import DatePicker from "../../../../components/DatePicker";
import { MoreButton } from "../../../Blogs/BlogCard/styles";
import { ArrowRight } from "../../../../assets/icons";
import { useTranslation } from "react-i18next";
import form from "../../../../assets/form.svg";
import axios from "axios";
import { API_BASE_URL } from "../../../../constants/api";
import { notifySuccess, notifyFailure } from "../../../../components/Modal";
import UnCheckIcon from "../../../../assets/UnCheckedIcon";
import CheckedIcon from "../../../../assets/CheckedIcon";

const ServicesComponent = ({
  data,
  requestData,
  setRequestData,
  onChangeText,
  name,
  surname,
  email,
  phone,
  company,
  position,
  message,
  handleName,
  handleSurname,
  handleEmail,
  handlePhone,
  handleCompany,
  handlePosition,
  handleMessage,
  setDate,
  date,
  variant,
  ifConsultancy,
}) => {
  const handleChange = (id) => {
    let temp = [...requestData];
    temp[11] = id;
    setRequestData(temp);
  };
  const { i18n, t } = useTranslation();
  const sendForm = async () => {
    try {
      await axios({
        url: API_BASE_URL + i18n.language + `/estimate/createConsUserData/`,
        method: "POST",
        data: {
          email,
          name,
          surname,
          company,
          position,
          phone,
          projectDetail: message,
          data: [requestData[11]],
          date: date.toISOString(),
        },
      });
      notifySuccess();
    } catch (error) {
      console.log("ERROR: ", error);
      if (error?.response?.data?.error)
        notifyFailure(error?.response?.data?.error);
      else if (error?.response?.data?.email)
        notifyFailure(error?.response?.data?.email[0]);
      else if (error?.response?.data?.data)
        notifyFailure(error?.response?.data?.data[0]);
    }
  };

  return (
    <Grid container xl={12} style={{ margin: "2rem auto 0 auto" }}>
      <Grid style={{ display: "flex", flexDirection: "column" }}>
        <Container
          container
          style={{
            marginTop: "4rem",
            width: "100%",
            borderBottom: "2px solid #194591",
          }}
        >
          <img src={slash} alt="slash" />
          <h1
            style={{ color: "#194591", fontSize: "30px", marginLeft: "1rem" }}
          >
            {data?.name}
          </h1>
        </Container>
        <Grid
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              color: "#194591",
              fontSize: "18px",
              marginTop: "2.5rem",
              marginBottom: "2rem",
            }}
          >
            {data?.sub_steps?.[0]?.name}
          </span>
          <Grid container style={{ display: "flex", flexDirection: "row" }}>
            {data?.sub_steps?.[0]?.data.map((item, index) => (
              <Grid
                item
                lg={3}
                md={8}
                sm={10}
                xs={12}
                key={index}
                style={{
                  width: "16rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Radio
                    icon={<UnCheckIcon />}
                    checkedIcon={<CheckedIcon />}
                    checked={requestData[11] === item.id}
                    onChange={() => handleChange(item.id)}
                    value="b"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "B" }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 40,
                        color: "#194591",
                      },
                    }}
                  />
                </Box>
                <Box item lg={3}>
                  <span
                    style={{
                      color:
                        requestData[11] === item.id ? "#FF744C" : "#194591",
                    }}
                  >
                    {item.name}
                  </span>
                </Box>
              </Grid>
            ))}
          </Grid>
          <span
            style={{
              color: "#194591",
              fontSize: "18px",
              marginTop: "4rem",
              marginBottom: "2rem",
            }}
          >
            {data?.sub_steps?.[1]?.name}
          </span>
          <Grid
            sx={{
              border: "2px solid #194591",
              padding: "1rem",
            }}
          >
            <MyTextField
              placeholder={t("project.writePlease")}
              sx={{ width: "100%" }}
              variant="standard"
              multiline
              lg={12}
              sm={12}
              md={12}
              xs={12}
              rows={4}
              onChange={onChangeText}
            ></MyTextField>
          </Grid>
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              marginTop: "2rem",
            }}
          >
            <img style={{ marginTop: "36px" }} src={slash} alt="slash" />
            <p
              style={{
                fontSize: variant === "desktop" ? "26px" : "20px",
                fontStyle: "italic",
                color: "#194591",
                fontWeight: "normal",
                paddingLeft: "1rem",
                paddingTop: "1rem",
              }}
            >
              {t("project.formContent")}
            </p>
          </Grid>
          <CustomGrid>
            <Grid lg={10} sx={{ width: "100%" }}>
              <Grid
                item
                spacing={6}
                lg={12}
                paddingBottom={3}
                sx={{ display: "flex", gap: "8px" }}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{ width: "100%" }}
                    label={t("getOffer.Name")}
                    value={name}
                    variant="outlined"
                    onChange={handleName}
                    alignItems="center"
                    InputProps={{ style: { fontSize: "14px" } }}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                  ></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    sx={{ width: "100%" }}
                    label={t("getOffer.Surname")}
                    value={surname}
                    variant="outlined"
                    onChange={handleSurname}
                    InputProps={{ style: { fontSize: "14px" } }}
                    InputLabelProps={{ style: { fontSize: "12px" } }}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid item paddingBottom={3} lg={12}>
                <MyTextField
                  sx={{
                    width: "100%",
                    backgroundColor: "white",
                    marginTop: "1rem",
                  }}
                  label={t("getOffer.Eposta")}
                  variant="outlined"
                  onChange={handleEmail}
                  InputProps={{ style: { fontSize: "14px" } }}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                ></MyTextField>
              </Grid>
              <Grid item paddingBottom={3} lg={12}>
                <TextField
                  sx={{ width: "100%", marginTop: "1rem" }}
                  label={t("getOffer.PhoneNumber")}
                  variant="outlined"
                  onChange={handlePhone}
                  InputProps={{ style: { fontSize: "14px" } }}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                ></TextField>
              </Grid>
              <Grid item paddingBottom={3} lg={12}>
                <TextField
                  sx={{ width: "100%", marginTop: "1rem" }}
                  label={t("getOffer.Company")}
                  variant="outlined"
                  onChange={handleCompany}
                  InputProps={{ style: { fontSize: "14px" } }}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                ></TextField>
              </Grid>
              <Grid item paddingBottom={3} lg={12}>
                <TextField
                  sx={{ width: "100%", marginTop: "1rem" }}
                  label={t("getOffer.Position")}
                  variant="outlined"
                  onChange={handlePosition}
                  InputProps={{ style: { fontSize: "14px" } }}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                ></TextField>
              </Grid>
              <Grid item paddingBottom={1} lg={12}>
                <TextField
                  label={t("getOffer.Write")}
                  sx={{ width: "100%", marginTop: "1rem" }}
                  variant="outlined"
                  multiline
                  rows={4}
                  onChange={handleMessage}
                  InputProps={{ style: { fontSize: "14px" } }}
                  InputLabelProps={{ style: { fontSize: "12px" } }}
                ></TextField>
              </Grid>
            </Grid>
            <Grid sx={{ width: "100%" }}>
              <DatePicker setDate={setDate} date={date} />
            </Grid>
          </CustomGrid>
        </Grid>
      </Grid>
      {ifConsultancy === true && (
        <MoreButton
          type="primary"
          style={{
            display: "flex",
            alignSelf: "end",
            width: "387px",
            height: "48px",
            marginTop: "5rem",
            float: "right",
          }}
          onClick={() => sendForm()}
        >
          <img src={form} alt="send-form" />{" "}
          {t("getOffer.consultancyFormButton")} <ArrowRight marginLeft />
        </MoreButton>
      )}
    </Grid>
  );
};

export default ServicesComponent;
