import { Box, Grid } from "@mui/material";
import styled from "styled-components";
import theme from "../../theme";
import CustomTypography from "../CustomTypography";

export const Wrapper = styled(Box)`
  width: 100%;
  display: flex;

  .slick-slide > div {
    /* margin: 0 4.5rem 0 -3.5rem; */
    margin: 0 2rem 0 0;
  }
`;

export const Container = styled(Box)`
  width: 98%;
  .slick-dots li {
    width: 1rem;
  }

  .slick-dots li button::before {
    color: #c4c4c4;
    font-size: 1rem;
    opacity: 1;
  }

  .slick-dots li.slick-active button::before {
    color: #194591;
    opacity: 1;
  }
  .slick-dots li {
    margin: 0;
    padding: 0;
  }
  .slick-dots {
    list-style-type: circle;
    width: fit-content;
    bottom: -25px;
    margin-left: 31px;
  }
`;

export const ServiceCard = styled(Grid)`
  position: relative;
  margin: 1.5rem 0rem 2rem 1.5rem;
  background-color: ${theme.main.white};
  height: 14rem;
  border-radius: 5px;
  filter: drop-shadow(0.5rem 0.5rem 0.5rem #bfbfbf);
`;

export const SubTitle = styled(CustomTypography)`
  color: ${theme.main.blue};
  margin: 1.5rem 1.5rem 0.5rem;
  font-weight: 700;
  line-height: 30px;
  font-size: 1rem;
  color: #19bdfb;
`;

export const SubText = styled(CustomTypography)`
  color: ${theme.main.blue};
  margin: 0rem 1.5rem 0.5rem;
  font-size: 12px;
  line-height: 20px;
`;

export const SubImage = styled.img`
  position: absolute;
  top: -30%;
  left: 15%;
`;
