import { Grid, styled } from "@mui/material";

export const GetOfferDateArea = styled(Grid)`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;
