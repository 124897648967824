import styled from "styled-components";
import { Grid, Button } from "@mui/material";
import CustomTypography from "../../../../components/CustomTypography";

export const Container = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1516px;
  margin: 8rem auto 0 auto;
  @media (max-width: 1516px) {
    width: 71rem;
  }

  @media (max-width: 1200px) {
    max-width: 100%;
    padding: 0 31px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const LeftContainer = styled(Grid)`
  @media (max-width: 1200px) {
    max-width: 100%;
  }
`;

export const RightContainer = styled(Grid)`
  max-width: 50%;
  object-fit: contain;
  @media (max-width: 1200px) {
    max-width: 100%;
    margin: 0 auto;
  }
`;

export const Text = styled(Grid)`
  margin: 1rem 0;
  background: #194591;
  border-radius: 6px;
  color: #fff;
  padding: 1rem;
  width: max-content;
`;

export const StyledTypography = styled(CustomTypography)`
  font-size: 2.25rem;
  @media (max-width: 900px) {
    font-size: 2rem;
  }
  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
  @media (max-width: 460px) {
    font-size: 1rem;
  }
`;

export const ButtonContainer = styled(Grid)`
  margin: 2.5rem 0;
`;

export const HeadBannerButton = styled(Button)`
  font-size: 14px;
  background-color: #ff744c;
  color: #fff;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
  &:hover {
    background-color: #ff744c;
    opacity: 0.8;
  }
  font-family: "Open Sans";
`;
