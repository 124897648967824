import { Grid, Hidden } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import BreadCrumbsMobile from "../../../../components/BreadCrumbsMobile";
import {
  MdDownJourneyContainer,
  MdUpJourneyContainer,
  ServicesTitle,
} from "./style";

const ProjectJourney = () => {
  const { t } = useTranslation();

  return (
    <Grid container backgroundColor="#f6f6f6">
      <Hidden mdDown>
        <MdDownJourneyContainer margin={{ md: "4rem auto" }} item container>
          <ServicesTitle variant="mediumTitle">
            {t("projectJourney.title")}
          </ServicesTitle>
          <BreadCrumbs />
        </MdDownJourneyContainer>
      </Hidden>
      <Hidden mdUp>
        <MdUpJourneyContainer margin={{ xs: "2rem 0" }} item container>
          <ServicesTitle sx={{ paddingLeft: "31px" }} variant="mediumTitle">
            {t("projectJourney.title")}
          </ServicesTitle>
          <BreadCrumbsMobile />
        </MdUpJourneyContainer>
      </Hidden>
    </Grid>
  );
};

export default ProjectJourney;
