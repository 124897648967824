import { Typography } from "@mui/material";
import React from "react";
import "./style.css";

const defaultStyle = {
  fontSize: { xs: "1.5rem", md: "2rem" },
  lineHeight: "1.17",
};

const transformVariant = (variant) => {
  switch (variant) {
    case "text":
    case "largeText":
    case "smallText": {
      return undefined;
    }
    default:
      return variant;
  }
};

function CustomTypography(props) {
  const {
    variant,
    color,
    letterSpacing,
    className,
    fontFamily,
    children,
    ...rest
  } = props;

  const getStyleByVariant = {
    text: {
      fontSize: { xs: "1.125rem", md: "1.5rem" },
    },
    title: {
      fontSize: { xs: "1.25rem", md: "2.5rem" },
      fontWeight: "700",
    },
    subTitle: {
      fontSize: { xs: "1rem", md: "1.875rem" },
    },
    mediumTitle: {
      fontSize: { xs: "1.25rem", md: "2rem" },
      fontWeight: "700",
    },
    mediumSubTitle: {
      fontSize: { xs: "0.875rem", md: "1rem" },
    },
    largeBoldText: {
      fontSize: { xs: "1.25rem", md: "2.25rem" },
      fontWeight: "700",
    },
    largeText: {
      fontSize: { xs: "1.25rem", md: "2.25rem" },
    },
    smallTitle: {
      fontSize: { xs: "1rem", md: "1.125rem" },
      fontWeight: "700",
    },
    smallText: {
      fontSize: { xs: "0.875rem", md: "1rem" },
    },
    careerDetailXLarge: {
      fontSize: { xs: "20px", md: "34px" },
    },
    careerDetailLarge: {
      fontSize: { xs: "16px", md: "24px" },
    },
    careerDetailMedium: {
      fontSize: { xs: "12px", md: "16px" },
    },
    careerDetailSubTitle: {
      fontSize: { xs: "16px", md: "20px" },
    },
    careerDetailDesc: {
      lineHeight: { xs: "20px", md: "32px" },
      fontSize: { xs: "14px", md: "20px" },
    },
    breadCrumbSequence: {
      fontSize: { lg: "50px", md: "39px", xl: "60px" },
    },
    breadCrumbTitle: {
      fontSize: { lg: "22px", md: "18px", xl: "30px" },
    },
    breadCrumbContent: {
      fontSize: { md: "14px", lg: "16px", xl: "20px" },
    },
    MPTechPartnersSeeMore: {
      fontSize: { xs: "12px", lg: "16px", md: "14px", xl: "20px" },
      lineHeight: { xs: "20px", lg: "28px", md: "25px", xl: "30px" },
    },
    MPTechPartnersDescription: {
      fontSize: { xs: "12px", lg: "16px", md: "14px", xl: "20px" },
      width: { xs: "47%", sm: "54%", lg: "65%", md: "62%", xl: "70%" },
      lineHeight: { xs: "20px", lg: "28px", md: "25px", xl: "30px" },
    },
    techPartnersContent: {
      fontSize: { xs: "14px", lg: "16px", md: "14px", xl: "20px" },
      lineHeight: { xs: "22px", lg: "28px", md: "25px", xl: "32px" },
    },
    techPartnersContentTitle: {
      fontSize: { xs: "14px", lg: "16px", md: "14px", xl: "20px" },
      lineHeight: { xs: "22px", lg: "28px", md: "25px", xl: "32px" },
      fontWeight: "bold",
    },
    careerCardTitle: {
      fontFamily: "Open Sans",
      fontSize: "20px",
      color: "#194591",
      fontWeight: "bold",
      lineHeight: "28px",
    },
    careerCardLocation: {
      fontFamily: "Open Sans",
      fontSize: "14px",
      color: "#7F7F7F",
      lineHeight: "40px",
      whiteSpace: "nowrap",
    },
  };

  return (
    <Typography
      variant={transformVariant(variant)}
      color={color || "white"}
      fontFamily={
        "Open sans, sans-serif" || fontFamily
      } /* HelveticaNeueLT Pro Lt */
      letterSpacing={letterSpacing || 0}
      className={className}
      {...defaultStyle}
      {...getStyleByVariant[variant]}
      {...rest}
    >
      {children}
    </Typography>
  );
}

export default CustomTypography;
