import styled from "styled-components";
import { Grid, Button } from "@mui/material";
import CustomTypography from "../../../../components/CustomTypography";

export const Container = styled(Grid)`
  width: 1516px;
  margin: 6rem auto 0 auto;
  @media (max-width: 1516px) {
    width: 71rem;
    padding-bottom: 3rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem;
  }
`;

export const LeftContainer = styled(Grid)`
  justify-content: center;
  align-items: center;
  padding: 4rem 0rem 0 0rem;
  width: 100%;
  @media (max-width: 768px) {
    padding: 1rem 0rem;
  }
  @media (max-width: 600px) {
    padding: 0.5rem 0rem;
  }
`;

export const Text = styled(Grid)`
  background: ${(props) => props.background};
  border-radius: 6px;
  color: #fff;
  padding: ${(props) => props.padding}rem;
  width: max-content;
  margin: 3rem 0 0 0;
  @media (max-width: 768px) {
    margin: 2rem 0 0 0;
  }
  @media (max-width: 600px) {
    margin: 1.5rem 0 0 0;
  }
`;
export const StyledTitle = styled(CustomTypography)`
  font-size: 44px;
  padding: 5px 20px;
  white-space: nowrap;
  width: 100%;
  /* @media (max-width: 1024px) {
    font-size: 18px;
  } */
  @media (max-width: 900px) {
    font-size: 36px;
  }
  @media (max-width: 600px) {
    font-size: 28px;
  }
`;

export const StyledContext = styled(CustomTypography)`
  font-size: 20px;
  line-height: 28px;
  @media (max-width: 1535px) {
    line-height: 26px;
    font-size: 18px;
  }
  @media (max-width: 1200px) {
    line-height: 22px;
    font-size: 16px;
  }
  @media (max-width: 600px) {
    line-height: 22px;
    font-size: 14px;
  }
`;

export const ButtonContainer = styled(Grid)`
  margin: 10px 0;
`;

export const HeadBannerButton = styled(Button)`
  font-size: 14px;
  background-color: #ff744c;
  color: #fff;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
`;

export const RightContainer = styled(Grid)``;
