/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../../../constants/api";
import PortfolioSliderMainPage from "../../../../components/PortfolioSliderMainPage";
import { useTranslation } from "react-i18next";

function MainPagePortfolioSlider() {
  const [portfolio, setPortfolio] = useState([]);
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${API_BASE_URL}${i18n.language}/portfolio/maindesign/${
          i18n.language === "tr" ? `listTr/` : "listEn/"
        }`
      )
        .then((res) => res.json())
        .then((result) => {
          setPortfolio(result);
        });
    };
    fetchData();
  }, [i18n.language]);

  return (
    <Grid container>
      <PortfolioSliderMainPage background={"#edf0f6"} portfolio={portfolio} />
    </Grid>
  );
}

export default MainPagePortfolioSlider;
