import React from "react";

function Slash({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="30.565"
      viewBox="0 0 16 30.565"
    >
      <path
        id="Path_141460"
        data-name="Path 141460"
        d="M331.922,31.952a2.977,2.977,0,0,1-2.7-4.233L339.268,3.11a2.977,2.977,0,0,1,5.4,2.511L334.624,30.229a2.978,2.978,0,0,1-2.7,1.723"
        transform="translate(-328.946 -1.387)"
        fill={color}
      />
    </svg>
  );
}

export default Slash;
