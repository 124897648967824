import Slider from "react-slick";
import {
  Container,
  Wrapper,
  ServiceCard,
  SubImage,
  SubTitle,
  SubText,
} from "./style";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ArrowLeft, ArrowRight } from "../../assets/icons";
import { useRef } from "react";

const ImageCarousel = ({ slides }) => {
  const slider = useRef();
  const handleArrowLeft = () => {
    slider.current.slickPrev();
  };
  const handleArrowRight = () => {
    slider.current.slickNext();
  };
  const navigate = useNavigate();
  const settings = {
    className: "center",
    infinite: true,
    slidesToShow: 3,
    dots: true,
    dotsClass: "slick-dots",
    arrows: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          className: "center",
          infinite: true,
          slidesToShow: 3,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          className: "center",
          infinite: true,
          slidesToShow: 2.5,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 910,
        settings: {
          className: "center",
          infinite: true,
          slidesToShow: 2,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          className: "center",
          infinite: true,
          slidesToShow: 1.5,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          className: "center",
          infinite: true,
          slidesToShow: 1.3,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 505,
        settings: {
          className: "center",
          infinite: true,
          slidesToShow: 1,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Wrapper>
      <Container>
        <Slider ref={slider} {...settings}>
          {slides.map((item, index) => (
            <ServiceCard
              key={index}
              onClick={() =>
                navigate("/services", { state: { cardIndex: index } })
              }
            >
              <SubImage src={item.services_image} alt="subLogo" />
              <SubTitle> {item.title} </SubTitle>
              <SubText variant="smallText">{item.raw_content}</SubText>
            </ServiceCard>
          ))}
        </Slider>
        <Box sx={{ float: "right" }}>
          <ArrowLeft
            style={{ cursor: "pointer" }}
            onClick={handleArrowLeft}
            color={"#194591"}
          />
          <ArrowRight
            style={{ cursor: "pointer" }}
            onClick={handleArrowRight}
            color={"#194591"}
            marginLeft="20px"
          />
        </Box>
      </Container>
    </Wrapper>
  );
};

export default ImageCarousel;
