import { Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import styled from "styled-components";
import theme from "../../theme";

export const StyledTab = styled(Tab)`
  text-transform: none;
  border: 2px solid #194591;
  border-bottom: 0px;
  color: ${theme.main.blue};
  font-weight: bold;
  border-radius: 8px 8px 0 0;
  filter: ${({ selected }) => (selected ? "grayscale(0)" : "grayscale(1)")};
  background-color: ${({ selected }) => (selected ? "#FFFFFF" : "#EDF0F6")};
  height: ${({ selected }) => (selected ? "120px" : "100px")};
  width: 277px;

  @media (max-width: 1200px) {
    height: ${({ selected }) => (selected ? "100px" : "80px")};
    width: 200px;
  }
  @media (max-width: 900px) {
    height: ${({ selected }) => (selected ? "78px" : "68px")};
  }
  @media (max-width: 600px) {
    width: 143px;
    height: ${({ selected }) => (selected ? "68px" : "60px")};
  }
`;

export const CustomTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    align-items: flex-end;
    gap: 2rem;

    @media (max-width: 768px) {
      gap: 1.5rem;
    }
    @media (max-width: 600px) {
      gap: 1rem;
    }
  }
  .MuiTabs-indicator {
    height: 0px;
  }
`;
