import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: fit-cover;
  justify-content: ${({ alignCenter }) =>
    alignCenter ? "center" : "flex-start"};
  width: fit-cover;
  padding-left: ${({ defaultPadding, theme }) =>
    defaultPadding ? theme.pageTitle.paddingHorizontal : "0"};

  .title {
    font-size: 2.5vw;
    line-height: ${({ noLineHeight }) => (noLineHeight ? "0px" : "70px")};
    color: ${({ color, theme }) => color || theme.main.secondaryColor};
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 700;
    margin-bottom: ${({ noMargin }) => (noMargin ? "0px" : "1vw")};
    margin-top: ${({ noMargin }) => (noMargin ? "0px" : "0px")};

    @media (max-width: 992px) {
      font-size: 17px;
    }
  }
`;
