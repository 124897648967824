import React from "react";
import PageTitleContainer from "./styles";

const PageTitle = ({
  defaultPadding,
  alignCenter,
  color,
  title,
  noMargin,
  noLineHeight,
  children,
  style,
}) => (
  <PageTitleContainer
    noLineHeight={noLineHeight}
    noMargin={noMargin}
    defaultPadding={defaultPadding}
    alignCenter={alignCenter}
    color={color}
    style={style}
  >
    <p style={{ fontFamily: "Open Sans" }} className="title">
      {title}
    </p>
    {children}
  </PageTitleContainer>
);

export default PageTitle;
