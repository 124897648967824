import Slider from "react-slick";
import { Container, Wrapper, ServiceCard, ProcessTitle } from "./style";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Grid } from "@mui/material";
import { useEffect, useRef } from "react";
import SliderArrows from "../SliderArrows";

const ProcessCarousel = ({ processData }) => {
  const settings = {
    className: "center",
    infinite: false,
    slidesToShow: 2,
    dots: true,
    dotsClass: "slick-dots",
    arrows: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 580,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 1.3,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slider = useRef();
  useEffect(() => {
    slider?.current.slickGoTo(0, true);
  }, [processData]);
  return (
    <Wrapper>
      <Container>
        <Slider ref={slider} {...settings}>
          {processData?.[0]?.OurRecruitmentsProcesss?.map((item, index) => (
            <ServiceCard key={index} index={index}>
              <Grid
                item
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={item?.our_logo} alt="application" />
                  <ProcessTitle>{item?.logo_title}</ProcessTitle>
                </Box>
              </Grid>
            </ServiceCard>
          ))}
        </Slider>
        <SliderArrows slider={slider} />
      </Container>
    </Wrapper>
  );
};

export default ProcessCarousel;
