import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./style.css";
import { CrumbCover } from "./style";
import { API_BASE_URL } from "../../constants/api";
import { useTranslation } from "react-i18next";
import CustomTypography from "../CustomTypography";
const order = ["first", "second", "third", "sixth", "fifth", "fourth"];
const BreadCrumbs = () => {
  const { i18n } = useTranslation();
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  useEffect(() => {
    fetch(
      `${API_BASE_URL}${i18n.language}/project/projectjourney/${
        i18n.language === "tr" ? `listTr/` : "listEn/"
      }`
    )
      .then((res) => res.json())
      .then((result) => {
        const firstHalf = result.slice(0, 3);
        const secondHalf = result.slice(3, 6).reverse();
        setBreadCrumbs([...firstHalf, ...secondHalf]);
      });
  }, [i18n.language]);
  return (
    <>
      <CrumbCover className="crumbs" container>
        {breadCrumbs.map((item, index) => {
          return (
            <Grid div xs={2} sm={4} md={4} key={index}>
              <div className={order[index]}>
                <div className="crumb-content">
                  <CustomTypography
                    variant="breadCrumbSequence"
                    className="number-web"
                  >
                    {item.sequence}
                  </CustomTypography>
                  <CustomTypography
                    variant="breadCrumbTitle"
                    className="subject-web"
                  >
                    {item.title}
                  </CustomTypography>
                  <CustomTypography
                    variant="breadCrumbContent"
                    className="crumb-description-web"
                    style={{ lineHeight: "26px", width: "100%" }}
                  >
                    {item.content}
                  </CustomTypography>
                </div>
              </div>
            </Grid>
          );
        })}
      </CrumbCover>
    </>
  );
};

export default BreadCrumbs;
