import styled from "styled-components";
import { between } from "polished";
import { Grid } from "@mui/material";

export const CareerStyles = styled(Grid)`
  padding-top: 120px;
`;

export const CareerHeader = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Open Sans";
  font-size: 34px;

  .sentence-image {
    width: 35px;
    height: 65px;
  }
  h3 {
    color: rgb(35, 63, 141);
    font-size: 34px;
    font-family: "Open Sans";
    font-weight: bold;
    margin: 20px 0;
  }
`;
export const CareerFooter = styled(Grid)`
  padding-top: ${between("10px", "200px")};
`;

export const CareerContent = styled(Grid)`
  min-height: 66vh;
  &&& {
    padding-top: ${between("10px", "25px")};
    padding-left: ${between("5px", "250px")};
    padding-right: ${between("5px", "250px")};
  }
`;
export const CareerContentHeader = styled(Grid)``;

export const SelectionBar = styled(Grid)`
  /* margin-bottom: ${between("10px", "25px")}; */
  display: flex;
  justify-content: center;
  align-items: center;
`;
