import styled from "styled-components";
import { Grid, Button } from "@mui/material";
import CustomTypography from "../CustomTypography";
import theme from "../../theme";

export const Wrapper = styled(Grid)`
  padding-bottom: 1rem;
  width: 100%;
  height: 100%;
  padding: 0 0 4rem 0;
  display: flex;

  .slick-slide > div {
    margin: 0 2rem 0 0;
  }
`;

export const Container = styled(Grid)`
  margin: 2rem auto;
  width: 1516px;
  height: 100%;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    padding: 0rem 2rem;
    width: 100%;
  }
  @media (max-width: 550px) {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .arrows {
    @media (max-width: 550px) {
      display: none;
    }
  }
  /*  width: 100%; */
  marginLeft .slick-dots li {
    width: 1rem;
  }

  .slick-dots li button::before {
    color: #c4c4c4;
    font-size: 1rem;
    opacity: 1;
  }

  .slick-dots li.slick-active button::before {
    color: #194591;
    opacity: 1;
  }
  .slick-dots li {
    width: 1.5rem;
    margin: 0;
    padding: 0;
  }
  .slick-dots {
    list-style-type: circle;
    width: fit-content;
  }
`;

export const GridContainer = styled(Grid)`
  display: flex;
  margin: 8rem 0 -1rem 0;
  display: flex !important;
  flex-direction: row;
  align-items: flex-start;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
  &:focus,
  &:active,
  &:hover {
    border: none !important;
    outline: none !important;
    cursor: pointer;
  }
`;

export const LeftContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 0;
  width: 50%;
  @media (max-width: 900px) {
    width: 100%;
  }
  #logo {
    height: 6rem;
    width: auto;
    @media (max-width: 1200px) {
      height: 4rem;
      width: auto;
      max-width: 100%;
    }
  }
`;

export const Text = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0;
  width: max-content;
`;

export const StyledTypography = styled(CustomTypography)`
  color: ${theme.main.blue};
  @media (max-width: 1024px) {
    font-size: 1.125rem;
  }
  @media (min-width: 1024px) {
    font-size: 2.5rem;
  }
  @media (max-width: 768px) {
    font-size: 1rem;
  }
  @media (max-width: 600px) {
    font-size: 0.875rem;
  }
`;

export const StyledButton = styled(Button)`
  text-transform: none;
  border: 1px solid transparent;
  border-radius: 20px;
  color: ${theme.main.blue} !important;
  font-size: 1rem;
  font-weight: 600;
  background-color: #fff;
  padding: 0.25rem 0.75rem;
  margin: 1rem 1rem 1rem 0;

  @media (max-width: 1024px) {
    font-size: 0.875rem;
    margin: 0.5rem 0.5rem 0.5rem 0;
  }
  @media (max-width: 768px) {
    font-size: 0.75rem;
    margin: 0.25rem 0.25rem 0.25rem 0;
  }
  @media (max-width: 600px) {
    font-size: 0.675rem;
    margin: 0.25rem 0.25rem 0.25rem 0;
  }
`;

export const RightContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 400px) {
    display: flex;
    justify-content: center;
  }
  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
  #picture {
    height: auto;
    width: 65rem;
    @media (max-width: 1680px) {
      height: 40rem;
      width: 60rem;
    }
    @media (max-width: 1680px) {
      height: auto;
      width: 50rem;
    }
    @media (max-width: 550px) {
      height: auto;
      width: 30rem;
      margin: 0 auto 0 3rem;
    }
  }
`;
