/* eslint-disable jsx-a11y/alt-text */
import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  GridContainer,
  LeftContainer,
  StyledTypography,
  Text,
  InnerContainer,
  Wrapper,
  TextContainer,
  ImgContainer,
  ArrowBox,
  InnerBox,
  LogoBox,
} from "./style";
import Slider from "react-slick";
import Background from "../../assets/techPartnersBackground.svg";
import BackgroundBlue from "../../assets/techPartnersBackgroundBlue.svg";
import { ArrowLeft, ArrowRight } from "../../assets/icons";

const TechPartnersComponent = ({
  partner,
  background,
  scrollToSection,
  _index,
}) => {
  const settings = {
    className: "center",
    infinite: false,
    slidesToShow: 1,
    dots: true,
    dotsClass: "slick-dots",
    arrows: false,
    slidesToScroll: 1,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const slider = useRef();
  const handleArrowLeft = () => {
    slider.current.slickPrev();
  };
  const handleArrowRight = () => {
    slider.current.slickNext();
  };

  return (
    <Grid
      className={`techPartners-${_index}`}
      container
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      backgroundColor={background % 2 === 0 ? "#FFFFFF" : "#EDF0F6"}
    >
      <Wrapper id={`test-${_index}`}>
        <Container>
          <Slider ref={slider} {...settings}>
            {partner.Partners.map((item, index) => (
              <>
                <Box key={index} sx={{ zIndex: 99, height: "1000px" }}>
                  <GridContainer
                    item
                    key={index}
                    padding={{
                      lg: "5.75rem 0rem 2.5rem",
                      xl: "5.75rem 0rem 5.5rem",
                      md: "5.75rem 0rem 2.5rem",
                      xs: "2.5rem 0rem 1rem",
                    }}
                  >
                    <LeftContainer>
                      <InnerContainer>
                        <Text>
                          <StyledTypography
                            variant="largeText"
                            style={{
                              zIndex: 99,
                            }}
                          >
                            {item.title}
                          </StyledTypography>
                          <StyledTypography
                            variant="largeText"
                            style={{
                              zIndex: 99,
                            }}
                          >
                            {item.title_two}
                          </StyledTypography>
                        </Text>
                        <LogoBox
                          sx={{
                            border: "2px solid #194591",
                            borderRadius: "10px 10px 0 0",
                            borderBottom: "0",
                            width: "225px",
                            height: "120px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            zIndex: 999,
                            backgroundColor: "white",
                          }}
                          item
                          xs={4}
                        >
                          <img
                            src={partner.main_image}
                            alt="logo"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </LogoBox>
                      </InnerContainer>
                      <TextContainer
                        flexDirection={{
                          xs: "column",
                          sm: "column",
                          md: "column",
                          lg: "row",
                          xl: "row",
                        }}
                        justifyContent
                        paddingTop={{ xs: "24px", sm: "24px", md: "40px" }}
                        paddingBottom={{
                          xs: "42px",
                          sm: "42px",
                          md: "211px",
                        }}
                        style={{ zIndex: 99 }}
                      >
                        <Text item xs={12} sm={12} md={12} lg={5} xl={5}>
                          <StyledTypography
                            variant="techPartnersContent"
                            color="#4a4a4a !important"
                            paddingBottom={{
                              xs: "22px",
                              md: "25px",
                              lg: "28px",
                              xl: "32px",
                            }}
                            style={{ zIndex: 99 }}
                          >
                            {item.content}
                          </StyledTypography>
                          {item.parner_content.map((partnerItem) => (
                            <>
                              <StyledTypography
                                variant="techPartnersContentTitle"
                                color="#194591 !important"
                                style={{ zIndex: 99 }}
                              >
                                {partnerItem.title}
                              </StyledTypography>
                              <StyledTypography
                                variant="techPartnersContent"
                                color="#4a4a4a !important"
                                paddingBottom={{
                                  xs: "22px",
                                  md: "25px",
                                  lg: "28px",
                                  xl: "32px",
                                }}
                                style={{ zIndex: 99 }}
                              >
                                {partnerItem.content}
                              </StyledTypography>
                            </>
                          ))}
                        </Text>
                        <ImgContainer>
                          <Box
                            width={{
                              xs: "250px",
                              md: "610px",
                              lg: "610px",
                              xl: "610px",
                            }}
                            height={{
                              xs: "350px",
                              md: "410px",
                              lg: "410px",
                              xl: "410px",
                            }}
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              zIndex: 999,
                            }}
                          >
                            <img
                              style={{
                                objectFit: "contain",
                                zIndex: 999,
                                height: "100%",
                                width: "100%",
                              }}
                              src={item.partner_image}
                              alt="slash"
                            />
                          </Box>
                        </ImgContainer>
                      </TextContainer>
                    </LeftContainer>
                  </GridContainer>
                </Box>
              </>
            ))}
          </Slider>
          {partner.Partners.length > 1 && (
            <Box sx={{ float: "right" }}>
              <ArrowLeft
                style={{ cursor: "pointer" }}
                onClick={handleArrowLeft}
                color={"#194591"}
              />
              <ArrowRight
                style={{ cursor: "pointer" }}
                onClick={handleArrowRight}
                color={"#194591"}
                marginLeft="20px"
              />
            </Box>
          )}
        </Container>
      </Wrapper>
    </Grid>
  );
};

export default TechPartnersComponent;
