import styled from "styled-components";

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: ${({ theme }) => theme.header.height}; */
  .cHbmQz {
  }
  .container {
    display: flex;
    align-items: center;
    padding-top: 6rem;
    width: 1516px;
    justify-content: space-between;
    @media (max-width: 1516px) {
      width: 71rem;
    }
    @media (max-width: 1200px) {
      width: 100%;
      padding: 8rem 2rem 5rem;
      align-items: flex-start;
    }
  }

  .container-img {
    width: 100%;
  }
  .slogan-container {
    width: 200%;
    .slogan {
      padding: 5px 0px;
      margin-bottom: 5px;
      .text {
        background-color: ${({ theme }) => theme.main.secondaryColor};
        color: #fff;
        border-radius: 7px;
        font-size: 40px;
        font-family: "Open Sans";
        margin: 0;
        padding: 10px 25px;
        @media screen and (max-width: 992px) {
          font-size: 4.2vw;
        }
        .bold {
          font-weight: bold;
        }
      }
    }
  }
`;
