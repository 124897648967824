/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { API_BASE_URL } from "../../../constants/api";
import {
  BlogContainer,
  BlogStyles,
  BlogsNotExistContainer,
  CoverBox,
} from "./styles";
import { motion, AnimatePresence } from "framer-motion";
import BlogCard from "../BlogCard";
import Loading from "../../../components/Loading";
import HeadBanner from "./HeadBanner";
import axios from "axios";

const Blog = () => {
  const navigate = useNavigate();
  const [blogList, setBlogList] = useState([]);
  const [page, setPage] = useState(0);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          url:
            API_BASE_URL +
            i18n.language +
            `/blog/rawList` +
            (i18n.language === "tr" ? `Tr/` : `En/`),
          method: "GET",
        });
        setBlogList(response.data);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    };
    fetchData();
  }, [page, i18n.language]);

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  return (
    <>
      <HeadBanner />
      <CoverBox>
        <BlogStyles>
          <Helmet>
            <title>{t("blog.title")}</title>
            <meta name="description" content={t("blog.description")} />
          </Helmet>
          <AnimatePresence>
            {!blogList.length && (
              <motion.div
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 1 }}
                style={{
                  backgroundColor: "rgba(0,0,0,0.7)",
                  width: "1000vw",
                  height: "1000vw",
                  position: "fixed",
                  top: 0,
                  left: 0,
                  zIndex: "999999999",
                }}
              >
                <Loading />
              </motion.div>
            )}
          </AnimatePresence>

          {blogList.length && (
            <BlogContainer>
              {
                blogList?.map(
                  ({
                    id,
                    title,
                    raw_content,
                    created_date,
                    author,
                    blog_image,
                    blog_main_image,
                    content,
                    BlogCategory,
                    date,
                  }) => (
                    <Grid item className="container" key={id}>
                      <BlogCard
                        key={id}
                        id={id}
                        author={author}
                        blog_image={blog_image}
                        blog_main_image={blog_main_image}
                        content={content}
                        createDate={created_date}
                        title={title}
                        raw_content={raw_content}
                        language={t("blog.buttonName")}
                        BlogCategory={BlogCategory}
                        onClick={() => {
                          navigate(
                            `/blog/post/${id}/${title.replaceAll(" ", "")}`
                          );
                        }}
                      />
                    </Grid>
                  )
                )
                /* ) */
              }
            </BlogContainer>
          )}
        </BlogStyles>
      </CoverBox>
    </>
  );
};

export default Blog;
