import React from "react";
import PageTitle from "../../../../components/PageTitle";
import { useTranslation } from "react-i18next";
import {
  BenefitLayout,
  EmployeeCard,
  EmployeeBenefitsContainer,
} from "./styles";
import { Box, Hidden } from "@mui/material";
import BenefitCarousel from "../../../../components/BenefitCarousel";

const EmployeeBenefits = ({ benefitsData }) => {
  const { t } = useTranslation();
  return (
    <>
      <Hidden lgDown>
        <Box style={{ backgroundColor: "#EDF0F6", width: "100%" }}>
          <EmployeeBenefitsContainer
            padding={{
              lg: "4.5rem 0rem 5.5rem",
              xl: "4.5rem 0rem 5.5rem",
            }}
            item
            container
          >
            <PageTitle
              title={t("jobs.employee")}
              alignCenter
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            />
            <BenefitLayout>
              {benefitsData?.map((benefit) => (
                <EmployeeCard key={benefit.id}>
                  <h3>{benefit.title}</h3>
                  <p>{benefit.description}</p>
                </EmployeeCard>
              ))}
            </BenefitLayout>
          </EmployeeBenefitsContainer>
        </Box>
      </Hidden>
      <Hidden lgUp>
        <EmployeeBenefitsContainer
          style={{ backgroundColor: "#EDF0F6" }}
          item
          container
        >
          <Box
            padding={{
              xs: "0.5rem 0rem 0",
              md: "4.5rem 0rem 0",
            }}
          >
            <PageTitle
              title={t("jobs.employee")}
              alignCenter
              style={{
                display: "flex",
                justifyContent: "flex-start",
                paddingLeft: "2rem",
              }}
            />
          </Box>

          <BenefitCarousel benefitsData={benefitsData} />
        </EmployeeBenefitsContainer>
      </Hidden>
    </>
  );
};

export default EmployeeBenefits;
