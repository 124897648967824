import styled from "styled-components";
import { TextField } from "@mui/material";

export const MyTextField = styled(TextField)`
  border: none;
  /* .css-5xn5lu-MuiInputBase-root-MuiInput-root::before {
    content: none;
  }
  .css-5xn5lu-MuiInputBase-root-MuiInput-root::after {
    content: none;
  } */
  *::before {
    content: none;
    border-bottom: none;
  }
  *::after {
    content: none;
    border-bottom: none;
  }
`;
