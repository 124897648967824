import styled from "styled-components"
import {ToastContainer} from 'react-toastify'

export const StyledToastContainer = styled(ToastContainer).attrs({
    className: 'toast-container',
    toastClassName: 'toast',
    bodyClassName: 'body',
    progressClassName: 'progress',
  })`  
    .body {
        margin-left: 40px
    }
  
  `;