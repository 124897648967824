import React from "react";
import CareerPagePic from "../../../../assets/CareerPagePic.svg";
import { Trans, useTranslation } from "react-i18next";
import { Top } from "./styles";
import { Box } from "@mui/material";

const HeadBanner = () => {
  const { t } = useTranslation();

  return (
    <Top
      padding={{
        md: "4.5rem 0rem 1rem",
        lg: "4.5rem 0rem 1rem",
        xl: "4.5rem 0rem 1rem",
      }}
    >
      <Box className="container">
        <Box className="slogan-container">
          <div>
            <div className="slogan">
              <p className="text">
                <span className="bold"> {t("jobs.header.part1")} </span>
                {t("jobs.header.part2")}
              </p>
            </div>
            <div className="slogan" style={{ width: "fit-content" }}>
              <p className="text">{t("jobs.header.part3")}</p>
            </div>
          </div>
          <div className="slogan2">
            <p className="subtext">
              <Trans>{t("jobs.subheader")}</Trans>
            </p>
          </div>
        </Box>
        <Box>
          <img
            className="container-img"
            src={CareerPagePic}
            alt="WesteropsPhoto"
          />
        </Box>
      </Box>
    </Top>
  );
};

export default HeadBanner;
