import React from "react";
import { StyledToastContainer } from "./styles";
import { SuccessIcon } from "../../assets/about/successIcon";
import { FailIcon } from "../../assets/about/failIcon";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export const Modal = () => {
  return (
    <div>
      <StyledToastContainer
        position="top-right"
        autoClose={7000}
        pauseOnHover
        style={{ width: 452, height: 131, marginTop: 100 }}
      />
    </div>
  );
};

export const notifySuccess = () =>
  toast.success(<NotificationSuccess />, {
    icon: <SuccessIcon style={{ marginLeft: -30, marginTop: -30 }} />,
  });

export const notifyFailure = (error) =>
  toast.error(<NotificationFail error={error} />, {
    icon: <FailIcon style={{ marginLeft: -30, marginTop: -30 }} />,
  });

const NotificationSuccess = () => {
  const { t } = useTranslation();
  return (
    <>
      <strong style={{ fontSize: 22 }}>{t("message.message.sended")}</strong>
      <p style={{ fontSize: 16, marginTop: "4px" }}>
        {t("message.message.sended")}
      </p>
    </>
  );
};

const NotificationFail = ({ error }) => {
  const { t } = useTranslation();
  return (
    <>
      <strong style={{ fontSize: 22 }}>{t("message.message.failed")}</strong>
      <p style={{ fontSize: 16, marginTop: "6px" }}>{error}</p>
    </>
  );
};
