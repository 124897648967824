// some options may be initialized in the "config-overrides.js"
// these options are marked with comments -> "//config-overrides"
// please make same changes on config-overrides.js

// ** INDEX **
// MAIN
// COMPONENTS
// PAGES
import { rgb } from "polished";
import NETWORK from "../assets/network.svg";

const primaryColor = "#FF744C"; // config-overrides
const secondaryColor = "#233F8D";
const cyan = "#01D2D6";
const white = "#ffff";
const orange = "#ff744c";
const turquoise = "#EDFAFF";
const blue = "#194591";
const darkColor = "#000";
const smokeColor = "#A5A5A5";
const extraSmokeColor = "#959798";
const backgroundColors = rgb(250, 251, 253);

const paddingHorizontal = "8%";
const paddingHorizontalS = "2%";
const paddingHorizontalM = "4.5%";
const paddingHorizontalL = "10%";

const paddingVertical = "3%";
const paddingVerticalS = "0.5%";
const paddingVerticalM = "2%";
const paddingVerticalL = "8%";

const theme = {
  // MAIN
  main: {
    primaryColor, // config-overrides
    primaryFocus: "#f25a30",
    secondaryColor,
    secondaryFocus: "#425893",
    cyan,
    white,
    orange,
    turquoise,
    blue,
    green: "#52c41a",
    danger: "#f5222d",
    darkColor,
    smokeColor,
    extraSmokeColor,
    padding: "10px",
    shadow: "0 3px 3px 0 rgba(0, 0, 0, 0.03)",
    shadowHover: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    shadowCard: "0 3px 6px rgba(0,0,0,0.19)",
    borderRadius: "4px",
    backgroundColors,

    paddingHorizontal,
    paddingHorizontalS,
    paddingHorizontalM,
    paddingHorizontalL,

    paddingVertical,
    paddingVerticalS,
    paddingVerticalM,
    paddingVerticalL,
  },
  // COMPONENTS
  content: {
    backgroundColor: "#f9f9f9",
    paddingHorizontal,
    paddingVertical,
  },
  header: {
    height: "80px",
    backgroundColor: "#f9f9f9",
    borderBottom: "2px solid rgba(0, 0, 0, 0.03)",
    shadow: "0 3px 3px 0 rgba(0, 0, 0, 0.03);",
    paddingHorizontal,
    paddingVertical: paddingVerticalS,
  },
  footer: {
    height: "200px",
    backgroundColor: "#fff",
  },
  pageTitle: {
    paddingHorizontal,
  },
  card: {
    background: "#fff",
    margin: "10px",
    padding: "15px",
    borderRadius: "5px",
  },
  text: {
    primaryFontFamily: "Segoe UI",
    secondaryFontFamily: "Lucida",
    tertiaryFontFamily: "Georgia",
    quaternaryFontFamily: "Open Sans",

    extraSmallFontSize: "12px",
    smallFontSize: "14px",
    regularFontSize: "16px",
    superRegularFontSize: "21px",
    bigFontSize: "25px",
    extraBigFontSize: "40px",

    regularFontWeight: "400",
    mediumFontWeight: "500",
    semiboldFontWeight: "600",
    boldFontWeight: "700",
  },
  // PAGES
  banner: {
    top: {
      background: NETWORK,
      companiesTextColor: "#a9a9a9",
      paddingVertical: paddingVerticalS,
    },
    bottom: {
      paddingVertical: paddingVerticalS,
    },
  },

  portfolio: {
    paddingVertical,
    paddingHorizontal: paddingHorizontalS,
  },

  testimonials: {
    shadow: "7.829px 11.607px 14px rgba(80,80,80,0.2)",
    card: {
      minHeight: "120px",
      width: "25vw",
      primary: {
        height: "22px",
        width: "90px",
        widthLittle: "30px",
      },
      secondary: {
        height: "115px",
        width: "24px",
      },
      cyan: {
        height: "107px",
        width: "56px",
      },
    },
  },

  // contactUs: {
  //   paddingHorizontal: paddingHorizontalL,
  // },
  staff: {
    paddingHorizontal: paddingHorizontalS,
  },
};
export default theme;
