import { Grid, Box } from "@mui/material";
import styled from "styled-components";
import CustomTypography from "../../../components/CustomTypography";

export const CoverBox = styled(Box)`
  background-color: #edf0f6;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const BlogStyles = styled(Grid)``;

export const BlogContainer = styled(Grid)`
  width: 1516px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media (max-width: 1516px) {
    width: 71rem;
    justify-content: space-evenly;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem;
  }
  @media (max-width: 1050px) {
    width: 100%;
    justify-content: center;
  }
`;

export const StyledTypography = styled(CustomTypography)`
  @media (max-width: 1024px) {
    font-size: 2rem;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 600px) {
    font-size: 1.25rem;
  }
`;

export const BlogsNotExistContainer = styled(Grid)`
  height: 1279px;
`;

export const Banner = styled.div`
  position: relative;
  height: 250px;
  width: 100%;
  margin-bottom: 30px;
  .banner-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .title {
    position: absolute;
    top: 30%;
    left: 8%;
  }
  .blog {
    position: absolute;
    top: 50%;
    left: 8%;
  }
`;
