import { Box, Grid } from "@mui/material";
import React from "react";
import { Container } from "../../style";
import slash from "../../../../assets/getOffer/slash.svg";
import selectedInput from "../../../../assets/getOffer/selectedInput.svg";
import input from "../../../../assets/getOffer/input.svg";

const AdminComponent = ({ requestData, setRequestData, variant, data }) => {
  const handleChange = (id) => {
    if (!requestData?.[7]?.find((item) => item === id)) {
      let temp = [...requestData];
      if (!temp[7]) temp[7] = [id];
      else temp[7] = [...temp[7], id];
      setRequestData(temp);
    } else {
      let temp = [...requestData];
      let index = temp[7].indexOf(id);
      if (index > -1) {
        temp[7].splice(index, 1);
      }
      setRequestData(temp);
    }
  };
  return (
    <Grid container xl={12} style={{ margin: "0rem auto 0 auto" }}>
      <Container
        container
        style={{
          marginTop: "2rem",
          width: "100%",
          borderBottom: "2px solid #194591",
        }}
      >
        <img src={slash} alt="slash" />
        <h1 style={{ color: "#194591", fontSize: "30px", marginLeft: "1rem" }}>
          {data?.name}
        </h1>
      </Container>
      <Grid style={{ marginTop: "2.5rem" }}>
        <span style={{ color: "#194591", fontSize: "18px" }}>
          {data?.sub_steps?.[0].name}
        </span>
        <Grid
          container
          style={{ display: "flex", flexDirection: "row", marginTop: "2rem" }}
        >
          {data?.sub_steps?.[0]?.data?.map((item, index) => (
            <Grid
              item
              lg={3}
              md={8}
              sm={10}
              xs={12}
              key={index}
              style={{
                width: "16rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box>
                <Box onClick={() => handleChange(item?.id)}>
                  <img
                    src={
                      requestData?.[7]?.some((e) => e === item.id)
                        ? selectedInput
                        : input
                    }
                    alt="image"
                  />
                </Box>
              </Box>
              <Box>
                <span
                  style={{
                    marginTop: "1rem",
                    fontSize: "1rem",
                    color: requestData?.[7]?.some((e) => e === item.id)
                      ? "#FF744C"
                      : "#194591",
                    marginLeft: "10px",
                  }}
                >
                  {item.name}
                </span>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminComponent;
