/* eslint-disable jsx-a11y/alt-text */
import { Box, Grid, Radio, Typography } from "@mui/material";
import React from "react";
import slash from "../../../../assets/getOffer/slash.svg";
import { Container } from "../../style";
import { MyBox } from "../../components/Platform/style";
import UnCheckIcon from "../../../../assets/UnCheckedIcon";
import CheckedIcon from "../../../../assets/CheckedIcon";

const TimingComponent = ({ requestData, setRequestData, variant, data }) => {
  const handleChange = (id, index) => {
    let temp = [...requestData];
    temp[index] = id;
    setRequestData(temp);
  };

  return (
    <Grid
      sx={{ width: "100%" }}
      container
      xl={12}
      style={{ margin: "0rem auto 0 auto" }}
    >
      <Container
        container
        style={{
          marginTop: "2rem",
          width: "100%",
          borderBottom: "2px solid #194591",
        }}
      >
        <img src={slash} alt="slash" />
        <h1 style={{ color: "#194591", fontSize: "30px", marginLeft: "1rem" }}>
          {data?.name}
        </h1>
      </Container>

      <Typography
        sx={{
          color: "#194591",
          fontSize: "18px",
          marginTop: "2.5rem",
          marginBottom: "2rem",
        }}
      >
        {data?.sub_steps?.[0]?.name}
      </Typography>
      <Grid
        container
        style={{
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        {data?.sub_steps?.[0].data?.map((item, index) => (
          <MyBox item xs={6} sm={4} md={4} lg={3} key={index}>
            <Radio
              icon={<UnCheckIcon />}
              checkedIcon={<CheckedIcon />}
              checked={requestData[9] === item.id}
              onChange={() => handleChange(item.id, 9)}
              value="b"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 40,
                  color: "#194591",
                },
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {!item?.data_image || (
                <Box
                  style={{
                    backgroundColor:
                      requestData[9] === item.id ? "#EDFAFF" : "white",
                    height: variant === "desktop" ? "150px" : "92px",
                    width: variant === "desktop" ? "150px" : "92px",
                    border: "2px solid #194591",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "11px",
                  }}
                >
                  <img src={item?.data_image} style={{ color: "#194591" }} />
                </Box>
              )}
              <span
                style={{
                  marginTop: "1rem",
                  fontSize: "1rem",
                  color: requestData[9] === item.id ? "#FF744C" : "#194591",
                }}
              >
                {item.name}
              </span>
            </div>
          </MyBox>
        ))}
        {/* <Grid>
          <Box>
            <Radio
              checked={selectedValue === "b"}
              onChange={handleChange}
              value="b"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
            />
            <span>Farketmez</span>
          </Box>
        </Grid> */}
      </Grid>
      <Typography
        sx={{
          color: "#194591",
          fontSize: "18px",
          marginTop: "6rem",
          marginBottom: "2rem",
        }}
      >
        {data?.sub_steps?.[1]?.name}
      </Typography>
      <Grid
        container
        style={{
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        {data?.sub_steps?.[1]?.data?.map((item, index) => (
          <MyBox item xs={6} sm={4} md={4} lg={3} key={index}>
            <Radio
              icon={<UnCheckIcon />}
              checkedIcon={<CheckedIcon />}
              checked={requestData[10] === item.id}
              onChange={() => handleChange(item.id, 10)}
              value="b"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 40,
                  color: "#194591",
                },
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {!item?.data_image || (
                <Box
                  style={{
                    backgroundColor:
                      requestData[10] === item.id ? "#EDFAFF" : "white",
                    height: variant === "desktop" ? "150px" : "92px",
                    width: variant === "desktop" ? "150px" : "92px",
                    border: "2px solid #194591",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "11px",
                  }}
                >
                  <img src={item?.data_image} style={{ color: "#194591" }} />
                </Box>
              )}
              <span
                style={{
                  marginTop: "1rem",
                  fontSize: "1rem",
                  color: requestData[10] === item.id ? "#FF744C" : "#194591",
                }}
              >
                {item.name}
              </span>
            </div>
          </MyBox>
        ))}
        {/* <Grid>
          <Box>
            <Radio
              checked={selectedValue === "b"}
              onChange={handleChange}
              value="b"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
            />
            <span>Farketmez</span>
          </Box>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default TimingComponent;
