import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { JoinUsContainer } from "./styles";
import CareerCard from "../../CareerCard";
import { useNavigate } from "react-router";
import { API_BASE_URL } from "../../../../constants/api";
import axios from "axios";
import { Box, Grid, Hidden } from "@mui/material";
import CareerJoinUsFilterBar from "../../../../components/CareerJoinUsFilterBar";
import CareerJoinUsFilterBarMobile from "../../../../components/CareerJoinUsFilterBarMobile";

const JoinUs = () => {
  const { i18n } = useTranslation();
  const [selectedJobs, setSelectedJobs] = useState(-1);
  const navigate = useNavigate();
  const [jobList, setJobList] = useState("");
  const [jobTypes, setJobTypes] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios({
          url:
            API_BASE_URL +
            i18n.language +
            `/staff/job/list` +
            (i18n.language === "tr" ? `Tr/` : "En/"),
          method: "GET",
        });
        setJobList(response.data);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    };
    const getCategoryData = async () => {
      try {
        const response = await axios({
          url:
            API_BASE_URL +
            i18n.language +
            `/staff/jobcategory/list` +
            (i18n.language === "tr" ? `Tr/` : "En/"),
          method: "GET",
        });
        setJobTypes(response.data);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    };
    getData();
    getCategoryData();
  }, [i18n.language]);
  const filteredJobs =
    selectedJobs !== -1 && jobList
      ? jobList.filter((item) => item.job_category.id === selectedJobs)
      : jobList;
  return (
    <Box style={{ backgroundColor: "#edfaff" }} paddingBottom="2rem">
      <JoinUsContainer
        margin="0 auto"
        padding={{
          xs: "3rem 0rem 3.5rem",
          md: "4.5rem 0rem 5.5rem",
          lg: "4.5rem 0rem 5.5rem",
          xl: "4.5rem 0rem 5.5rem",
        }}
        container
      >
        <Hidden mdDown>
          <CareerJoinUsFilterBar
            roles={jobTypes}
            active={selectedJobs}
            setActive={setSelectedJobs}
          />
        </Hidden>
        <Hidden mdUp>
          <CareerJoinUsFilterBarMobile
            roles={jobTypes}
            active={selectedJobs}
            setActive={setSelectedJobs}
          />
        </Hidden>
        <Grid container gap={"2rem"}>
          {filteredJobs &&
            filteredJobs?.map(
              ({ id, position, location, job_category, index }) => (
                <Box key={index} sx={{ width: "355px" }}>
                  <CareerCard
                    id={id}
                    title={position}
                    location={location}
                    category={job_category === null ? "" : job_category?.name}
                    onClick={() => {
                      navigate(`/career/post/${id}`);
                    }}
                  />
                </Box>
              )
            )}
        </Grid>
      </JoinUsContainer>
    </Box>
  );
};

export default JoinUs;
