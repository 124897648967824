/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ArrowLeft, ArrowRight } from "../../assets/icons";
import { ServicesTitle } from "./style";

function index({ slider, roles, active, setActive }) {
  const handleArrowLeft = () => {
    slider.current.slickPrev();
  };
  const handleArrowRight = () => {
    slider.current.slickNext();
  };
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ServicesTitle variant="mediumTitle">
          {t("westeropsTeam.title")}
        </ServicesTitle>
        <Box>
          <ArrowLeft onClick={handleArrowLeft} color="#194591" />
          <ArrowRight
            onClick={handleArrowRight}
            color="#194591"
            marginLeft="20px"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "30px",
          alignItems: "center",
          width: "100%",
          overflowY: "scroll",
          whiteSpace: "nowrap",
        }}
      >
        {roles.map((role, index) => (
          <Box onClick={() => setActive(role)} key={index}>
            <Typography
              sx={{
                cursor: "pointer",
                color: active === role ? "#FF744C" : "#4A4A4A",
                fontSize: "14px",
                fontWeight: "600 !important",
                fontFamily: "Open Sans",
              }}
            >
              {role}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
}

export default index;
