import styled from "styled-components";
import { ArrowLongLeft, ArrowLongRight } from "@styled-icons/heroicons-outline";
import { ArrowDownward } from "@styled-icons/evaicons-solid/";
// import { Location } from "styled-icons/evil";
import {
  Phone,
  Mail,
  Facebook,
  Instagram,
  Twitter,
  Linkedin,
  MapPin,
} from "@styled-icons/feather";

const COMMON_ICON_STYLE = (marginLeft, width, color) => `
        width: ${width || "25px"};
        margin-left: ${marginLeft ? "20px" : "0px"};
        color: ${color || "#fff"};
        overflow: visible !important;

        @media screen and (max-width: 767px) {
          width: ${width || "20px"};
        }
    `;

const CIRCLE = (circled, color, borderRadius) => {
  const circle =
    circled &&
    `border: 1px solid;
                            border-radius: ${borderRadius || "50%"};
                            border-color: ${color || "#ffff"};
                            padding: 3px;
                            `;

  return `
        ${circle}
    `;
};

export const ArrowRight = styled(ArrowLongRight)`
  ${({ marginLeft, width, color }) =>
    COMMON_ICON_STYLE(marginLeft, width, color)}
`;
export const ArrowLeft = styled(ArrowLongLeft)`
  ${({ marginLeft, width, color }) =>
    COMMON_ICON_STYLE(marginLeft, width, color)}
`;

export const ArrowDown = styled(ArrowDownward)`
  ${({ marginLeft, width, color }) =>
    COMMON_ICON_STYLE(marginLeft, width, color)}
`;

export const PhoneNumber = styled(Phone)`
  ${({ marginLeft, width, color }) =>
    COMMON_ICON_STYLE(marginLeft, width, color)};
  ${({ circled, color }) => CIRCLE(circled, color)}
`;

export const Email = styled(Mail)`
  ${({ marginLeft, width, color }) =>
    COMMON_ICON_STYLE(marginLeft, width, color)};
  ${({ circled, color, borderRadius }) => CIRCLE(circled, color, borderRadius)}
`;

export const Address = styled(MapPin)`
  ${({ marginLeft, width, color }) =>
    COMMON_ICON_STYLE(marginLeft, width, color)};
  ${({ circled, color, borderRadius }) => CIRCLE(circled, color, borderRadius)}
`;

export const FacebookIcon = styled(Facebook)`
  ${({ marginLeft, width, color }) =>
    COMMON_ICON_STYLE(marginLeft, width, color)};
  ${({ circled, color, borderRadius }) => CIRCLE(circled, color, borderRadius)}
`;

export const TwitterIcon = styled(Twitter)`
  ${({ marginLeft, width, color }) =>
    COMMON_ICON_STYLE(marginLeft, width, color)};
  ${({ circled, color, borderRadius }) => CIRCLE(circled, color, borderRadius)}
`;

export const LinkedinIcon = styled(Linkedin)`
  ${({ marginLeft, width, color }) =>
    COMMON_ICON_STYLE(marginLeft, width, color)};
  ${({ circled, color, borderRadius }) => CIRCLE(circled, color, borderRadius)}
`;

export const InstagramIcon = styled(Instagram)`
  ${({ marginLeft, width, color }) =>
    COMMON_ICON_STYLE(marginLeft, width, color)};
  ${({ circled, color, borderRadius }) => CIRCLE(circled, color, borderRadius)}
`;
