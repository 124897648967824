import { Grid, Box } from "@mui/material";
import styled from "styled-components";
import CustomTypography from "../../components/CustomTypography/index";
import theme from "../../theme";

export const MyBox = styled(Box)`
  width: 100%;
`;

export const ServicesContainer = styled(Grid)`
  justify-content: flex-start;
  display: flex;
  align-items: center;
  padding: 6rem 0 9rem 0;
  width: 1516px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 6rem 2rem 4rem 2rem;
  }
  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8rem 2rem 4rem 2rem;
  }
`;

export const ContentGrid = styled(Grid)`
  /* padding: 7rem 0 10rem 0; */
`;

export const LeftContainer = styled(Grid)`
  width: 374px;
  @media (max-width: 600px) {
    margin: 1rem 0 0 0;
    width: 280px;
  }
  /*  @media (max-width: 400px) {
    margin: 1rem 0 0 0;
    width: 260px;
  } */
`;

export const RightContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0 0 0 10rem;
  width: 742px;
  @media (max-width: 1516px) {
    margin: 0 0 0 5rem;
  }
  @media (max-width: 900px) {
    margin: 0;
    width: 100%;
  }
`;

export const Text = styled(Grid)`
  margin: 1rem 0;
  background: #194591;
  border-radius: 6px;
  color: #fff;
  padding: 1rem;
  width: max-content;
`;

export const StyledTypography = styled(CustomTypography)`
  @media (max-width: 1024px) {
    font-size: 2rem;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 600px) {
    font-size: 1.25rem;
  }
`;

export const ServiceCard = styled(Grid)`
  width: 20rem;
  position: relative;
  /*  margin: 0.7rem; */
  background-color: ${theme.main.white};
  max-height: 8rem;
  border-radius: 5px;
  filter: drop-shadow(0.2rem 0.2rem 0.4rem #bfbfbf);
  @media (max-width: 600px) {
    width: 280px;
  }
  @media (max-width: 400px) {
    width: 300px;
  }
`;

export const SubTitle = styled(CustomTypography)`
  color: ${theme.main.blue};
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: 10%;
`;

export const SubText = styled(CustomTypography)`
  font-size: 20px;
  color: ${theme.main.blue};
  font-family: "Open Sans";
  @media (max-width: 1200px) {
    font-size: 18px;
    line-height: 26px;
  }
  @media (max-width: 700px) {
    margin-top: ${(props) => (props.index === 0 ? "0" : "-2rem")};
    font-size: 1rem;
    line-height: 22px;
  }
  @media (max-width: 650px) {
    font-size: 14px;
  }
`;

export const SubImage = styled.img`
  position: absolute;
  bottom: 80px;
  left: 10%;
`;
