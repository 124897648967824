import * as React from "react";

const CheckedIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <g data-name="Ellipse 1147" fill="#fff" stroke="#194591" strokeWidth={2}>
      <circle cx={16} cy={16} r={16} stroke="none" />
      <circle cx={16} cy={16} r={15} fill="none" />
    </g>
    <circle
      data-name="Ellipse 1148"
      cx={7}
      cy={7}
      r={7}
      transform="translate(9 9)"
      fill="#194591"
    />
  </svg>
);

export default CheckedIcon;
