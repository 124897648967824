/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Grid } from "@mui/material";
import React from "react";
import { Container, MyBox } from "../../style";
import slash from "../../../../assets/getOffer/slash.svg";
import selectedInput from "../../../../assets/getOffer/selectedInput.svg";
import input from "../../../../assets/getOffer/input.svg";
import { MyTextField } from "./styles";
import { useTranslation } from "react-i18next";

const EntegrationComponent = ({
  requestData,
  setRequestData,
  variant,
  data,
  onChangeText,
  isClicked,
  setClicked,
}) => {
  const handleChange = (id) => {
    if (!requestData?.[6]?.find((item) => item === id)) {
      let temp = [...requestData];
      if (!temp[6]) temp[6] = [id];
      else temp[6] = [...temp[6], id];
      setRequestData(temp);
    } else {
      let temp = [...requestData];
      let index = temp[6].indexOf(id);
      if (index > -1) {
        temp[6].splice(index, 1);
      }
      setRequestData(temp);
    }
  };
  const { t } = useTranslation();

  return (
    <Grid container xl={12} style={{ margin: "2rem auto 0 auto" }}>
      <Container
        container
        style={{
          width: "100%",
          /* marginBottom: "2rem", */
          marginTop: "1.5rem",
          borderBottom: "2px solid #194591",
        }}
      >
        <img src={slash} alt="slash" />
        <h1
          style={{
            color: "#194591",
            fontSize: "30px",
            marginLeft: "1rem",
          }}
        >
          {data?.name}
        </h1>
      </Container>
      <span
        style={{
          color: "#194591",
          fontSize: "18px",
          marginTop: "2.5rem",
        }}
      >
        {data?.sub_steps?.[0].name}
      </span>
      <Grid
        container
        style={{
          margin: "0 auto",
          display: "flex",
          marginTop: "2rem",
        }}
      >
        {data?.sub_steps?.[0]?.data?.map((item, index) => (
          <MyBox
            item
            xs={6}
            sm={4}
            md={4}
            lg={3}
            key={index}
            onClick={() => handleChange(item?.id)}
            style={{ marginBottom: "4rem" }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <img
                src={
                  requestData?.[6]?.some((e) => e === item.id)
                    ? selectedInput
                    : input
                }
                alt="image"
              />
            </Box>
            <Grid
              item
              xs={12}
              lg={7}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                item
                style={{
                  backgroundColor: requestData?.[6]?.some((e) => e === item.id)
                    ? "#EDFAFF"
                    : "white",
                  height: variant === "desktop" ? "150px" : "92px",
                  width: variant === "desktop" ? "150px" : "92px",
                  border: "2px solid #194591",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  width={variant === "desktop" ? "85px" : "60px"}
                  src={item.data_image}
                  alt="image"
                  pos
                />
              </Box>
              <span
                style={{
                  marginTop: "1rem",
                  fontSize: "1rem",
                  color: requestData?.[6]?.some((e) => e === item.id)
                    ? "#FF744C"
                    : "#194591",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {item.name}
              </span>
            </Grid>
          </MyBox>
        ))}
        <Grid
          item
          container
          xl={4.7}
          lg={4.6}
          md={8}
          sm={8}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Box onClick={() => setClicked((state) => !state)}>
            <img src={isClicked === true ? selectedInput : input} alt="image" />
          </Box>
          <Grid
            item
            lg={11}
            sm={10}
            xs={10}
            style={{
              flexDirection: "column",
              width: "93%",
            }}
          >
            <span
              style={{
                marginLeft: "2rem",
                fontSize: "1rem",
                color: isClicked ? "#FF744C" : "#194591",
              }}
            >
              {t("getOffer.other")}
            </span>
            <Grid
              item
              sx={{
                border: "2px solid #194591",
                padding: "1rem",
                height: "106px",
                marginLeft: "35px",
                marginTop: "1rem",
              }}
            >
              <MyTextField
                placeholder={t("getOffer.writePlease")}
                sx={{ width: "100%" }}
                variant="standard"
                multiline
                lg={12}
                sm={12}
                md={12}
                xs={12}
                rows={4}
                onChange={onChangeText}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EntegrationComponent;
