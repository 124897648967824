import { Grid, Hidden } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  MdDownContainer,
  MdUpContainer,
  ServicesText,
  ServicesTitle,
  TechContainer,
} from "./style";
import TabPanels from "../../../../components/TabPanel";

const Technolojies = () => {
  const { t } = useTranslation();
  return (
    <Container container>
      <TechContainer
        margin={{ xs: "2rem auto", md: "4rem auto" }}
        item
        container
      >
        <ServicesTitle variant="mediumTitle">
          {t("technologies.title")}
        </ServicesTitle>
        <ServicesText variant="mediumSubTitle">
          {t("technologies.text")}
        </ServicesText>

        <MdDownContainer item container>
          <Hidden lgDown>
            <Grid container>
              <TabPanels variant="fullWidth" />
            </Grid>
          </Hidden>
        </MdDownContainer>
        <MdUpContainer item container>
          <Hidden lgUp>
            <TabPanels variant="scrollable" />
          </Hidden>
        </MdUpContainer>
      </TechContainer>
    </Container>
  );
};

export default Technolojies;
