import Slider from "react-slick";
import "./style.css";
import { Container, Wrapper } from "./style";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API_BASE_URL } from "../../constants/api";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import SliderArrows from "../SliderArrows";

const order = ["first", "second", "third", "fourth", "fifth", "sixth"];
const BreadCrumbsMobile = () => {
  const { i18n } = useTranslation();
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  useEffect(() => {
    fetch(
      `${API_BASE_URL}${i18n.language}/project/projectjourney/${
        i18n.language === "tr" ? `listTr/` : "listEn/"
      }`
    )
      .then((res) => res.json())
      .then((result) => {
        setBreadCrumbs(result);
      });
  }, [i18n.language]);
  const settings = {
    className: "center",
    infinite: false,
    slidesToShow: 2.2,
    dots: true,
    dotsClass: "slick-dots",
    arrows: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 1.2,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slider = useRef();

  useEffect(() => {
    slider?.current.slickGoTo(0, true);
  }, [breadCrumbs]);
  return (
    <Wrapper>
      <Container>
        <ul className="ul-crumbs">
          <Slider ref={slider} {...settings}>
            {breadCrumbs?.map((item, index) => {
              return (
                <li className={"crumb " + order[index]} key={index}>
                  <div>
                    <p className="number">{item.sequence}</p>
                    <p className="subject">{item.title}</p>
                    <p className="crumb-description">{item.content}</p>
                  </div>
                </li>
              );
            })}
          </Slider>
        </ul>
        <SliderArrows slider={slider} />
      </Container>
    </Wrapper>
  );
};

export default BreadCrumbsMobile;
