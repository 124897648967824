import { Box, Grid, Hidden } from "@mui/material";
import React from "react";
import PageTitle from "../../../../components/PageTitle";
import Slash from "../../../../assets/Slash";
import {
  CareerHeader,
  Process,
  ProcessCard,
  ProcessmentContainer,
} from "./styles";
import ProcessCarousel from "../../../../components/ProcessCarousel";

const Processment = ({ processData }) => {
  return (
    <>
      <Hidden mdDown>
        <Box sx={{ backgroundColor: "#edf0f6" }} padding="3rem 0">
          <ProcessmentContainer
            padding={{
              md: "4.5rem 0rem 5.5rem",
              lg: "4.5rem 0rem 5.5rem",
              xl: "4.5rem 0rem 5.5rem",
            }}
            item
            container
          >
            <PageTitle
              title={processData?.[0]?.title}
              alignCenter
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            />
            <Grid
              container
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  width: "100%",
                  filter: "drop-shadow(0px 3px 6px rgba(0,0,0,0.14))",
                }}
              >
                {processData?.[0]?.OurRecruitmentsProcesss.map(
                  (item, index) => (
                    <ProcessCard
                      key={index}
                      item
                      index={index}
                      style={{
                        backgroundColor: "#FFFFFF",
                        width: "100%",
                        height: "15rem",
                      }}
                    >
                      <img
                        src={item?.our_logo}
                        alt="application"
                        style={{ display: "flex" }}
                      />
                      <Process>{item?.logo_title}</Process>
                    </ProcessCard>
                  )
                )}
              </Grid>
            </Grid>

            <CareerHeader>
              <Slash color={"#194591"} />
              <Hidden lgDown>
                <p
                  style={{
                    justifyContent: "center",
                    fontFamily: "Open Sans",
                    fontSize: "20px",
                    width: "100%",
                    margin: "0 0 0 10px",
                  }}
                >
                  {processData?.[0]?.description}
                </p>
              </Hidden>
              <Hidden lgUp>
                <p
                  style={{
                    justifyContent: "center",
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    width: "100%",
                    margin: "0 0 0 10px",
                  }}
                >
                  {processData?.[0]?.description}
                </p>
              </Hidden>
            </CareerHeader>
          </ProcessmentContainer>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <Grid
          sx={{
            backgroundColor: "#edf0f6",
            display: "flex",
            flexDirection: "column",
          }}
          item
          container
        >
          <Box
            padding={{
              xs: "1.5rem 2rem 0rem",
            }}
          >
            <PageTitle
              title={processData?.[0]?.title}
              alignCenter
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            />
          </Box>
          <ProcessCarousel processData={processData} />
          <CareerHeader
            padding={{
              xs: "0rem 2rem 2.5rem",
            }}
          >
            <Slash color={"#194591"} />
            <p
              style={{
                justifyContent: "center",
                fontFamily: "Open Sans",
                fontSize: "14px",
                width: "100%",
                margin: "0 0 0 10px",
                color: "#4A4A4A",
              }}
            >
              {processData?.[0]?.description}
            </p>
          </CareerHeader>
        </Grid>
      </Hidden>
    </>
  );
};

export default Processment;
