import { Link } from "react-router-dom";
import styled from "styled-components";

export const Hyperlink = styled(Link)`
  white-space: nowrap;
  font-size: 15px;
  @media (max-width: 1516px) {
    font-size: 14px;
  }
`;
