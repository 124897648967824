import styled from "styled-components";
import { Grid } from "@mui/material";

export const CareerHeader = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  font-family: "Open Sans";
  font-size: 34px;
  margin-top: 2.5rem;

  .sentence-image {
    width: 35px;
    height: 65px;
  }
`;
export const ProcessmentContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 1516px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem;
  }
`;
export const ProcessCard = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  clip-path: ${({ index }) =>
    index === 4
      ? "polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 10% 53%, 0% 0%)"
      : index === 0
      ? "polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%)"
      : "polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 53%, 0% 0%)"};
  img {
    width: 36%;
    @media (max-width: 1535px) {
      width: 34%;
    }
    @media (max-width: 1300px) {
      width: 32%;
    }
    @media (max-width: 1051px) {
      width: 30%;
    }
  }
`;

export const Process = styled.div`
  font-family: "Open Sans";
  font-size: 22px;
  font-weight: bold;
  color: rgb(35, 63, 141);
  margin-top: 30px;
  @media (max-width: 1250px) {
    font-size: 20px;
  }
  @media (max-width: 1050px) {
    font-size: 18px;
  }
`;
