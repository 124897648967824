import { Box, Grid } from "@mui/material";
import styled from "styled-components";
import theme from "../../theme";
import CustomTypography from "../CustomTypography";

export const Wrapper = styled(Box)`
  width: 100%;
  display: flex;

  .slick-slide > div {
    /* margin: 0 4.5rem 0 -3.5rem; */
    /* margin: 0 2rem 0 0; */
  }
`;

export const Container = styled(Box)`
  width: 100%;
  .slick-dots li {
    width: 1rem;
  }

  .slick-dots li button::before {
    color: #c4c4c4;
    font-size: 1rem;
    opacity: 1;
  }

  .slick-dots li.slick-active button::before {
    color: #194591;
    opacity: 1;
  }
  .slick-dots li {
    margin: 0;
    padding: 0;
  }
  .slick-dots {
    list-style-type: circle;
    width: fit-content;
    bottom: -25px;
    margin-left: 31px;
  }
`;

export const CoverGrid = styled(Grid)`
  display: flex;
`;

export const ServiceCard = styled(Grid)`
  margin: 0 0 2rem 2rem;
  display: flex !important;
  justify-content: center;
  background-color: ${theme.main.white};
  height: 11rem;
  filter: drop-shadow(0.5rem 0.5rem 0.5rem #bfbfbf);
  clip-path: ${({ index }) =>
    index === 4
      ? "polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 10% 53%, 0% 0%)"
      : index === 0
      ? "polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%)"
      : "polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 53%, 0% 0%)"};
  img {
    width: 73%;
  }
`;

export const ProcessTitle = styled.div`
  margin: 3% auto;
  width: fit-content;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  color: rgb(35, 63, 141);
`;
