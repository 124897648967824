/* eslint-disable no-unused-vars */
import React from "react";
import Button from "../../../components/Button";
import { CareerCardStyles, CardHeader, CardFooter } from "./styles";
import { ArrowRight } from "../../../assets/icons";
import { useTranslation } from "react-i18next";
import { rgb } from "polished";
import CustomTypography from "../../../components/CustomTypography";

const CareerCard = ({ id, title, location, category, onClick }) => {
  const { t } = useTranslation();

  return (
    <CareerCardStyles onClick={onClick}>
      <CardHeader>
        <CustomTypography variant="careerCardTitle">{title}</CustomTypography>
      </CardHeader>
      <Button
        className="customize-button"
        type="link"
        style={{
          color: "#194591",
          backgroundColor: "#EDF0F6",
          borderRadius: "30px",
          padding: "8px 14px",
          fontFamily: "Open Sans",
          fontSize: "16px",
        }}
      >
        {category}
      </Button>
      <CardFooter align="middle" justify="space-between">
        <Button
          type="primary"
          style={{ color: "#fff", border: "none", width: "136px" }}
        >
          {t("buttons.apply")}
        </Button>
        <CustomTypography variant="careerCardLocation">
          {location}
        </CustomTypography>
      </CardFooter>
    </CareerCardStyles>
  );
};

export default CareerCard;
