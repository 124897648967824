/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Grid } from "@mui/material";
import React from "react";
import { Container, MyBox } from "../../style";
import slash from "../../../../assets/getOffer/slash.svg";
import selectedInput from "../../../../assets/getOffer/selectedInput.svg";
import input from "../../../../assets/getOffer/input.svg";

const Users = ({ requestData, setRequestData, variant, data }) => {
  const handleChange = (id) => {
    if (!requestData?.[4]?.find((item) => item === id)) {
      let temp = [...requestData];
      if (!temp[4]) temp[4] = [id];
      else temp[4] = [...temp[4], id];
      setRequestData(temp);
    } else {
      let temp = [...requestData];
      let index = temp[4].indexOf(id);
      if (index > -1) {
        temp[4].splice(index, 1);
      }
      setRequestData(temp);
    }
  };

  return (
    <Grid container xl={12} style={{ margin: "4rem auto 0 auto" }}>
      <Container
        container
        style={{
          /* marginBottom: "2rem", */
          marginTop: "2rem",
          width: "100%",
          borderBottom: "2px solid #194591",
        }}
      >
        <img src={slash} alt="slash" />
        <h1
          style={{
            color: "#194591",
            fontSize: "30px",
            marginLeft: "1rem",
          }}
        >
          {data?.name}
        </h1>
      </Container>
      <span
        style={{
          color: "#194591",
          fontSize: "18px",
          marginTop: "2.5rem",
          marginBottom: "2rem",
        }}
      >
        {data?.sub_steps?.[0]?.name}
      </span>
      <Grid
        container
        style={{
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          marginTop: "2rem",
        }}
      >
        {data?.sub_steps?.[0]?.data?.map((item, index) => (
          <MyBox
            item
            xs={6}
            sm={4}
            md={4}
            lg={3}
            key={index}
            onClick={() => handleChange(item?.id)}
          >
            <Box>
              <img
                src={
                  requestData?.[4]?.some((e) => e === item.id)
                    ? selectedInput
                    : input
                }
                alt="image"
              />
            </Box>
            <Grid
              item
              xs={12}
              lg={7}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "9px",
              }}
            >
              <Box
                item
                style={{
                  backgroundColor: requestData?.[4]?.some((e) => e === item.id)
                    ? "#EDFAFF"
                    : "white",
                  height: variant === "desktop" ? "150px" : "92px",
                  width: variant === "desktop" ? "150px" : "92px",
                  border: "2px solid #194591",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  width={variant === "desktop" ? "85px" : "60px"}
                  src={item.data_image}
                  alt="image"
                />
              </Box>
              <Box style={{ marginTop: "1rem" }}>
                <span
                  style={{
                    fontSize: "1rem",
                    color: requestData?.[4]?.some((e) => e === item.id)
                      ? "#FF744C"
                      : "#194591",
                  }}
                >
                  {item.name}
                </span>
              </Box>
            </Grid>
          </MyBox>
        ))}
      </Grid>
    </Grid>
  );
};

export default Users;
