import React from 'react';
import CardStyles from './styles';

const Card = ({
    className = '',
    style = null,
    color = '',
    background = '',
    linear = [],
    shadow = true,
    hover = false,
    margin = false,
    padding = true,
    children = null,
    onClick = null,
    onMouseEnter = null,
    onMouseLeave = null,
    overflowX = 'hidden',
    overflowY = 'hidden',
}) => (
    <CardStyles
        className={className}
        style={style}
        color={color}
        background={background}
        linear={linear}
        shadow={shadow}
        hover={hover}
        margin={margin}
        padding={padding}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        overflowX={overflowX}
        overflowY={overflowY}>
        {children}
    </CardStyles>
);

export default Card;
