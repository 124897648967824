import React from "react";
import { CircularProgress } from "@mui/material";

function Loading() {
  return (
    <CircularProgress
      sx={{
        color: "#21A7F9",
        left: "50%",
        bottom: "50%",
        position: "fixed",
      }}
    />
  );
}

export default Loading;
