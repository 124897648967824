export const ROUTES = {
  MAINPAGE: "/",
  SERVICES: "/services",
  PORTFOLIO: "/portfolio",
  ABOUT: "/about",
  PARTNER_COMPONIES: "/tech_partners",
  BLOG: "/blog",
  CAREER: "/career",
  MEET_THE_TEAM: "/team",
  GET_OFFER: "/getOffer",
  FACEBOOK: "http://facebook.com/westerops/",
  TWITTER: "http://twitter.com/westerops",
  INSTAGRAM: "https://www.instagram.com/westerops/?hl=en",
  LINKEDIN: "http://linkedin.com/company/westerops/",
  /* CLUTCH: "http://clutch.com/profile/westerops#summary", */
  CLUTCH:
    "https://clutch.co/profile/westerops?loginsalt=t2eywrmdjs&loginattempt=1#summary",
  UPWORK: "https://www.upwork.com/o/companies/~01aa2e77885378a4ae/",
};
