import { Box, Grid, Radio } from "@mui/material";
import React from "react";
import { Container } from "../../style";
import slash from "../../../../assets/getOffer/slash.svg";
import UnCheckIcon from "../../../../assets/UnCheckedIcon";
import CheckedIcon from "../../../../assets/CheckedIcon";

const DataComponent = ({ requestData, setRequestData, variant, data }) => {
  const handleChange = (id) => {
    let temp = [...requestData];
    temp[5] = id;
    setRequestData(temp);
  };
  return (
    <Grid container xl={12} style={{ margin: "2rem auto 0 auto" }}>
      <Container
        container
        style={{
          marginTop: "2rem",
          width: "100%",
          borderBottom: "2px solid #194591",
        }}
      >
        <img src={slash} alt="slash" />
        <h1 style={{ color: "#194591", fontSize: "30px", marginLeft: "1rem" }}>
          {data?.name}
        </h1>
      </Container>
      <Grid style={{ display: "flex", flexDirection: "column" }}>
        <span
          style={{
            color: "#194591",
            fontSize: "18px",
            marginTop: "2.5rem",
            marginBottom: "2rem",
          }}
        >
          {data?.sub_steps?.[0].name}
        </span>
        <Grid container style={{ display: "flex", flexDirection: "row" }}>
          {data?.sub_steps?.[0]?.data?.map((item, index) => (
            <Grid
              item
              lg={3}
              md={8}
              sm={10}
              xs={12}
              key={index}
              style={{
                width: "16rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box>
                <Radio
                  icon={<UnCheckIcon />}
                  checkedIcon={<CheckedIcon />}
                  checked={requestData[5] === item.id}
                  onChange={() => handleChange(item.id)}
                  value="b"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "B" }}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 40,
                      color: "#194591",
                    },
                  }}
                />
              </Box>
              <Box
                style={{
                  color: requestData[5] === item.id ? "#FF744C" : "#194591",
                }}
              >
                <span>{item.name}</span>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DataComponent;
