/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Typography } from "@mui/material";
import React from "react";
import { ServicesTitle, JoinUsHeaderBox, JobSelectionBox } from "./style";
import { useTranslation } from "react-i18next";

function index({ roles, active, setActive }) {
  const { t } = useTranslation();
  return (
    <JoinUsHeaderBox
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "48px",
      }}
    >
      <ServicesTitle variant="mediumTitle">{t("jobs.title")}</ServicesTitle>
      <JobSelectionBox
        sx={{
          display: "flex",
          gap: "40px",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box onClick={() => setActive(-1)}>
          <Typography
            sx={{
              cursor: "pointer",
              color: active === -1 ? "#FF744C" : "#194591",
              fontSize: "14px",
              fontWeight: "600 !important",
              fontFamily: "Open Sans",
            }}
            marginLeft={{ lg: "5rem", xs: "0rem" }}
          >
            {t("jobs.selectionDefault")}
          </Typography>
        </Box>
        {roles.map((role) => (
          <Box onClick={() => setActive(role.id)} key={index}>
            <Typography
              sx={{
                cursor: "pointer",
                color: active === role.id ? "#FF744C" : "#194591",
                fontSize: "14px",
                fontWeight: "600 !important",
                fontFamily: "Open Sans",
              }}
            >
              {role.name}
            </Typography>
          </Box>
        ))}
      </JobSelectionBox>
    </JoinUsHeaderBox>
  );
}

export default index;
