import styled from "styled-components";
import { Grid, TextField } from "@mui/material";

export const MyTextField = styled(TextField)`
  border: none;
  /* .css-5xn5lu-MuiInputBase-root-MuiInput-root::before {
    content: none;
  }
  .css-5xn5lu-MuiInputBase-root-MuiInput-root::after {
    content: none;
  } */
  *::before {
    content: none;
    border-bottom: none;
  }
  *::after {
    content: none;
    border-bottom: none;
  }
`;

export const FormTextField = styled(TextField)`
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 5px;
  }
`;

export const CustomGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  gap: 8px;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;
