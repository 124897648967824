/* eslint-disable import/no-anonymous-default-export */
export const ENLanguage = {
  slogan1: {
    part1: "We",
    part2: "Design ,",
    part3: "We",
    part4: "Develop ,",
    part5: "We",
    part6: "Deliver",
    buttonText: "Get Offer",
  },
  slogan2: {
    part1: "ALSO WE ARE A PARTNER WITH A RELENTLESS COMMITMENTS",
    part2: "TO OUR",
    part3: "CUSTOMER'S SUCCESS",
  },
  buttons: {
    getOffer: "GET OFFER",
    services: "Services",
    portfolio: "Portfolio",
    home: "Home",
    about: "About",
    tech_partners: "Technology Partners",
    blog: "Blog",
    career: "Career",
    meetTheTeam: "TEAM",
    findOut: "Find out what we can do together",
    submit: "Submit",
    sendMessage: "Send Message",
    signUp: "Sign Up",
    apply: "Apply",
    seeMore: "See More",
    sendForm: "Send Form",
  },
  ourServices: {
    title: "Our Services",
    text: "Software as Intended",
  },
  teamPage: {
    slogan1: "In WesterOps, ",
    slogan2: "team spirit ",
    slogan3: "is so important for us!",
    all: "Show All",
    leader: "Leader",
    projectManager: "Project Management",
    softwareDeveloper: "Software Developer",
    uiux: "UI/UX Design",
    marketting: "Marketting",
    humanResources: "Human Resources",
    consultant: "Consultant",
    devops: "DevOps Engineer",
    button: "Join Us",
  },
  technologies: {
    title: "Technologies We Used",
    text: "We can grow your team with the right tools and the latest skills and staff at the right time.",
  },
  projectJourney: {
    title: "Project Journey",
  },
  techPartners: {
    title: "Technology Partners",
  },
  westeropsTeam: {
    title: "Westerops Team",
    joinUsButton: "Join Us",
    seeAllTeam: "See the Team",
  },
  aboutus: {
    writeUs: "Write Us",
    writeUsMessage:
      "Contact us if you have a project you would like to discuss.",
  },
  tools: "TOOLS OF OUR TRADE",
  toolsSub1: "We can augment your team with cutting-edge skills and squads",
  toolsSub2: "at the right time with the right tools.",
  portfolio: "PORTFOLIO",
  team: "OUR TEAM",
  teamJobs: "You can be here",
  contactUs: "Contact Us",
  appointment: {
    tabName: "Get Appointment",
    pageTitle: "Let's Have a Meeting",
    interval: "Select a time interval",
    fName: "First Name",
    lName: "Last Name",
    email: "E-mail",
    category: {
      title: "Select a category",
      dev: "Development",
      devOps: "DevOps Consultancy",
      other: "Other",
    },
    desc: "Description",
    message: {
      itself: "We got your request!",
      desc: "We will contact you soon :)",
      err: "Something went wrong! Please Try Again.",
    },
    rules: {
      time: "Please select a time",
      name: "Please input your first name",
      lastName: "Please input your last name",
      email: "Please input your e-mail",
      validEmail: "Please input a valid e-mail",
      category: "Please select a category",
      desc: "Please fill the description",
    },
  },
  message: {
    tabName: "Send Message",
    pageTitle: "Let's Chat",
    subTitle: "Reach out if you have a project that you want to discuss.",
    fName: "First Name",
    lName: "Last Name",
    email: "E-mail",
    phone: "Phone Number",
    yourMessage: "Your Message",
    message: {
      sended: "Message has been sent!",
      failed: "Message could not be sent!",
      desc: "We got your message. Thank you!",
      err: "Something went wrong! Please Try Again.",
    },
    rules: {
      name: "Please input your first name",
      lastName: "Please input your last name",
      validEmail: "The input is not valid E-mail!",
      email: "Please input your E-mail!",
    },
  },

  footer: {
    London: "London",
    placeHolder: "E-mail",
    title: "Stay Connected",
    exp: "Sign up for latest news and promotions",
    successMessage: "Registration Successful",
    somethingWrong: "Something went wrong",
    invalidEmail: "Enter a valid e-mail address",
    emailExists: "This e-mail address is already registered",
    facebook: "Facebook",
    twitter: "twitter",
    instagram: "instagram",
    linkedin: "linkedin",
    clutch: "clutch",
    upwork: "upwork",
    offices: "Our Offices",
    communication: "Communication",
    kobilGroup: "Kobil Group",
  },

  jobs: {
    title: "Join Us",
    header: {
      part1: "Your Skills Are",
      part2: "Significant",
      part3: "For Us",
    },
    subheader:
      "We offer the opportunity to work with <br/> innovative technologies.",
    employee: "Employee Benefits",
    cards: {
      first: "Leader Support",
      second: "Private Helath Insurance",
      third: "Lunches",
      fourth: "Remote Work",
      fifth: "Mentoring",
    },
    processes: {
      first: "Application",
      second: "Assesment",
      third: "Interview",
      fourth: "Proposal",
      fifth: "Getting Start",
    },
    culture: "Company Culture",
    recruitment: "Our Recruitment Process",
    description: "Get information about jobs from WesterOps",
    subTitle: "CHECK OUT OPEN POSITIONS AND BECOME A PART OF WESTEROPS TEAM.",
    youWillDo: "What you will be doing?",
    qualifications: "Qualifications:",
    sendCV: "Please send your CV to:",
    noJobs: "No Job Postings",
    message: {
      itself: "Something went wrong!",
      description: "Couldn't fetch the list of Jobs",
    },
    location: "Etiler,Istanbul",
    hq: "Our Headquarters",
    selectionDefault: "All",
  },
  blog: {
    title: "Westerops Blog Page",
    description: "Detailed tech blogs , written by our precious team members",
    messages: {
      part1: "Welcome",
      part2: "to Westerops",
      part3: "",
      part4: "Blog Page!",
    },
    category: "Categories",
    buttonName: "Continue",
    details:
      "We showcase the technologies and approaches we use as a tutorial on this blog",
    author: "Author",
  },
  getOffer: {
    other: "Other",
    title: "Get Offer",
    stepTitle: "PRICING",
    estimatedTime: "Estimated time",
    estimatedPrice: "Estimated Price",
    description:
      "The features you have chosen about your product will be examined in detail by our customer service. Get an offer to find out the estimated pricing and completion times.",
    contact: "Create a request to meet with our sales team",
    modalTitle: " Just one step left...",
    modalSubTitle:
      "We need your contact information so that we can inform you about pricing details.",
    otherTag: "Other",

    errorMsg: {
      required: " is required",
      notValidEmail: "Input field should not be empty",
      successMessage: "Your request has been sent to our marketing team",
      successDescription: "We will contact you soon...",
      failMessage: "Something went wrong",
      failDescription: "Please check your feature selections",
    },
    label: {
      name: "Name",
      surname: "Surname",
      email: "E-mail",
    },
  },
  about: {
    Name: "Name",
    Surname: "Surname",
    Eposta: "E-mail",
    PhoneNumber: "Phone Number",
    Write: "Your Message",
    officeTitle1: "Istanbul Office",
    officeTitle2: "London Office",
    button: "Send Message",
  },
  getOffer: {
    consultancyFormButton: "Send Form",
    name: "Get Offer",
    title1: "Mobile/Web application development",
    title2: "Consultancy",
    Name: "Name",
    Surname: "Surname",
    Eposta: "E-mail",
    PhoneNumber: "Phone Number",
    Company: "Company",
    Position: "Position",
    Write: "Your Message",
    other: "Other",
    writePlease: "Write...",
  },
  project: {
    formContent:
      "We will review the form you filled out about your product with our team. We will then send you our offer as soon as possible, including estimated pricing and completion times.",
    writePlease: "Write...",
  },
  datePicker: {
    content:
      "If you wish to have a detailed discussion about your project before our team prepares your proposal, you can arrange an online meeting by choosing a day and time.",
    select: "Clear Choice",
  },
};
