import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HeadBanner from "./HeadBanner";
import EmployeeBenefits from "./EmployeeBenefits";
import CompanyCulture from "./CompanyCulture";
import Processment from "./Process";
import JoinUs from "./JoinUs";
import { CareerStyles } from "./styles";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_BASE_URL } from "../../../constants/api";
import { BlogsNotExistContainer } from "../../Blogs/Blog/styles";
import Loading from "../../../components/Loading";
import { motion, AnimatePresence } from "framer-motion";

const Career = () => {
  const { t, i18n } = useTranslation();

  const [careerData, setCareerData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios({
          url:
            API_BASE_URL +
            i18n.language +
            `/landing/companyculture/allInfo` +
            (i18n.language === "tr" ? `Tr/` : "En/"),
          method: "GET",
        });
        setCareerData(response.data[0]);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    };
    getData();
  }, [i18n.language]);
  return (
    <CareerStyles>
      <Helmet>
        <title>{t("jobs.title")}</title>
        <meta name="description" content={t("jobs.description")} />
      </Helmet>
      <HeadBanner />
      <AnimatePresence>
        {!careerData.benefits && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              width: "1000vw",
              height: "1000vw",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: "999999999",
            }}
          >
            <Loading />
          </motion.div>
        )}
      </AnimatePresence>
      <EmployeeBenefits benefitsData={careerData?.benefits} />
      <CompanyCulture
        title={careerData?.title}
        description={careerData?.description}
        companyCultureData={careerData?.CompanyCultureLogos}
        vision={careerData?.our_vision}
      />
      <Processment processData={careerData?.our_recruitment} />
      <JoinUs />
    </CareerStyles>
  );
};

export default Career;
