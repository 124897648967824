import { Box, Grid, Hidden } from "@mui/material";
import { useEffect, useState } from "react";
import Platform from "./components/Platform";
import Design from "./components/Design";
import Users from "./components/Users";
import TimingComponent from "./components/Timing";
import axios from "axios";
import { API_BASE_URL } from "../../constants/api";
import Technologies from "./components/Technologies";
import DataComponent from "./components/Data";
import EntegrationComponent from "./components/Entegrations";
import AdminComponent from "./components/Admin";
import ServicesComponent from "./components/Services";
import { MoreButton } from "../Blogs/BlogCard/styles";
import { ArrowRight } from "../../assets/icons";
import { useTranslation } from "react-i18next";
import form from "../../assets/form.svg";
import { CustomTabs, StyledTab, Container } from "./style";
import { useTheme } from "@mui/material/styles";
import { Modal, notifySuccess, notifyFailure } from "../../components/Modal";
import { motion, AnimatePresence } from "framer-motion";
import Loading from "../../components/Loading";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-offertabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Grid style={{ display: "flex" }}>{children}</Grid>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const GetOfferPage = () => {
  const [offerData, setOfferData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [projectDiger, setProjectDiger] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [message, setMessage] = useState("");
  const [entegDiger, setEntegDiger] = useState("");
  const [isEntegClicked, setEntegClicked] = useState(false);
  const [date, setDate] = useState(new Date());
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      try {
        const response = await axios({
          url:
            API_BASE_URL +
            i18n.language +
            `/estimate/${i18n.language}/listStep/`,
          method: "GET",
        });
        setOfferData(response.data.results);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    };
    getData();
  }, [i18n.language]);

  const sendForm = async () => {
    try {
      await axios({
        url: API_BASE_URL + i18n.language + `/estimate/createUserData/`,
        method: "POST",
        data: {
          email,
          name,
          surname,
          company,
          position,
          phone,
          enteg_diger: entegDiger,
          proje_diger: projectDiger,
          projectDetail: message,
          date: date.toISOString(),
          data: requestData
            .slice(1, 4)
            .concat(requestData[0])
            .concat(requestData[4])
            .concat(requestData[5])
            .concat(requestData[6])
            .concat(requestData[7])
            .concat(requestData[8])
            .concat(requestData.slice(9, 11)),
        },
      });
      notifySuccess();
      window.location.reload();
    } catch (error) {
      if (error?.response?.data?.error)
        notifyFailure(error?.response?.data?.error);
      else if (error?.response?.data?.email)
        notifyFailure(error?.response?.data?.email[0]);
      else if (error?.response?.data?.data)
        notifyFailure(error?.response?.data?.data[0]);
    }
  };

  const handleChange = (event) => {
    setProjectDiger(event.target.value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeSurname = (event) => {
    setSurname(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  };

  const handleChangeCompany = (event) => {
    setCompany(event.target.value);
  };

  const handleChangePosition = (event) => {
    setPosition(event.target.value);
  };

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleChangeEnteg = (event) => {
    setEntegDiger(event.target.value);
  };

  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const tabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Container>
      <AnimatePresence>
        {!offerData.length && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              width: "1000vw",
              height: "1000vw",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: "999999999",
            }}
          >
            <Loading />
          </motion.div>
        )}
      </AnimatePresence>
      <Grid container xl={11} style={{ padding: "0rem", margin: "0 auto" }}>
        <Modal />
        <CustomTabs
          value={tabValue}
          onChange={tabChange}
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          variant="fullWidth"
          style={{
            width: "100%",
            borderBottom: "2px solid #194591",
          }}
        >
          <StyledTab label={t("getOffer.title1")} {...a11yProps(0)} />
          <StyledTab label={t("getOffer.title2")} {...a11yProps(1)} />
        </CustomTabs>

        <Grid sx={{ width: "100%", padding: "0rem" }} index={tabValue}>
          <Hidden mdDown>
            <TabPanel value={tabValue} index={0} dir={theme.direction}>
              <Grid
                display="flex"
                justifyContent={{
                  xs: "flex-start",
                  md: "flex-start",
                  lg: "center",
                }}
                flexWrap={{ xs: "nowrap", md: "nowrap", lg: "wrap" }}
                overflow={{ xs: "scroll", md: "none" }}
                width="100%"
              >
                <Grid
                  container
                  style={{
                    overflow: "scroll",
                    display: "flex",
                  }}
                >
                  <Grid sx={{ width: "100%" }}>
                    <Platform
                      data={offerData[10]}
                      requestData={requestData}
                      setRequestData={setRequestData}
                      variant="desktop"
                    />
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <Design
                      data={offerData[9]}
                      requestData={requestData}
                      setRequestData={setRequestData}
                      variant="desktop"
                    />
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <Users
                      data={offerData[8]}
                      requestData={requestData}
                      setRequestData={setRequestData}
                      variant="desktop"
                    />
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <DataComponent
                      data={offerData[7]}
                      requestData={requestData}
                      setRequestData={setRequestData}
                      variant="desktop"
                    />
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <EntegrationComponent
                      data={offerData[6]}
                      requestData={requestData}
                      setRequestData={setRequestData}
                      variant="desktop"
                      onChangeText={handleChangeEnteg}
                      isClicked={isEntegClicked}
                      setClicked={setEntegClicked}
                    />
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <AdminComponent
                      data={offerData[5]}
                      requestData={requestData}
                      setRequestData={setRequestData}
                      variant="desktop"
                    />{" "}
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <Technologies
                      requestData={requestData}
                      setRequestData={setRequestData}
                      data={offerData[4]}
                      variant="desktop"
                    />{" "}
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <TimingComponent
                      requestData={requestData}
                      setRequestData={setRequestData}
                      data={offerData[3]}
                      variant="desktop"
                    />{" "}
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <ServicesComponent
                      requestData={requestData}
                      setRequestData={setRequestData}
                      data={offerData[0]}
                      onChangeText={handleChange}
                      handleName={handleChangeName}
                      handleSurname={handleChangeSurname}
                      handleEmail={handleChangeEmail}
                      handlePhone={handleChangePhone}
                      handleCompany={handleChangeCompany}
                      handlePosition={handleChangePosition}
                      handleMessage={handleChangeMessage}
                      setDate={setDate}
                      date={date}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <MoreButton
                      type="primary"
                      style={{
                        display: "flex",
                        alignSelf: "end",
                        width: "387px",
                        height: "48px",
                      }}
                      onClick={() => sendForm()}
                    >
                      <img src={form} alt="send-form" /> {t("buttons.sendForm")}
                      <ArrowRight marginLeft />
                    </MoreButton>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1} dir={theme.direction}>
              <Grid
                display="flex"
                justifyContent={{
                  xs: "flex-start",
                  md: "flex-start",
                  lg: "center",
                }}
                flexWrap={{ xs: "nowrap", md: "nowrap", lg: "wrap" }}
                overflow={{ xs: "scroll", md: "none" }}
                width="100%"
              >
                <ServicesComponent
                  requestData={requestData}
                  setRequestData={setRequestData}
                  data={offerData[1]}
                  onChangeText={handleChange}
                  name={name}
                  surname={surname}
                  email={email}
                  phone={phone}
                  company={company}
                  position={position}
                  message={projectDiger}
                  handleName={handleChangeName}
                  handleSurname={handleChangeSurname}
                  handleEmail={handleChangeEmail}
                  handlePhone={handleChangePhone}
                  handleCompany={handleChangeCompany}
                  handlePosition={handleChangePosition}
                  handleMessage={handleChangeMessage}
                  setDate={setDate}
                  date={date}
                  ifConsultancy={true}
                />
              </Grid>
            </TabPanel>
          </Hidden>
          <Hidden mdUp>
            <TabPanel value={tabValue} index={0} dir={theme.direction}>
              <Grid
                display="flex"
                justifyContent={{
                  xs: "flex-start",
                  md: "flex-start",
                  lg: "center",
                }}
                flexWrap={{ xs: "nowrap", md: "nowrap", lg: "wrap" }}
                overflow={{ xs: "scroll", md: "none" }}
                width="100%"
              >
                <Grid container style={{ overflow: "scroll" }}>
                  <Grid sx={{ width: "100%" }}>
                    <Platform
                      data={offerData[10]}
                      requestData={requestData}
                      setRequestData={setRequestData}
                      variant="mobile"
                    />
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <Design
                      data={offerData[9]}
                      requestData={requestData}
                      setRequestData={setRequestData}
                      variant="mobile"
                    />
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <Users
                      data={offerData[8]}
                      requestData={requestData}
                      setRequestData={setRequestData}
                      variant="mobile"
                    />
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <DataComponent
                      data={offerData[7]}
                      requestData={requestData}
                      setRequestData={setRequestData}
                      variant="mobile"
                    />
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <EntegrationComponent
                      data={offerData[6]}
                      requestData={requestData}
                      setRequestData={setRequestData}
                      variant="mobile"
                      onChangeText={handleChangeEnteg}
                      isClicked={isEntegClicked}
                      setClicked={setEntegClicked}
                    />
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <AdminComponent
                      data={offerData[5]}
                      requestData={requestData}
                      setRequestData={setRequestData}
                      variant="mobile"
                    />{" "}
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <Technologies
                      requestData={requestData}
                      setRequestData={setRequestData}
                      data={offerData[4]}
                      variant="mobile"
                    />{" "}
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <TimingComponent
                      requestData={requestData}
                      setRequestData={setRequestData}
                      data={offerData[3]}
                      variant="mobile"
                    />{" "}
                  </Grid>
                  <Grid sx={{ width: "100%" }}>
                    <ServicesComponent
                      requestData={requestData}
                      setRequestData={setRequestData}
                      data={offerData[0]}
                      onChangeText={handleChange}
                      handleName={handleChangeName}
                      handleSurname={handleChangeSurname}
                      handleEmail={handleChangeEmail}
                      handlePhone={handleChangePhone}
                      handleCompany={handleChangeCompany}
                      handlePosition={handleChangePosition}
                      handleMessage={handleChangeMessage}
                      setDate={setDate}
                      date={date}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <MoreButton
                      type="primary"
                      style={{
                        display: "flex",
                        alignSelf: "end",
                        width: "387px",
                        height: "48px",
                        marginTop: "5rem",
                        float: "right",
                      }}
                      onClick={() => {
                        sendForm();
                        setName("");
                        setSurname("");
                      }}
                    >
                      <img src={form} alt="send-form" /> {t("buttons.sendForm")}
                      <ArrowRight marginLeft />
                    </MoreButton>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1} dir={theme.direction}>
              <Grid
                display="flex"
                justifyContent={{
                  xs: "flex-start",
                  md: "flex-start",
                  lg: "center",
                }}
                flexWrap={{ xs: "nowrap", md: "nowrap", lg: "wrap" }}
                overflow={{ xs: "scroll", md: "none" }}
                width="100%"
              >
                <ServicesComponent
                  requestData={requestData}
                  setRequestData={setRequestData}
                  data={offerData[1]}
                  onChangeText={handleChange}
                  name={name}
                  surname={surname}
                  email={email}
                  phone={phone}
                  company={company}
                  position={position}
                  message={projectDiger}
                  handleName={handleChangeName}
                  handleSurname={handleChangeSurname}
                  handleEmail={handleChangeEmail}
                  handlePhone={handleChangePhone}
                  handleCompany={handleChangeCompany}
                  handlePosition={handleChangePosition}
                  handleMessage={handleChangeMessage}
                  setDate={setDate}
                  date={date}
                  ifConsultancy={true}
                />
              </Grid>
            </TabPanel>
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GetOfferPage;
