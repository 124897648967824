import styled from "styled-components";
import { Grid, Button, Box, Typography } from "@mui/material";
import CustomTypography from "../../components/CustomTypography";
import theme from "../../theme";

export const Wrapper = styled(Grid)`
  padding-bottom: 1rem;
  height: auto;
  margin: 0rem 0 0 0;
  width: 1516px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem 1rem 2rem;
  }
  .slick-slide > div {
    margin: 0 0 0 0;
  }
`;

export const Container = styled(Box)`
  .slick-dots li {
    width: 1.5rem;
  }

  .slick-dots li button::before {
    color: #c4c4c4;
    font-size: 1rem;
    opacity: 1;
  }
  .slick-dots li.slick-active button::before {
    color: #194591;
    opacity: 1;
  }
  .slick-dots li {
    margin: 0;
    padding: 0;
  }
  .slick-dots {
    list-style-type: circle;
    width: fit-content;
  }
`;

export const GridContainer = styled(Grid)`
  justify-content: "flex-start";
  margin: 2rem auto;
  display: flex !important;
  max-width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const LeftContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const DescTypography = styled(Typography)`
  font-size: 20px;
  @media (max-width: 1516px) {
    font-size: 18px;
  }
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export const TitleText = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2.5rem;
`;

export const Text = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 2.5rem;
  /*  max-height: 12rem; */
`;

export const StyledButton = styled(Button)`
  text-transform: none;
  border: 1px solid transparent;
  border-radius: 20px;
  color: ${theme.main.blue} !important;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.25rem 0.75rem;
  margin-top: 2.5rem;

  @media (min-width: 1530px) {
    font-size: 0.875rem;
  }
  @media (max-width: 1024px) {
    font-size: 0.75rem;
  }
  @media (max-width: 600px) {
    font-size: 0.675rem;
  }
`;
export const StyledTypography = styled(CustomTypography)`
  color: ${theme.main.blue};
  @media (min-width: 1530px) {
    font-size: 2rem;
    /*  margin-top: 2rem; */
  }
  @media (max-width: 1530px) {
    font-size: 2rem;
    /*  margin-top: 2rem; */
  }
  @media (max-width: 1024px) {
    font-size: 1.8rem;
    /* margin-top: 2rem; */
  }
`;

export const RightContainer = styled(Grid)``;
