/* eslint-disable no-unused-vars */
import { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { AppBar, Grid, Hidden, IconButton } from "@mui/material";
import Button from "../Button";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import "./styles.css";
import CustomLink from "../CustomLink";
import { NavLink, useNavigate } from "react-router-dom";
import { useHeaderLogic } from "../../hooks";
import Logo from "../../assets/mainLogo.svg";
import {
  TeamButton,
  GetOfferButton,
  LanguageButton,
  LgDownGrid,
  LogoBox,
} from "./style";

const Header = () => {
  const [state, setState] = useState(false);
  const { language, t, pages, handleChange } = useHeaderLogic();

  const toggleDrawer = (anchor, open) => (event) => {
    setState(!state);
  };
  const navigate = useNavigate();

  const list = (anchor) => (
    <Grid
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Divider />
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "5rem",
        }}
        className="mobile-nav-list"
      >
        {pages.map(({ name, path }) => (
          <CustomLink
            key={name}
            className="buttons nav-link nav-link--mobile"
            activeClassName="nav-link--active"
            to={path}
          >
            {name}
          </CustomLink>
        ))}

        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "10%",
            marginTop: "20%",
          }}
        >
          {/* <Button onClick={() => navigate("/team")} type="default-secondary">
            {t("buttons.meetTheTeam")}
          </Button> */}
          <Button
            onClick={() => navigate("/getoffer")}
            type="cyan"
            style={{ marginTop: "1rem" }}
          >
            {t("buttons.getOffer")}
          </Button>
        </Grid>
      </List>
    </Grid>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        background: "#f6f6f6",
        height: "6rem",
        justifyContent: "center",
      }}
    >
      <Hidden lgDown>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LgDownGrid
            width="1516px"
            wrap="nowrap"
            container
            justifyContent="space-between"
          >
            <Grid container item md={2.5} marginTop={{ lg: "0.5rem" }}>
              <NavLink to="/">
                <LogoBox>
                  <img
                    src={Logo}
                    alt="Westerops"
                    title="Westerops Logo"
                    id="logo"
                  />
                </LogoBox>
              </NavLink>
            </Grid>

            <Grid item container justifyContent="flex-end" alignItems="center">
              {pages.map(({ name, path }, index) => (
                <CustomLink
                  key={name}
                  className="nav-link"
                  activeClassName="nav-link--active"
                  to={path}
                  style={{
                    marginRight: index === pages.length - 1 ? "7%" : "2%",
                  }}
                >
                  {name}
                </CustomLink>
              ))}
            </Grid>

            <Grid
              item
              container
              md={2.5}
              flexWrap={"nowrap"}
              justifyContent="flex-end"
              alignItems="center"
            >
              {/* <TeamButton onClick={() => navigate("/team")}>
                {t("buttons.meetTheTeam").toUpperCase()}
              </TeamButton> */}
              <GetOfferButton type="cyan" onClick={() => navigate("/getoffer")}>
                {t("buttons.getOffer").toUpperCase()}
              </GetOfferButton>
              <LanguageButton
                onClick={() => handleChange(language === "tr" ? "en" : "tr")}
              >
                {language === "tr" ? "EN" : "TR".toUpperCase()}
              </LanguageButton>
            </Grid>
          </LgDownGrid>
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Grid
          container
          sx={{ padding: "0 19px 0 31px" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item justifyContent="center" alignItems="center">
            <NavLink to="/">
              <LogoBox>
                <img
                  src={Logo}
                  alt="Westerops"
                  title="Westerops Logo"
                  id="logo"
                />
              </LogoBox>
            </NavLink>
          </Grid>

          <Grid item>
            <IconButton
              onClick={toggleDrawer("right", true)}
              size="large"
              aria-label="open drawer"
              color="default"
            >
              <MenuIcon />
            </IconButton>

            <Drawer
              anchor="right"
              open={state}
              close={!state}
              onClose={toggleDrawer("right", false)}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "inherit",
                }}
              >
                <div>
                  <IconButton
                    sx={{
                      float: "right",
                      margin: "17px",
                      width: "24px",
                      height: "24px",
                    }}
                    onClick={toggleDrawer("right", false)}
                  >
                    <CloseIcon />
                  </IconButton>
                  {list("")}
                </div>
                <Grid className="img-container" display={"block"}>
                  <Box display={"flex"} justifyContent={"center"}>
                    <LanguageButton
                      onClick={() =>
                        handleChange(language === "tr" ? "en" : "tr")
                      }
                    >
                      {language === "tr" ? "EN" : "TR".toUpperCase()}
                    </LanguageButton>
                  </Box>
                </Grid>
              </Grid>
            </Drawer>
          </Grid>
        </Grid>
      </Hidden>
    </AppBar>
  );
};

export default Header;
