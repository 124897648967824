import { Button, Grid } from "@mui/material";
import styled from "styled-components";
export const Container = styled(Grid)``;

export const TeamContainer = styled(Grid)`
  width: 1516px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem;
  }

  .MuiBox-root::-webkit-scrollbar {
    display: none;
  }
  .MuiBox-root {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const HeadBannerButton = styled(Button)`
  font-family: "Open Sans";
  font-size: 12px;
  width: 176px;
  height: 48px;
  font-weight: bold;
  background-color: #ff744c;
  color: #fff;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
  &:hover {
    background-color: #ff744c;
    opacity: 0.8;
  }
`;
