import styled from "styled-components";
import { between } from "polished";
import Card from "../../../components/Card";
import Button from "../../../components/Button";
import { Grid } from "@mui/material";

export const BlogCardStyles = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 484px;
  margin: 2rem 0.5rem;
  padding: 0 0 10px 0;
  border-radius: 10px;
  @media (max-width: 1516px) {
    margin: 2rem 0;
  }
  @media (max-width: 760px) {
    width: 350px;
  }
  @media (max-width: 500px) {
    width: 300px;
  }

  &:hover {
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.main.shadowHover};
  }
`;

export const ImageContainer = styled(Grid)`
  img {
    width: 100%;
    height: ${between("150px", "190px")};
    object-fit: fill;
    border-bottom: 8px solid;
    border-color: rgb(242, 243, 248);
  }
`;
export const CardHeader = styled(Grid)`
  display: flex;
  flex: 1;
  padding-top: ${between("20px", "30px")};
  padding-left: ${between("15px", "20px")};
  padding-right: ${between("15px", "20px")};
  justify-content: flex-start;
  font-family: Open Sans;
  font-size: 20px;
  max-width: 100%;
  height: 102px;
`;

export const CardContent = styled(Grid)`
  padding-left: ${between("15px", "20px")};
  padding-right: ${between("15px", "20px")};
  margin-bottom: 20px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical;
  //border-top: 2px dashed #f0f2f5;
  font-family: Open Sans;
  font-size: 20px;
  width: 90%;
  height: 150px;
  @media (max-width: 760px) {
    padding-top: 1.5rem;
  } ;
`;

export const CardFooter = styled(Grid)`
  padding-left: ${between("15px", "20px")};
  padding-right: ${between("15px", "20px")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
`;

export const MoreButton = styled(Button)`
  /* max-width: 176px;
  margin-left: 1rem; */
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  margin-bottom: 25px;
  text-align: center;
  padding: 11px;
  .arrow {
    margin-left: 2px !important;
  }

  &:hover {
    text-decoration: none;
    background-color: rgb(241 89 48 / 63%);
    .arrow {
      transition: all 0.15s;
      padding-left: 6px;
    }
  }
`;
