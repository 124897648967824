/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../constants/routes";
import { useLocalStorage } from ".";
import Clutch from "../assets/footer/clutch.svg";
import Facebook from "../assets/footer/facebook.svg";
import Twitter from "../assets/footer/twitter.svg";
import Upwork from "../assets/footer/upwork.svg";
import instagram from "../assets/footer/Instagram.svg";
import linkedin from "../assets/footer/linkedin.svg";

const useFooterLogic = () => {
  const { storedValue: localLang, setValue } = useLocalStorage("lang");
  const { t, i18n } = useTranslation();

  const handleChange = (event) => {
    const { value } = event.target;
    i18n.changeLanguage(value);
    setValue(value);
  };

  const socialMedia = [
    {
      name: t("footer.facebook"),
      path: ROUTES.FACEBOOK,
      img: Facebook,
      isSvg: true,
    },
    {
      name: t("footer.twitter"),
      path: ROUTES.TWITTER,
      img: Twitter,
      isSvg: true,
    },
    {
      name: t("footer.instagram"),
      path: ROUTES.INSTAGRAM,
      img: instagram,
      isSvg: true,
    },
    {
      name: t("footer.linkedin"),
      path: ROUTES.LINKEDIN,
      img: linkedin,
      isSvg: true,
    },
    {
      name: t("footer.clutch"),
      path: ROUTES.CLUTCH,
      img: Clutch,
      isSvg: true,
    },
    {
      name: t("footer.upwork"),
      path: ROUTES.UPWORK,
      img: Upwork,
      isSvg: true,
    },
  ];

  const subPages = [
    {
      name: t("buttons.services"),
      path: ROUTES.SERVICES,
    },
    {
      name: t("buttons.portfolio"),
      path: ROUTES.PORTFOLIO,
    },
    {
      name: t("buttons.about"),
      path: ROUTES.ABOUT,
    },
    {
      name: t("buttons.tech_partners"),
      path: ROUTES.PARTNER_COMPONIES,
    },
    {
      name: t("buttons.blog"),
      path: ROUTES.BLOG,
    },
    {
      name: t("buttons.career"),
      path: ROUTES.CAREER,
    },
    {
      name: t("buttons.meetTheTeam"),
      path: ROUTES.MEET_THE_TEAM,
    },
    {
      name: t("getOffer.name"),
      path: ROUTES.GET_OFFER,
    },
  ];

  useEffect(() => {
    if (localLang) {
      i18n.changeLanguage(localLang);
    }
  }, [localLang, i18n]);

  return {
    language: i18n.language,
    handleChange,
    socialMedia,
    subPages,
    t,
  };
};
export default useFooterLogic;
