/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Grid, Radio, Typography } from "@mui/material";
import React from "react";
import { Container, MyBox } from "./style";
import slash from "../../../../assets/getOffer/slash.svg";
import CheckedIcon from "../../../../assets/CheckedIcon";
import UnCheckIcon from "../../../../assets/UnCheckedIcon";

const Design = ({ requestData, setRequestData, variant, data }) => {
  const handleChange = (id, index) => {
    let temp = [...requestData];
    temp[index] = id;
    setRequestData(temp);
  };
  return (
    <Grid container xl={12} style={{ margin: "4rem auto 0 auto" }}>
      <Container container>
        <img src={slash} alt="slash" />
        <h1 style={{ color: "#194591", fontSize: "30px", marginLeft: "1rem" }}>
          {data?.name}
        </h1>
      </Container>
      <span
        style={{
          color: "#194591",
          fontSize: "20px",
          marginTop: "2.5rem",
          marginBottom: "2rem",
        }}
      >
        {data?.sub_steps?.[0]?.name}
      </span>
      <Grid
        container
        style={{
          margin: "0rem auto 0 auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        {data?.sub_steps?.[0]?.data.map((item, index) => (
          <MyBox
            item
            xs={6}
            sm={4}
            md={4}
            lg={3}
            key={index}
            style={{ marginTop: "2rem" }}
          >
            <Radio
              icon={<UnCheckIcon />}
              checkedIcon={<CheckedIcon />}
              checked={requestData[1] === item.id}
              onChange={() => handleChange(item.id, 1)}
              value="b"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 40,
                  color: "#194591",
                },
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "11px",
              }}
            >
              <Box
                style={{
                  backgroundColor:
                    requestData[1] === item.id ? "#EDFAFF" : "white",
                  height: variant === "desktop" ? "150px" : "92px",
                  width: variant === "desktop" ? "150px" : "92px",
                  border: "2px solid #194591",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  width={variant === "desktop" ? "85px" : "60px"}
                  src={item.data_image}
                  alt="image"
                />
              </Box>
              <Typography
                sx={{
                  marginTop: "1rem",
                  fontSize: "1rem",
                  color: requestData[1] === item.id ? "#FF744C" : "#194591",
                  width: variant === "desktop" ? "150px" : "92px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {item.name}
              </Typography>
            </div>
          </MyBox>
        ))}
      </Grid>
      <Grid style={{ display: "flex", flexDirection: "column" }}>
        <Grid style={{ display: "flex", flexDirection: "column" }}>
          <span
            style={{
              color: "#194591",
              fontSize: "18px",
              marginTop: "6rem",
              marginBottom: "2rem",
            }}
          >
            {data?.sub_steps?.[1]?.name}
          </span>
          <Grid container style={{ display: "flex", flexDirection: "row" }}>
            {data?.sub_steps?.[1]?.data.map((item, index) => (
              <Grid
                item
                xl={4}
                lg={4}
                md={12}
                sm={12}
                xs={12}
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Radio
                    icon={<UnCheckIcon />}
                    checkedIcon={<CheckedIcon />}
                    checked={requestData[2] === item.id}
                    onChange={() => handleChange(item.id, 2)}
                    value="b"
                    name="radio-buttons"
                    inputProps={{ "aria-label": "B" }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 40,
                        color: "#194591",
                      },
                      /* "input[type='radio'] ": {
                        border: "2px solid red",
                      }, */
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    width: "100%",
                    color: requestData[2] === item.id ? "#FF744C" : "#194591",
                  }}
                >
                  {item.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid style={{ marginTop: "4rem" }}>
          <span
            style={{
              color: "#194591",
              fontSize: "18px",
              marginBottom: "2rem",
            }}
          >
            {data?.sub_steps?.[2]?.name}
          </span>
          <Grid
            container
            style={{
              marginTop: "2rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            {data?.sub_steps?.[2]?.data.map((item, index) => (
              <MyBox item xl={4} lg={5} md={4} sm={4} xs={6} key={index}>
                <Radio
                  icon={<UnCheckIcon />}
                  checkedIcon={<CheckedIcon />}
                  checked={requestData[3] === item.id}
                  onChange={() => handleChange(item.id, 3)}
                  value="b"
                  name="radio-buttons"
                  inputProps={{ "aria-label": "B" }}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 40,
                      strokeWidth: "0.5px",
                      color: "red",
                    },
                    /* "input[type='radio'] ": {
                      border: "2px solid red",
                    }, */
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "11px",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor:
                        requestData[3] === item.id ? "#EDFAFF" : "white",
                      height: variant === "desktop" ? "150px" : "92px",
                      width: variant === "desktop" ? "150px" : "92px",
                      border: "2px solid #194591",
                      borderRadius: "8px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1 style={{ color: "#194591" }}>{item.name}</h1>
                  </Box>
                  <span
                    style={{
                      marginTop: "1rem",
                      fontSize: "1rem",
                      color: requestData[3] === item.id ? "#FF744C" : "#194591",
                      width: variant === "desktop" ? "150px" : "92px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {item.name}
                  </span>
                </div>
              </MyBox>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Design;
