import { Button, Grid, TextField, Box, Typography } from "@mui/material";
import styled from "styled-components";

export const WriteUsContainer = styled(Grid)`
  display: flex;
  width: 100%;
  @media (max-width: 1200px) {
    padding: 0 2rem;
    flex-direction: column;
  }
`;

export const CoverBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  width: 1516px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    /* padding: 0 2rem; */
    flex-direction: column;
  }
`;

export const InputsContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
    background-color: #ff744c;
  }

  .MuiFilledInput-root:hover:not(.Mui-disabled):before {
    border: 0;
  }
  .MuiInputLabel-root {
    font-family: "Open Sans";
  }
  .MuiInputLabel-root.Mui-focused {
    color: #a8a8a8;
  }
  .MuiFilledInput-root::before {
    border: 0 !important;
  }
  .MuiFilledInput-root::after {
    border: 0;
  }
`;
export const CustomTextField = styled(TextField)`
  input {
    background-color: #ffffff;
    border: 1px solid #c4c4c4 !important;
    border-radius: 0.3rem;
    color: #194591;
    font-size: 14px;
  }
  input:focus {
    border: 1px solid #194591 !important;
    border-radius: 0.3rem;
  }
`;
export const CustomTextArea = styled(TextField)`
  .MuiFilledInput-root {
    background-color: #ffffff !important;
    border: 1px solid #c4c4c4 !important;
    border-radius: 0.3rem;
    color: #194591;
    font-size: 14px;
  }
  .MuiFilledInput-root:focus-within {
    border: 1px solid #194591 !important;
    border-radius: 0.3rem;
  }
`;
export const HeadBannerButton = styled(Button)`
  font-family: "Open Sans";
  font-size: 12px;
  width: 176px;
  height: 48px;
  font-weight: bold;
  background-color: #ff744c;
  color: #fff;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
  float: right;
  margin-right: 0.5rem;
  margin-top: 1rem;
  &:hover {
    background-color: #ff744c;
    opacity: 0.8;
  }
`;

export const TextInput = styled(TextField)`
  & label.Mui-focused {
    color: white;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: white;
    }
  }
`;

export const WriteUsTitleDesc = styled(Typography)`
  color: #194591;
  font-family: "Open Sans";
  @media (max-width: 680px) {
    font-size: 13px;
  }
`;
