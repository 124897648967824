/* eslint-disable import/no-anonymous-default-export */
export const TRLanguage = {
  slogan1: {
    part1: "",
    part2: "Tasarlıyoruz ,",
    part3: "",
    part4: "Geliştiriyoruz ,",
    part5: "",
    part6: "Teslim Ediyoruz",
    buttonText: "Teklif Al",
  },
  slogan2: {
    part1: "AMAÇLANAN ŞEKİLDE",
    part2: "",
    part3: "YAZILIM",
  },
  buttons: {
    getOffer: "TEKLİF AL",
    services: "Servisler",
    portfolio: "Portfoyümüz",
    home: "Ana Sayfa",
    about: "Hakkımızda",
    tech_partners: "Teknoloji Ortakları",
    blog: "Blog",
    career: "Kariyer",
    meetTheTeam: "TAKIM",
    findOut: "Birlikte neler yapabileceğimizi keşfet",
    submit: "Gönder",
    sendMessage: "Mesaj Gönder",
    signUp: "Kaydol",
    apply: "Başvur",
    seeMore: "Daha fazla gör",
    sendForm: "Formu Gönder",
  },
  ourServices: {
    title: "Servislerimiz",
    text: "Amaçlanan Şekilde Yazılım",
  },
  teamPage: {
    slogan1: "WesterOps olarak ",
    slogan2: "takım ruhu",
    slogan3: "bizim için çok önemli!",
    all: "Tümü",
    leader: "Lider",
    projectManager: "Proje Yönetimi",
    softwareDeveloper: "Yazılım Geliştirme",
    uiux: "UI/UX Tasarım",
    marketting: "Pazarlama",
    consultant: "Danışmanlarımız",
    humanResources: "İnsan Kaynakları",
    devops: "DevOps Mühendisi",
    button: "Aramıza Katıl",
  },
  technologies: {
    title: "Kullandığımız Teknolojiler",
    text: "Ekibinizi, doğru araçlarla doğru zamanda en son beceriler ve kadrolarla büyütebiliriz.",
  },
  projectJourney: {
    title: "Projenizin Yolculuğu",
  },
  aboutus: {
    writeUs: "Bize Yazın",
    writeUsMessage: "Görüşmek istediğiniz bir projeniz varsa bize ulaşın.",
  },
  techPartners: {
    title: "Teknoloji Ortakları",
  },
  westeropsTeam: {
    title: "Westerops Takımı",
    joinUsButton: "Aramıza Katıl",
    seeAllTeam: "Tüm Takımı Gör",
  },
  tools: "KULLANDIĞIMIZ TEKNOLOJİLER",
  toolsSub1:
    "Ekibinizi, doğru araçlarla doğru zamanda en son beceriler ve kadrolarla büyütebiliriz.",
  toolsSub2: "",
  portfolio: "PORTFÖY",
  team: "TAKIM",
  teamJobs: "Burada olabilirsin",
  contactUs: "İletişim",

  appointment: {
    tabName: "Randevu Al",
    pageTitle: "Bize Ulaşın",
    interval: "Zaman aralığı",
    fName: "İsim",
    lName: "Soyisim",
    email: "E-mail",
    category: {
      title: "Kategori seç",
      dev: "Yazılım geliştirme",
      devOps: "DevOps Danışmanlık",
      other: "Diğer",
    },
    desc: "Açıklama",
    message: {
      itself: "Talebiniz tarafımıza ulaştı",
      desc: "En kısa sürede sizinle iletişime geçeceğiz",
      err: "Bir şeyler yanlış gitti! Lütfen tekrar deneyin",
    },
    rules: {
      time: "Lütfen zaman aralığı seçin",
      name: "Lütfen isminizi girin",
      lastName: "Lüften soyisminizi girin",
      email: "Lütfen e-mail adresinizi girin",
      validEmail: "Lütfen geçerli bir e-mail adresi girin",
      category: "Lüften bir kategori seçin",
      desc: "Lüften bir açıklama girin",
    },
  },
  message: {
    tabName: "Mesaj Gönder",
    pageTitle: "Bize Ulaşın",
    subTitle: "Görüşmek istediğiniz bir projeniz varsa bize ulaşın.",
    fName: "İsim",
    lName: "Soyisim",
    email: "E-mail",
    phone: "Telefon Numaranız",
    yourMessage: "Mesajınız",
    message: {
      sended: "Mesajınız Gönderildi!",
      failed: "Mesajınız Gönderilemedi!",
      desc: "Mesajınızı aldık. teşekkür ederiz!",
      err: "Bir şeyler yanlış gitti! Lütfen tekrar deneyin.",
    },
    rules: {
      name: "Lütfen isminizi girin",
      lastName: "Lüften soyisminizi girin",
      email: "Lütfen e-mail adresinizi girin",
      validEmail: "Lütfen geçerlı bir e-mail adresi girin",
    },
  },
  footer: {
    London: "Londra",
    placeHolder: "E-posta",
    title: "İletişimde Kalın",
    exp: "En son haberler ve promosyonlar için kaydolun",
    successMessage: "Kayıt başarılı",
    somethingWrong: "Bir şeyler yanlış gitti! Lütfen tekrar deneyin.",
    invalidEmail: "Lütfen geçerli bir e-mail adresi girin",
    emailExists: "Bu e-mail adresi zaten kayıtlı",
    facebook: "Facebook",
    twitter: "twitter",
    instagram: "instagram",
    linkedin: "linkedin",
    clutch: "clutch",
    upwork: "upwork",
    offices: "Ofislerimiz",
    communication: "İletişim",
    kobilGroup: "Kobil Group",
  },

  jobs: {
    title: "Ekibimize Katılın",
    header: {
      part1: "Yeteneğin",
      part2: "Bizim İçin",
      part3: "Değerli",
    },
    subheader:
      "Fark yaratan yeteneklere yenilikçi teknolojilerle <br/> çalışma fırsatı sunuyoruz.",
    employee: "Şirket Faydaları",
    cards: {
      first: "Lider Desteği",
      second: "Özel Sağlık Sigortası",
      third: "Yemek",
      fourth: "Uzaktan Çalışma",
      fifth: "Danışmanlık",
    },
    processes: {
      first: "Başvuru",
      second: "Değerlendirme",
      third: "Mülakat",
      fourth: "Teklif",
      fifth: "İşe Başlangıç",
    },
    culture: "Kurum Kültürü",
    recruitment: "İşe Alım Sürecimiz",

    description: "WesterOps şirketinde iş fırsatlarından haberdar olun",
    subTitle: "SANA UYGUN POZİSYONLARI İNCELE VE EKİBİMİZİN BİR PARÇASI OL.",
    youWillDo: "Sorumluluklar:",
    qualifications: "Beceriler:",
    sendCV: "Lütfen CV'nizi şu adrese gönderin:",
    noJobs: "İş İlanı Yok",
    message: {
      itself: "Bir şeyler yanlış gitti!",
      description: "İş ilanları çekilemedi",
    },
    location: "Etiler,Istanbul",
    hq: "Ofislerimiz",
    selectionDefault: "Tümü",
  },
  blog: {
    title: "Westerops Blog Sayfası",
    description: "Değerli takımımız tarafından yazılmış güncel bloglar",
    messages: {
      part1: "Westerops blog",
      part2: "sayfasına",
      part3: "",
      part4: "Hoş geldiniz!",
    },
    buttonName: "Devamı",
    category: "Kategoriler",
    details:
      "Kullandığımız teknolojiler ve yaklaşımları bu blogda bir eğitim niteliğinde sergilemekteyiz",
    author: "Yazar",
  },
  getOffer: {
    other: "Diğer",
    title: "Teklif alın",
    stepTitle: "FİYATLANDIRMA",
    estimatedTime: "Ürünün tahmini tamamlanma süresi",
    estimatedPrice: "Öngörülen tahmini fiyat",
    description:
      "Ürününüz hakkında seçmiş olduğunuz özellikler , müşteri hizmetimiz tarafından detaylı olarak incelenecektir.Tahmini fiyatlandırma ve tamamlanma sürelerini öğrenmek için teklif alın.",
    contact: "Satış ekibimizle görüşme talebi oluşturun",
    modalTitle: "Sadece bir adım daha kaldı...",
    modalSubTitle:
      "Size fiyatlandırma detayları hakkında bilgilendirebilmemiz için iletişim bilgilerinize ihtiyacımız var.",

    errorMsg: {
      required: "Bu alanı boş bırakmamalısınız",
      notValidEmail: " formatı uygun değil",
      successMessage: "Talebiniz müşteri hizmetlerimize iletildi",
      successDescription: "Bizden haber bekleyin...",
      failMessage: "Bir şeyler ters gitti",
      failDescription: "Seçtiğiniz özellikleri kontrol edip tekrar deneyin",
    },
    label: {
      name: "İsim",
      surname: "Soyisim",
      email: "E-posta",
    },
  },
  about: {
    title: {
      part1: "WesterOps",
      part2: "kimdir?",
    },
    officeTitle1: "İstanbul Ofisimiz",
    officeTitle2: "Londra Ofisimiz",
    button: "Mesaj Gönder",
    description1:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pretium odio et magna convallis, eu consectetur ligula dignissim. Nam varius mi et tortor pellentesque, quis ullamcorper sem dignissim. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
    description2:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pretium odio et magna convallis, eu consectetur.",
    Name: "İsim",
    Surname: "Soyisim",
    Eposta: "E-Posta",
    PhoneNumber: "Telefon Numarası",
    Write: "Mesajınız",
  },
  getOffer: {
    consultancyFormButton: "Formu Gönder",
    other: "Diğer",
    name: "Teklif Al",
    title1: "Mobile/Web Uygulama Geliştirme",
    title2: "Danışmanlık",
    Name: "İsim",
    Surname: "Soyisim",
    Eposta: "E-mail",
    PhoneNumber: "Telefon Numarası",
    Company: "Şirket",
    Position: "Pozisyon",
    Write: "Mesajınız",
    writePlease: "Yazınız...",
  },
  project: {
    formContent:
      "Ürününüz hakkında doldurmuş olduğunuz formu ekibimizle inceleyeceğiz. Ardından sizlere en kısa zamanda tahmini fiyatlandırma ve tamamlanma sürelerini içeren teklifimizi ileteceğiz.",
    writePlease: "Yazınız...",
  },
  datePicker: {
    content:
      "Dilerseniz ekibimiz teklifinizi hazırlamadan önce projeniz hakkında detaylı bir görüşme yapmak isterseniz gün ve saat seçerek online toplantı ayarlayabilirsiniz.",
    select: "Seçimi Temizle",
  },
};
