import React from "react";
import { ArrowLeft, ArrowRight } from "../../assets/icons";
import { CustomGrid } from "./style";

function index({ slider }) {
  const handleArrowLeft = () => {
    slider.current.slickPrev();
  };
  const handleArrowRight = () => {
    slider.current.slickNext();
  };
  return (
    <CustomGrid sx={{ float: "right", zIndex: 999}}>
      <ArrowLeft onClick={handleArrowLeft} color="#194591" />
      <ArrowRight
        onClick={handleArrowRight}
        color="#194591"
        marginLeft="20px"
      />
    </CustomGrid>
  );
}

export default index;
