import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_BASE_URL } from "../../constants/api";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import TechPartnersComponent from "../../components/TechPartnersComponent";
import { motion, AnimatePresence } from "framer-motion";
import Loading from "../../components/Loading";

const TechPartnersPage = () => {
  const location = useLocation();
  const { cardIndex } = location.state === null ? 0 : location.state;
  const { i18n } = useTranslation();
  const [techPartners, setTechPartners] = useState([]);

  useEffect(() => {
    fetch(
      `${API_BASE_URL}${i18n.language}/technologypartner/mainpartner/${
        i18n.language === "tr" ? `listTr/` : "listEn/"
      }`
    )
      .then((res) => res.json())
      .then((result) => {
        setTechPartners(result);
        document?.getElementById("test-" + cardIndex)?.scrollIntoView(true);
      });
  }, [i18n.language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid
      container
      sx={{
        margin: "6rem auto 0 auto",
      }}
    >
      <AnimatePresence>
        {!techPartners.length && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              width: "1000vw",
              height: "1000vw",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: "999999999",
            }}
          >
            <Loading />
          </motion.div>
        )}
      </AnimatePresence>
      {techPartners.map((item, index) => (
        <TechPartnersComponent
          key={index}
          background={index}
          partner={item}
          _index={index}
        />
      ))}
    </Grid>
  );
};

export default TechPartnersPage;
