import { Box } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled(Box)`
  padding-bottom: 1rem;
  width: 100%;
  margin: 2rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .slick-slide > div {
    margin: 0 -5px 0 0;
  }
`;

export const Container = styled(Box)`
  width: 110%;

  .slick-dots li {
    width: 1.5rem;
  }

  .slick-dots li button::before {
    color: #c4c4c4;
    opacity: 1;
    font-size: 1rem;
    margin-top: 1rem;
  }

  .slick-dots li.slick-active button::before {
    color: #194591;
    opacity: 1;
  }
  .slick-dots li {
    margin: 0;
    padding: 0;
  }
  .slick-dots {
    list-style-type: circle;
    width: fit-content;
  }
`;
