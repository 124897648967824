import styled from "styled-components";
import { Grid } from "@mui/material";
import { between } from "polished";

export const CareerHeader = styled(Grid)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";
  font-size: 34px;
  .sentence-image {
    width: 35px;
    height: 65px;
  }
  h3 {
    color: rgb(35, 63, 141);
    font-size: 34px;
    font-family: "Open Sans";
    font-weight: bold;
    margin: 20px;
  }
  p {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    text-align: center;
  }
`;
export const MdDownContainer = styled(Grid)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 1516px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem;
  }
`;
export const MdUpContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const CultureLayout = styled.div`
  background-color: #f6f6f6;
  gap: 32px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin: 3rem 0 5rem;
`;

export const EmployeeCard = styled(Grid)`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 214px;
  padding: 1rem;
  width: 30%;
  display: flex;
  color: #4a4a4a;
  box-shadow: ${({ theme }) => theme.main.shadowCard};
  border-radius: 8px;
  h3 {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: bold;
    color: #19bdfb;
    margin: 0;
    text-align: left;
  }
  p {
    align-items: flex-start;
    font-family: Open Sans;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
  }
  @media (max-width: 1700px) {
    p {
      line-height: 20px;
      font-size: 12px;
    }
    h3 {
      font-size: 18px;
    }
  }
  @media (max-width: 1200px) {
    p {
      line-height: 18px;
      font-size: 11px;
    }
    h3 {
      font-size: 16px;
    }
  }
  @media (max-width: 1000px) {
    p {
      line-height: 18px;
      font-size: 10px;
    }
    h3 {
      font-size: 16px;
    }
  }
`;
