import Tab from "@mui/material/Tab";
import { Tabs, Grid } from "@mui/material";
import styled from "styled-components";

export const StyledTab = styled(Tab)`
  font-family: "Open Sans";
  font-weight: bold;
  text-transform: none;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 2px solid #194591;
  border-bottom: 0px;
  color: ${({ selected }) => (selected ? "#FFFFFF" : "#194591")} !important;
  font-weight: bold;
  font-size: 1.375rem;
  border-radius: 8px 8px 0 0;
  background-color: ${({ selected }) => (selected ? "#194591" : "#FFFFFF")};
  height: ${({ selected }) => (selected ? "5rem" : "4rem")};
  width: 40%;
  padding: 1rem 1.5rem;
  align-items: flex-start;
  @media (max-width: 1024px) {
    font-size: 1.125rem;
    padding: 1rem 1.5rem;
    height: ${({ selected }) => (selected ? "4rem" : "3rem")};
  }
  @media (max-width: 768px) {
    font-size: 1.125rem;
    padding: 0.5rem 1rem;
    height: ${({ selected }) => (selected ? "4rem" : "3rem")};
  }
  @media (max-width: 600px) {
    font-size: 1rem;
    padding: 0.5rem 0.5rem;
    height: ${({ selected }) => (selected ? "58px" : "50px")};
  }
`;

export const TechnologiesGrid = styled(Grid)`
  *::-webkit-scrollbar {
    display: none;
  }
  * {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const CustomTabs = styled(Tabs)`
  /* border: 1px solid red; */
  *::-webkit-scrollbar {
    display: none;
  }
  * {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .MuiTabs-flexContainer {
    align-items: flex-end;
    gap: 2rem;
    @media (max-width: 1200px) {
      padding-left: 31px;
      padding-right: 31px;
      overflow: scroll;
    }
    @media (max-width: 768px) {
      overflow: scroll;
      gap: 1.5rem;
    }
    @media (max-width: 600px) {
      overflow: scroll;
      gap: 1rem;
    }
  }
  .MuiTabs-indicator {
    height: 0px;
  }
`;
