import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import HeadBanner from "./components/HeadBanner";
import MainPagePortfolioSlider from "./components/MainPagePortfolioSlider";
import Products from "./components/Products";
import ProjectJourney from "./components/ProjectJourney";
import ServicesSlider from "./components/ServicesSlider";
import TechPartners from "./components/TechPartners";
import Technolojies from "./components/Teknologies";
import WesteropsTeam from "./components/WesteropsTeam";

const MainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Grid container overflow="hidden">
      <HeadBanner />
      <ServicesSlider />
      <MainPagePortfolioSlider />
      <Technolojies />
      <Products />
      <ProjectJourney />
      <TechPartners />
      {/* <WesteropsTeam /> */}
    </Grid>
  );
};

export default MainPage;
