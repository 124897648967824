import styled from "styled-components";
import { Grid, Button } from "@mui/material";
import CustomTypography from "../../../../components/CustomTypography";

export const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 9rem auto 0 auto;
  width: 1516px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem;
    margin-top: 2rem;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const LeftContainer = styled(Grid)`
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Text = styled(Grid)`
  margin: 1rem 0;
  background: #194591;
  border-radius: 6px;
  color: #fff;
  padding: 1rem;
  width: max-content;
`;

export const StyledTypography = styled(CustomTypography)`
  @media (max-width: 1024px) {
    font-size: 2rem;
  }
  @media (max-width: 768px) {
    font-size: 1.75rem;
  }
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

export const ButtonContainer = styled(Grid)`
  margin: 10px 0;
`;

export const HeadBannerButton = styled(Button)`
  font-size: 14px;
  background-color: #ff744c;
  color: #fff;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
`;

export const RightContainer = styled(Grid)`
  padding-bottom: 5rem;
  display: flex;
  @media (max-width: 1200px) {
    justify-content: center;
    margin: 5rem auto 0;
  }
`;
