import React, { useState, useEffect } from "react";
import { GetOfferDateArea } from "./style";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CalendarPicker from "@mui/lab/CalendarPicker";
import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import TimePicker from "@mui/lab/TimePicker";
import { useTranslation } from "react-i18next";

const minDate = new Date();
const maxDate = new Date("2034-01-01T00:00:00.000");

const DatePicker = ({ setDate, date }) => {
  const [time, setTime] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (time) date.setHours(time.getHours(), time.getMinutes(), 0);
  }, [time]);

  const reset = () => {
    var today = new Date();
    setTime(today);
    setDate(today);
  };

  return (
    <LocalizationProvider container dateAdapter={AdapterDateFns}>
      <Grid
        style={{
          border: "1px solid #C4C4C4",
          borderRadius: "5px",
          height: "98.5%",
        }}
      >
        <GetOfferDateArea>
          <Grid item lg={6}>
            <Grid
              style={{
                color: "#194591",
                fontSize: "1rem",
                width: "250px",
                margin: "5rem auto",
              }}
            >
              {t("datePicker.content")}{" "}
            </Grid>
            <Grid
              style={{
                color: "#194591",
                fontSize: "14px",
                textDecoration: "underline",
                width: "250px",
                margin: "0 auto",
              }}
              onClick={() => reset()}
            >
              {t("datePicker.select")}
            </Grid>
          </Grid>
          <Grid item lg={6} style={{ marginTop: "2rem" }}>
            <CalendarPicker
              date={date}
              onChange={(newDate) => setDate(newDate)}
              minDate={minDate}
              maxDate={maxDate}
            />
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Grid
                style={{
                  color: "#194591",
                  fontSize: "20px",
                  width: "66px",
                  fontWeight: "600",
                }}
              >
                Time
              </Grid>
              <TimePicker
                ampm={true}
                ampmInClock={false}
                value={time}
                onChange={setTime}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      width: "200px",
                      marginTop: "-14px",
                      marginBottom: "14px",
                    }}
                    {...params}
                  />
                )}
              />
              {/* <Grid
                  container
                  style={{ flexDirection: "row", alignItems: "center", justifyContent: "center"}}
                >
                  <Grid
                    style={{
                      width: "52px",
                      height: "56px",
                      backgroundColor: color === true ? "white" : "#194591",
                      border: "3px solid #194591",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "20px",
                      fontWeight: "600",
                      color: color === true ? "#194591" : "white",
                      marginLeft: "-1rem"
                    }}
                    onClick={() => setColor(true)}
                  > AM </Grid><Grid
                  style={{
                    width: "52px",
                    height: "56px",
                    backgroundColor: color === true ? "#194591" : "white",
                    border: "3px solid #194591",
                    marginLeft: "-4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "20px",
                    fontWeight: "600",
                    color: color === true ? 'white' : "#194591"
                  }}
                  onClick={() => setColor(false)}
                > PM
                  </Grid>
                </Grid> */}
            </Grid>
          </Grid>
        </GetOfferDateArea>
      </Grid>
    </LocalizationProvider>
  );
};

export default DatePicker;
