import styled from 'styled-components';

export default styled.div`
    height: ${({ full100vh }) => (full100vh ? '100vh' : '100%')};

    padding-right: ${({ paddingHorizontal, theme }) => paddingHorizontal || theme.content.paddingHorizontal};
    padding-left: ${({ paddingHorizontal, theme }) => paddingHorizontal || theme.content.paddingHorizontal};

    padding-top: ${({ paddingVertical, theme }) => paddingVertical || theme.content.paddingVertical};
    padding-bottom: ${({ paddingVertical, theme }) => paddingVertical || theme.content.paddingVertical};

    background-color: ${({ bgColor, theme }) => bgColor || theme.content.backgroundColor};

`;
