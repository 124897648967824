/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { MyBox } from "./style";
import slash from "../../../../assets/getOffer/slash.svg";
import { Container } from "./style";
import selectedInput from "../../../../assets/getOffer/selectedInput.svg";
import input from "../../../../assets/getOffer/input.svg";

const Platform = ({ requestData, setRequestData, variant, data }) => {
  const handleChange = (id) => {
    if (!requestData?.[0]?.find((item) => item === id)) {
      let temp = [...requestData];
      if (!temp[0]) temp[0] = [id];
      else temp[0] = [...temp[0], id];
      setRequestData(temp);
    } else {
      let temp = [...requestData];
      let index = temp[0].indexOf(id);
      if (index > -1) {
        temp[0].splice(index, 1);
      }
      setRequestData(temp);
    }
  };

  return (
    <Grid container xl={12} style={{ marginTop: "4rem", margin: "0 auto" }}>
      <Container container style={{ marginBottom: "2rem", marginTop: "4rem" }}>
        <img src={slash} alt="slash" />
        <h1
          style={{
            color: "#194591",
            fontSize: "30px",
            marginLeft: "1rem",
          }}
        >
          {data?.name}
        </h1>
      </Container>
      <span
        style={{
          color: "#194591",
          fontSize: variant === "desktop" ? "20px" : "16px",
          marginTop: "2.5rem",
        }}
      >
        {data?.sub_steps?.[0]?.name}
      </span>
      <Grid
        container
        style={{
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        {data?.sub_steps?.[0]?.data.map((item, index) => (
          <MyBox
            key={index}
            item
            xs={6}
            sm={4}
            md={4}
            lg={3}
            style={{ marginTop: "2rem" }}
          >
            <Box onClick={() => handleChange(item?.id)}>
              <img
                src={
                  requestData?.[0]?.some((e) => e === item.id)
                    ? selectedInput
                    : input
                }
                alt="image"
              />
            </Box>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <Box
                style={{
                  backgroundColor: requestData?.[0]?.some((e) => e === item.id)
                    ? "#EDFAFF"
                    : "white",
                  height: variant === "desktop" ? "150px" : "92px",
                  width: variant === "desktop" ? "150px" : "92px",
                  border: "2px solid #194591",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={item.data_image}
                  alt="image"
                  style={{
                    height: variant === "desktop" ? "85px" : "53px",
                    width: variant === "desktop" ? "48px" : "30px",
                  }}
                />
              </Box>
              <Typography
                sx={{
                  marginTop: "1rem",
                  fontSize: "1rem",
                  color: requestData[0]?.some((id) => id === item?.id)
                    ? "#FF744C"
                    : "#194591",
                  width: variant === "desktop" ? "170px" : "92px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {item.name}
              </Typography>
            </div>
          </MyBox>
        ))}
      </Grid>
    </Grid>
  );
};

export default Platform;
