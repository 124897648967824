import styled from "styled-components";
import { between } from "polished";
import { Box } from "@mui/material";

export const BlogDetailStyles = styled(Box)`
  /* padding-top: 130px;
  padding-right: ${({ paddingHorizontal, theme }) =>
    paddingHorizontal || theme.content.paddingHorizontal};
  padding-left: ${({ paddingHorizontal, theme }) =>
    paddingHorizontal || theme.content.paddingHorizontal};
  padding-bottom: ${({ paddingVertical, theme }) =>
    paddingVertical || theme.content.paddingVertical}; */
  background-color: #fff;
  display: flex;
  justify-content: center;
`;

export const Page = styled(Box)`
  display: flex;
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const BlogContainer = styled(Box)`
  //border-left: 4px solid ${({ theme }) => theme.main.secondaryColor};
  /* min-height: 100vh; */
  border-radius: 5px;
  background-color: #edf0f6;
  padding-right: ${between("32px", "48px")};
  padding-left: ${between("32px", "64px")};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  overflow: hidden;
  margin: 6rem 0 0 0;
  @media (max-width: 576px) {
    height: auto !important;
  }

  padding-bottom: 45px;
  padding-top: 150px;
`;

export const BlogTitle = styled(Box)`
  font-family: "Open Sans";
  font-size: 40px;
  font-weight: bold;
  color: rgb(35, 63, 141);
  text-transform: uppercase;
  max-width: 564px;
  margin-bottom: 50px;
  @media only screen and (max-width: 500px) {
    font-size: 20px;
  }
`;

export const BlogContent = styled.div`
  background-color: #edf0f6;
  text-align: justify;
  max-width: 100%;
  /*  @media only screen and (max-width: 580px) {
    max-width: 100%;
  } */
  /* max-width: 875px !important; */
  /* overflow-x: hidden; */
  img {
    object-fit: contain;
    max-width: 80%;
    height: auto;
    @media only screen and (max-width: 580px) {
      max-width: 100%;
    }
  }
`;

export const ImageBox = styled(Box)`
  border: 6px solid #194591;
  border-radius: 50%;
  height: ${between("169px", "265px")};
  width: ${between("169px", "265px")};
  margin-right: 1.25rem;
  margin-top: 14rem;
  @media only screen and (max-width: 500px) {
    margin-top: 3rem;
  }
`;
/* export const BlogFooter = styled(Row)`
  padding-top: 50px;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 5px solid yellow;
`; */

export const SideBar = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-right: ${between("4px", "48px")};
  padding-left: ${between("4px", "16px")};
  margin-bottom: 3rem;
  @media only screen and (max-width: 1000px) {
    padding-left: 20px;
  }
`;

export const ListItem = styled.li`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: normal;
  color: rgb(74, 74, 74);
  list-style-type: circle;
  list-style-position: inside;
`;
