import { Grid } from "@mui/material";
import styled from "styled-components";

export const Top = styled(Grid)`
  width: 1516px;
  margin: 0 auto;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-family: "Open Sans";
  font-size: 34px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    flex-wrap: nowrap;
    @media screen and (max-width: 1249px) {
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }

  .container-img {
    width: 520px;
    height: auto;
    @media (max-width: 1200px) {
      margin: 0 auto;
    }
    @media (max-width: 580px) {
      width: 400px;
    }
    @media (max-width: 450px) {
      width: 300px;
    }
  }
  .slogan-container {
    margin: 50px 0px;
    @media screen and (max-width: 768px) {
      margin: 10px 0px;
    }
    .slogan {
      padding: 5px 20px;
      background-color: ${({ theme }) => theme.main.secondaryColor};
      border-radius: 8px;
      margin-bottom: 5px;
      border-radius: 8px;
      .text {
        color: #fff;
        font-size: 50px;
        font-family: "Open Sans", sans-serif;
        margin: 0;
        white-space: nowrap;
        @media screen and (max-width: 992px) {
          font-size: 4.2vw;
        }
        .bold {
          font-weight: bold;
        }
      }
    }
    .slogan2 {
      padding: 3px 0;
      margin-bottom: 5px;

      .subtext {
        color: rgb(35, 63, 141);
        font-size: 18px;
        font-family: "Open Sans", sans-serif;
        margin: 16px 0;
        @media screen and (max-width: 992px) {
          font-size: 0.8rem;
        }
      }
    }
  }
`;
