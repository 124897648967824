import { Button, Grid } from "@mui/material";
import styled from "styled-components";
import CustomTypography from "../../../../components/CustomTypography";
export const Container = styled(Grid)``;

export const TeamContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 1600px;
  @media (max-width: 1516px) {
    width: 75rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0;
  }
  .MuiBox-root::-webkit-scrollbar {
    display: none;
  }
  .MuiBox-root {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const HeadBannerButton = styled(Button)`
  font-family: "Open Sans";
  font-size: 12px;
  width: 176px;
  height: 48px;
  font-weight: bold;
  background-color: #ff744c;
  color: #fff;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
`;

export const StyledTypography = styled(CustomTypography)`
  color: #194591;
  font-weight: 600;
  @media (max-width: 1024px) {
    font-size: 2rem;
  }
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 600px) {
    font-size: 1.25rem;
  }
`;
