import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import HeadBanner from "./components/HeadBanner";
import Images from "./components/Images";
import OfficeImages from "./components/OfficeImages";
import WriteUs from "./components/WriteUs";
import { Modal } from "../../components/Modal";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container>
      <HeadBanner />
      <Images />
      <OfficeImages />
      <WriteUs />
      <Modal />
    </Grid>
  );
};

export default About;
