import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { CustomTabs, StyledTab } from "./style";
import descriptionBackground from "../../assets/mainpage/techPartners/descriptionBackground.svg";
import { API_BASE_URL } from "../../constants/api";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomTypography from "../CustomTypography";

function PartnerPanel(props) {
  const { children, variant, value, index, text, ...other } = props;

  return (
    <>
      <div
        hidden={value !== index}
        id={`partner-partnerpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3, height: 215, overflow: "hidden" }}>
            <DescriptionBackground
              index={index}
              text={text}
              variant={variant}
            ></DescriptionBackground>
          </Box>
        )}
      </div>
    </>
  );
}

function DescriptionBackground(props) {
  const { variant, text, index } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundImage: `url(${descriptionBackground})`,
        height: "215px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "100% 100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: variant === "fullWidth" ? "100%" : "150%",
        minWidth: variant === "fullWidth" ? "750px" : "150%",
        maxWidth: variant === "fullWidth" ? "1000px" : "150%",
      }}
    >
      <CustomTypography
        variant="MPTechPartnersDescription"
        sx={{
          fontFamily: "Open Sans",
          padding: "40px 0 0 24px",
        }}
        color="#4A4A4A"
      >
        {text}
      </CustomTypography>
      <CustomTypography
        variant="MPTechPartnersSeeMore"
        style={{
          padding: "7px 0 19px 24px",
          textDecoration: "underline",
          cursor: "pointer",
          marginTop: "-1rem",
        }}
        color="#4A4A4A"
        onClick={() =>
          navigate("/tech_partners", { state: { cardIndex: index } })
        }
      >
        {t("buttons.seeMore")}
      </CustomTypography>
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `partner-partnerpanel-${index}`,
  };
}

const PartnersTabPanel = ({ variant, state }) => {
  const { i18n } = useTranslation();
  const [techPartners, setTechPartners] = useState([]);

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    fetch(
      `${API_BASE_URL}${i18n.language}/technologypartner/mainpartner/${
        i18n.language === "tr" ? `listTr/` : "listEn/"
      }`
    )
      .then((res) => res.json())
      .then((result) => {
        setTechPartners(result);
      });
  }, [i18n.language]);

  return (
    <Box sx={{ width: "100%", margin: "0 auto" }}>
      <CustomTabs
        value={value}
        onChange={handleChange}
        variant={variant}
        scrollButtons="none"
        style={{
          width: "100%",
          borderBottom: "2px solid #194591",
          margin: "0 auto",
        }}
      >
        {techPartners?.map((item, index) => (
          <StyledTab
            key={index}
            icon={
              <img
                style={{
                  width: "100%",
                  objectFit: "contain",
                }}
                src={item.main_image}
                alt="logo"
              />
            }
            {...a11yProps(index)}
          />
        ))}
      </CustomTabs>
      <Grid index={value} onChange={handleChangeIndex}>
        {techPartners?.map((item, index) => (
          <>
            <PartnerPanel
              key={index}
              value={value}
              selected={value === index}
              variant={variant}
              text={item.main_content}
              index={index}
              dir={theme.direction}
            ></PartnerPanel>
          </>
        ))}
      </Grid>
    </Box>
  );
};

export default PartnersTabPanel;
