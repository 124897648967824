import { Grid } from "@mui/material";
import styled from "styled-components";
import CustomTypography from "../../../../components/CustomTypography";
import theme from "../../../../theme";

export const Container = styled(Grid)`
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${theme.main.turquoise};
`;

export const ServicesContainer = styled(Grid)`
  width: 1516px;
  display: flex;
  flex-direction: column;
  padding: 4rem 0 0 0;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    padding: 2rem 2rem;
    width: 100%;
  }
`;

export const ServicesTitle = styled(CustomTypography)`
  color: ${theme.main.blue};
  width: 100%;
  /* font-size: 40px; */
  font-family: Open Sans;
  font-weight: bold;
  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const ServicesText = styled(CustomTypography)`
  width: 100%;
  color: ${theme.main.blue};
  margin: 1rem 0 1rem;
  /*  font-size: 30px; */
  font-family: Open Sans;
  @media (max-width: 1200px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const UpGrid = styled(Grid)`
  margin-top: 4rem;
  width: 1343px;
  height: 200px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1516px) {
    width: 1100px;
  }
`;
export const DownGrid = styled(Grid)`
  margin-top: 8rem;
  width: 90%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    justify-content: flex-end;
    min-width: 320px;
  }
`;

export const ServiceCard = styled(Grid)`
  position: relative;
  background-color: ${theme.main.white};
  height: 12rem;
  border-radius: 5px;
  width: 373px;
  filter: drop-shadow(0.2rem 0.2rem 0.5rem #bfbfbf);
  &:hover {
    background-color: #fff3f0;
  }
  @media (max-width: 1516px) {
    width: 300px;
    height: 180px;
  }
  @media (max-width: 1200px) {
    min-width: 300px;
  }
`;

export const SubTitle = styled(CustomTypography)`
  font-size: 22px;
  color: ${theme.main.blue};
  margin: 4rem 0 0 2rem;
  font-weight: bold;
  @media (max-width: 1516px) {
    font-size: 19px;
    line-height: 20px;
  }
`;

export const SubText = styled(CustomTypography)`
  width: 83%;
  color: ${theme.main.blue};
  margin: 1rem 0rem 1rem 2rem;
  font-size: 14px;
  line-height: 18px;
  @media (max-width: 1516px) {
    font-size: 12px;
  }
`;

export const SubImage = styled.img`
  position: absolute;
  bottom: 75%;
  left: 10%;
`;
