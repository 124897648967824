import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Hyperlink } from "./style";

const CustomLink = (props) => {
  const { pathname } = useLocation();
  const { to, children, activeClassName, className, ...rest } = props;

  return (
    <Hyperlink
      to={to}
      className={`${className} ${
        activeClassName && to === pathname ? activeClassName : ""
      }`}
      {...rest}
    >
      {children}
    </Hyperlink>
  );
};

export default CustomLink;
