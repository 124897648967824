import React, { useEffect, useState } from "react";
import {
  ServicesContainer,
  LeftContainer,
  RightContainer,
  ServiceCard,
  SubImage,
  SubTitle,
  SubText,
  ContentGrid,
  MyBox,
} from "./style";
import { API_BASE_URL } from "../../constants/api";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import slash from "../../assets/services/slash.svg";
import { useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Loading from "../../components/Loading";

const Services = () => {
  const location = useLocation();
  const { cardIndex } = location.state === null ? 0 : location.state;
  const { i18n } = useTranslation();
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetch(
      `${API_BASE_URL}${i18n.language}/project/ourservices/${
        i18n.language === "tr" ? "listTr/" : "listEn/"
      }`
    )
      .then((res) => res.json())
      .then((result) => {
        setServices(result);
        document?.getElementById("service-" + cardIndex)?.scrollIntoView(true);
      });
  }, [i18n.language]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AnimatePresence>
        {!services.length && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              width: "1000vw",
              height: "1000vw",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: "999999999",
            }}
          >
            <Loading />
          </motion.div>
        )}
      </AnimatePresence>
      {services.map((item, index) => (
        <MyBox
          id={`service-${index}`}
          key={index}
          height="auto"
          container
          sx={{
            backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#EDFAFF",
            marginTop: index === 0 && "6rem",
            display: "flex",
          }}
          flexDirection={{ md: "column" }}
        >
          <ContentGrid
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <ServicesContainer>
              <LeftContainer item container>
                <ServiceCard item container>
                  <SubImage src={item.services_image} alt="subLogo" />
                  <SubTitle variant="smallTitle">{item.title}</SubTitle>
                </ServiceCard>
              </LeftContainer>

              <RightContainer
                item
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                  marginTop={{ sm: "3rem", xs: "3rem" }}
                >
                  <img src={slash} alt="slash" />
                  <SubText
                    item
                    paddingLeft={{ xs: "1rem", lg: "2rem" }}
                    index={0}
                    style={{ lineHeight: "32px" }}
                  >
                    {item.content}
                  </SubText>
                </Box>
              </RightContainer>
            </ServicesContainer>
          </ContentGrid>
        </MyBox>
      ))}
    </Grid>
  );
};
export default Services;
