import { Grid } from "@mui/material";
import styled from "styled-components";
import CustomTypography from "../../../../components/CustomTypography";
import theme from "../../../../theme";

export const Container = styled(Grid)``;

export const MdDownJourneyContainer = styled(Grid)`
  width: 1516px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem;
  }
`;
export const MdUpJourneyContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export const ServicesTitle = styled(CustomTypography)`
  color: ${theme.main.blue};
`;
