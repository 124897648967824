/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ServicesTitle, TitlesBar } from "./style";

function index({ roles, active, setActive }) {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <ServicesTitle variant="mediumTitle" paddingTop="3rem">
        {t("jobs.title")}
      </ServicesTitle>
      <TitlesBar
        sx={{
          display: "flex",
          gap: "32px",
          alignItems: "center",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        {roles.map((role, index) => (
          <Box onClick={() => setActive(role.id)} key={index}>
            <Typography
              sx={{
                cursor: "pointer",
                color: active === role.id ? "#FF744C" : "#194591",
                fontSize: "14px",
                fontWeight: "600 !important",
                fontFamily: "Open Sans",
                whiteSpace: "nowrap",
              }}
            >
              {role.name}
            </Typography>
          </Box>
        ))}
      </TitlesBar>
    </>
  );
}

export default index;
