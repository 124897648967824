import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ENLanguage } from "../i18n/en";
import { TRLanguage } from "../i18n/tr";

const options = {
  resources: {
    en: { translation: ENLanguage },
    tr: { translation: TRLanguage },
  },
  fallbackLng: "en",
  interpolation: { escapeValue: false },
};

i18n.use(initReactI18next).init(options);
