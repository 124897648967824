import { Box } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled(Box)`
  padding-bottom: 1rem;
  width: 100%;
  margin: 8px 0 0 0;
  display: flex;

  .slick-slide > div {
    margin: 0 -5px 0 0;
  }
`;

export const Container = styled(Box)`
  width: 100%;
  .slick-dots li {
    width: 1rem;
    margin: 0 3px 0 0;
  }

  .slick-dots li button::before {
    color: #c4c4c4;
    font-size: 1rem;
    opacity: 1;
  }

  .slick-dots li.slick-active button::before {
    color: #194591;
    opacity: 1;
  }
  .slick-dots {
    list-style-type: circle;
    width: fit-content;
    bottom: -41px;
    margin-left: 31px;
  }
`;
