import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Container,
  LeftContainer,
  RightContainer,
  Text,
  StyledContext,
  StyledTitle,
} from "./style";
import AboutBanner from "../../../../assets/about/aboutBanner.png";
import { API_BASE_URL } from "../../../../constants/api";

const HeadBanner = () => {
  const { i18n } = useTranslation();
  const [landing, setLanding] = useState([]);
  useEffect(() => {
    fetch(
      `${API_BASE_URL}${i18n.language}/landing/${
        i18n.language === "tr" ? `listTr/` : "listEn/"
      }`
    )
      .then((res) => res.json())
      .then((result) => {
        setLanding(result);
      });
  }, [i18n.language]);
  return (
    <Container container>
      <LeftContainer item sm={12} md={12} lg={5.1}>
        <Text
          item
          container
          xs={12}
          sm={11}
          justifyContent="flex-start"
          alignItems="center"
          background="#194591"
          padding={1}
        >
          {landing.map((item, index) => (
            <StyledTitle variant="largeBoldText" key={index}>
              {item.title}
            </StyledTitle>
          ))}
        </Text>
        <Text
          item
          container
          xs={12}
          md={12}
          textAlign="middle"
          alignItems="center"
        >
          {landing.map((item, index) => (
            <StyledContext
              variant="smallText"
              color="#194591 !important"
              key={index}
            >
              {item.content}
            </StyledContext>
          ))}
        </Text>
      </LeftContainer>

      <RightContainer
        item
        sm={12}
        md={12}
        lg={6.9}
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={AboutBanner}
          alt="logo"
          style={{ width: "100%", marginTop: "1.5rem" }}
        />
      </RightContainer>
    </Container>
  );
};

export default HeadBanner;
