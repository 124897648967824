import styled from "@emotion/styled";
import theme from "../../theme";
import CustomTypography from "../CustomTypography";
import { Box } from "@mui/material";

export const ServicesTitle = styled(CustomTypography)`
  color: ${theme.main.blue};
  font-family: "Open Sans";
  white-space: nowrap;
`;

export const JoinUsHeaderBox = styled(Box)`
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1.5rem;
  }
`;

export const JobSelectionBox = styled(Box)`
  @media (max-width: 1516px) {
    padding-left: 3rem;
  }
  @media (max-width: 1200px) {
    padding-left: 0rem;
    padding-top: 1.5rem;
  }
`;
