/* eslint-disable react-hooks/rules-of-hooks */
import { Grid, Hidden } from "@mui/material";
import React, { useEffect, useState } from "react";
import { JourneyContainer } from "./style";
import OfficeImages from "../../../../components/OfficeImages";
import OfficeImagesSlider from "../../../../components/OfficeImagesSlider";
import { API_BASE_URL } from "../../../../constants/api";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
function index() {
  const { i18n } = useTranslation();
  const [istanbulOfficeImages, setIstanbulOfficeImages] = useState([]);
  const [londonOfficeImages, setLondonOfficeImages] = useState([]);
  useEffect(() => {
    fetch(
      `${API_BASE_URL}${i18n.language}/landing/photo/?photo_category=london`
    )
      .then((res) => res.json())
      .then((result) => {
        setLondonOfficeImages(result.results);
      });
  }, [i18n.language]);
  useEffect(() => {
    fetch(
      `${API_BASE_URL}${i18n.language}/landing/photo/?photo_category=istanbul`
    )
      .then((res) => res.json())
      .then((result) => {
        setIstanbulOfficeImages(result.results);
      });
  }, [i18n.language]);
  return (
    <JourneyContainer item container>
      <Hidden mdDown>
        <OfficeImages
          city={istanbulOfficeImages}
          title={t("about.officeTitle1")}
          color="#FF744C"
        />
        <OfficeImages
          city={londonOfficeImages}
          title={t("about.officeTitle2")}
          color="#19BDFB"
        />
      </Hidden>

      <Hidden mdUp>
        <OfficeImagesSlider
          images={istanbulOfficeImages}
          color="#FF744C"
          title={t("about.officeTitle1")}
        />
        <OfficeImagesSlider
          images={londonOfficeImages}
          color="#19BDFB"
          title={t("about.officeTitle2")}
        />
      </Hidden>
    </JourneyContainer>
  );
}

export default index;
