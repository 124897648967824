/* eslint-disable no-unused-vars */
import React from "react";
import { useTranslation } from "react-i18next";
import { ArrowRight } from "../../../assets/icons";
import {
  BlogCardStyles,
  ImageContainer,
  CardHeader,
  CardContent,
  CardFooter,
  MoreButton,
} from "./styles";
import { rgb } from "polished";
import CustomTypography from "../../../components/CustomTypography";
import { format } from "date-fns";

const BlogCard = (props) => {
  const {
    title,
    raw_content,
    content,
    createDate,
    language,
    author,
    blog_main_image,
    blog_image,
    onClick,
  } = props;
  const { t, i18n } = useTranslation();
  const truncate = (str) => {
    return str?.length > 140 ? str.substr(0, 120) + "..." : str;
  };
  var date = new Date(createDate);
  return (
    <BlogCardStyles onClick={onClick}>
      <ImageContainer>
        <img src={blog_image} alt="blog-img" />
      </ImageContainer>
      <CardHeader>
        <CustomTypography
          ellipsis={{
            rows: 3,
            expandable: false,
          }}
          style={{
            fontSize: "24px",

            fontWeight: "bold",
            color: rgb(35, 63, 141),
            fontFamily: "Open Sans",
            height: "65px",
            lineHeight: "32px",
          }}
        >
          {title}
        </CustomTypography>
      </CardHeader>
      <CardContent>
        <CustomTypography
          dangerouslySetInnerHTML={{ __html: truncate(content) }}
          ellipsis={{
            rows: 3,
            expandable: false,
          }}
          variant="mediumSubTitle"
          style={{
            lineHeight: "22px",
            fontWeight: "normal",
            fontFamily: "Open Sans",
            color: "#4A4A4A",
            marginTop: "2rem",
            textOverflow: "ellipsis",
          }}
        ></CustomTypography>
      </CardContent>
      <MoreButton type="primary" sx={{ maxWidth: "150px", marginLeft: "1rem" }}>
        {language} <ArrowRight marginLeft />
      </MoreButton>
      <CardFooter>
        <CustomTypography
          style={{
            fontSize: "14px",
            fontWeight: "normal",
            fontFamily: "Open Sans",
            color: rgb(127, 127, 127),
            marginBottom: 20,
          }}
        >
          {t("blog.author")}: {author}
        </CustomTypography>
        <CustomTypography
          style={{
            fontSize: "14px",
            fontWeight: "normal",
            fontFamily: "Open Sans",
            color: rgb(127, 127, 127),
          }}
        >
          {format(date, "dd.MM.yyyy")}
        </CustomTypography>
      </CardFooter>
    </BlogCardStyles>
  );
};

export default BlogCard;
