/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import { Helmet } from "react-helmet";
import { Trans, useTranslation } from "react-i18next";
import Loading from "../../../components/Loading";
import { API_BASE_URL } from "../../../constants/api";
import {
  BlogDetailStyles,
  BlogContent,
  BlogContainer,
  BlogTitle,
  SideBar,
  Page,
  ImageBox,
  ListItem,
} from "./styles";
import { rgb } from "polished";
import CustomTypography from "../../../components/CustomTypography";
import { Typography, Box } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";

const BlogDetail = () => {
  const selectedLanguage = window.sessionStorage.getItem("lang");
  const navigate = useNavigate();
  const { id: blogId } = useParams();
  const [blog, setBlog] = useState("");
  const [blogCategory, setblogCategory] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    scroller.scrollTo("title", { offset: -100 });
  }, [blog]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch(`${API_BASE_URL}${i18n.language}/blog/${blogId}/`)
      .then((res) => res.json())
      .then((result) => {
        setBlog(result);
      });
  }, [selectedLanguage, t]);

  useEffect(() => {
    fetch(`${API_BASE_URL}${i18n.language}/blog/blogcategory/${blogId}/`)
      .then((res) => res.json())
      .then((result) => {
        setblogCategory(result.blog_category);
      });
  }, [selectedLanguage, t]);

  if (blog && blog.detail) {
    navigate("/blog");
  }
  return (
    <BlogDetailStyles>
      <Helmet>
        <title>{t("blog.title")}</title>
        <meta name="description" content={t("blog.details")} />
      </Helmet>
      <AnimatePresence>
        {!blog.author && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              width: "1000vw",
              height: "1000vw",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: "999999999",
            }}
          >
            <Loading />
          </motion.div>
        )}
      </AnimatePresence>
      <Page>
        <BlogContainer>
          <BlogTitle> {blog?.title}</BlogTitle>
          <BlogContent>
            <div
              dangerouslySetInnerHTML={{ __html: blog.main_content }}
              className="content"
            />
          </BlogContent>
        </BlogContainer>
        <SideBar>
          <ImageBox>
            <img
              src={blog?.blog_main_image}
              alt="image"
              style={{
                objectFit: "contain",
                height: "100%",
                width: "100%",
                borderRadius: "50%",
              }}
            />
          </ImageBox>
          <CustomTypography
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: "Open Sans",
              color: rgb(35, 63, 141),
              marginTop: "50px",
              paddingLeft: "15px",
            }}
          >
            {t("blog.category")}:
          </CustomTypography>
          <Box>
            <ul style={{ padding: "0" }}>
              <li
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "red",
                  listStylePosition: "inside",
                  listStyleType: "circle",
                  paddingLeft: "15px",
                }}
              >
                <a
                  href="#"
                  style={{ color: "#4A4A4A", textDecoration: "none" }}
                >
                  {blogCategory}
                </a>
              </li>
            </ul>
          </Box>
          <CustomTypography
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              fontFamily: "Open Sans",
              color: rgb(35, 63, 141),
              marginTop: "50px",
              paddingLeft: "15px",
            }}
          >
            {t("blog.author")}:
          </CustomTypography>
          <CustomTypography
            style={{
              fontSize: "12px",
              fontWeight: "normal",
              fontStyle: "italic",
              fontFamily: "Open Sans",
              color: rgb(127, 127, 127),
              paddingLeft: "15px",
              width: "150px",
              marginTop: "16px",
            }}
          >
            {blog?.author}
          </CustomTypography>
        </SideBar>
      </Page>
    </BlogDetailStyles>
  );
};

export default BlogDetail;
