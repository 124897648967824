import { Box } from "@mui/material";
import styled from "styled-components";

export const ImageBox = styled(Box)`
  &:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  -webkit-filter: grayscale(70%);
  filter: grayscale(70%);
`;
