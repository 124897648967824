import { Grid } from "@mui/material";
import styled from "styled-components";
import CustomTypography from "../../../../components/CustomTypography";
import theme from "../../../../theme";

export const Container = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MdDownContainer = styled(Grid)`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 1516px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem;
  }
`;
export const MdUpContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
`;
export const TechContainer = styled(Grid)`
  display: flex;
  width: 1516px;
  flex-direction: column;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const ServicesTitle = styled(CustomTypography)`
  color: ${theme.main.blue};
  margin: 0.5rem 0;

  @media (max-width: 1200px) {
    max-width: 100%;
    padding: 0 31px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const ServicesText = styled(CustomTypography)`
  color: ${theme.main.blue};
  margin: 1rem 0 4rem 0;
  @media (max-width: 1200px) {
    max-width: 100%;
    padding: 0 31px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;
