import Slider from "react-slick";
import { Container, Wrapper, ServiceCard, SubTitle, SubText } from "./style";
import { useEffect, useRef } from "react";
import SliderArrows from "../SliderArrows";

const CompanyCultureCarousel = ({ companyCultureData }) => {
  const settings = {
    className: "center",
    infinite: false,
    slidesToShow: 2,
    dots: true,
    dotsClass: "slick-dots",
    arrows: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 1.7,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 1.3,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 1,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slider = useRef();

  useEffect(() => {
    slider?.current.slickGoTo(0, true);
  }, [companyCultureData]);
  return (
    <Wrapper>
      <Container>
        <Slider ref={slider} {...settings}>
          {companyCultureData?.map((item, index) => (
            <ServiceCard key={index}>
              <SubTitle> {item.logo_title} </SubTitle>
              <SubText variant="smallText">{item.logo_description}</SubText>
            </ServiceCard>
          ))}
        </Slider>
        <SliderArrows slider={slider} />
      </Container>
    </Wrapper>
  );
};

export default CompanyCultureCarousel;
