import { Grid } from "@mui/material";
import styled from "styled-components";

export const JourneyContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 1516px;
  margin: 7rem auto 0 auto;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    padding: 0 2rem;
    width: 100%;
  }
  @media (max-width: 900px) {
    margin-top: 3rem;
  }
`;
