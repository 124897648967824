import * as React from "react"

export const FailIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={43.093}
    height={39.602}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 162727"
          fill="none"
          stroke="#ff744c"
          strokeWidth={3}
          d="M0 0h43v39.602H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 175823">
      <path
        data-name="Rectangle 162725"
        fill="#fff"
        stroke="#ff744c"
        strokeWidth={3}
        d="M41.593 24.967h-7.695V1.881h7.695z"
      />
      <g data-name="Group 175822">
        <g
          data-name="Group 175821"
          clipPath="url(#a)"
          stroke="#ff744c"
          strokeWidth={3}
          transform="rotate(180 21.5 19.801)"
        >
          <path
            data-name="Rectangle 162726"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M1.409 14.605h7.705v23.114H1.409z"
          />
          <path
            data-name="Path 144185"
            d="M9.113 17.878s6.748-4.3 9.539-7.173c1.916-1.972 5.782-6.176 8.02-8.62a2.088 2.088 0 0 1 3.219.173l.482.649a4.557 4.557 0 0 1 .442 4.7l-3.705 7.667h10.328a4.152 4.152 0 0 1 3.977 5.334l-3.424 11.456a8.587 8.587 0 0 1-8.227 6.128H18.305a6.725 6.725 0 0 1-3.708-1.114l-5.484-3.622Z"
            fill="#fff"
          />
          <path
            data-name="Path 144186"
            d="M9.113 17.878s6.748-4.3 9.539-7.173c1.916-1.972 5.782-6.176 8.02-8.62a2.088 2.088 0 0 1 3.219.173l.482.649a4.557 4.557 0 0 1 .442 4.7l-3.705 7.667h10.328a4.152 4.152 0 0 1 3.977 5.334l-3.424 11.456a8.587 8.587 0 0 1-8.227 6.128H18.305a6.725 6.725 0 0 1-3.708-1.114l-5.484-3.622Z"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </g>
  </svg>
)
