import { Grid } from "@mui/material";
import styled from "styled-components";

export const TopContainer = styled(Grid)`
  display: flex;
  margin: 2rem 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #194591;
  color: white;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Image = styled.img`
  margin: 2rem 0;

  @media (max-width: 600px) {
    width: 80%;
    margin: 2rem 1rem;
  }
  @media (max-width: 768px) {
    margin: 2rem 1rem;
  }
`;
