import styled from 'styled-components';

const cardShadow = '0 3px 6px 0 rgba(0, 0, 0, 0.16);';
const hoverShadow = '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)';

export default styled.div`
    margin: ${({ margin, theme }) => margin && theme.card.margin};
    padding: ${({ padding, theme }) => padding && theme.card.padding};

    @media only screen and (max-width: 400px) {
        margin: ${({ margin }) => margin && '5px'};
        padding: 10px;
    }

    border-radius: ${({ theme }) => theme.card.borderRadius};

    border-bottom: ${({ color, theme }) => {
        if (color) {
            switch (color) {
                case 'primary':
                    return `3px solid ${theme.main.primaryColor}`;
                case 'secondary':
                    return `3px solid ${theme.main.secondaryColor}`;
                default:
                    return `3px solid ${color}`;
            }
        }
    }};

    background: ${({ background, theme }) => background || theme.card.background};
    background: linear-gradient(to right, ${({ linear }) => linear.join(',')});

    color: ${({ background, linear }) => (background || linear.length > 0) && 'white'};

    box-shadow: ${cardShadow};

    overflow-x: ${({ overflowX }) => overflowX || 'auto'};
    overflow-y: ${({ overflowY }) => overflowY || 'auto'};

    transition: box-shadow 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
    &:hover {
        cursor: ${({ onClick }) => onClick && 'pointer'};
        box-shadow: ${({ hover }) => hover && hoverShadow};
    }
`;
