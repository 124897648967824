/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  WriteUsContainer,
  InputsContainer,
  HeadBannerButton,
  CustomTextField,
  CustomTextArea,
  CoverBox,
  WriteUsTitleDesc,
} from "./style";
import { ArrowRight } from "../../../../assets/icons";
import WriteUsImage from "../../../../assets/about/writeUsImage.png";
import axios from "axios";
import { API_BASE_URL } from "../../../../constants/api";
import React from "react";
import { notifySuccess, notifyFailure } from "../../../../components/Modal";

function index() {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const { i18n } = useTranslation();

  const sendForm = async () => {
    try {
      await axios({
        url: API_BASE_URL + i18n.language + `/landing/createAboutLetter/`,
        method: "POST",
        data: {
          email,
          name,
          surname,
          phone,
          projectDetail: message,
        },
      });
      setName("");
      setSurname("");
      setEmail("");
      setPhone("");
      setMessage("");
      notifySuccess();
    } catch (error) {
      if (error?.response?.data?.error)
        notifyFailure(error?.response?.data?.error);
      else if (error?.response?.data?.email)
        notifyFailure(error?.response?.data?.email[0]);
      else if (error?.response?.data?.data)
        notifyFailure(error?.response?.data?.data[0]);
      console.log("ERROR: ", error?.response?.data);
    }
  };

  return (
    <WriteUsContainer
      flexDirection={{ xs: "column", sm: "column", md: "row" }}
      backgroundColor="#EDFAFF"
    >
      <CoverBox>
        <InputsContainer
          margin={{ xs: "2rem 0", sm: "2rem 0rem", md: "4rem 0rem" }}
          xs={12}
          lg={12}
        >
          <Grid>
            <Typography
              sx={{
                color: "#194591",
                fontSize: "2.5rem",
                fontWeight: "600",
                fontFamily: "Open Sans",
              }}
            >
              {t("aboutus.writeUs")}
            </Typography>
            <WriteUsTitleDesc>{t("aboutus.writeUsMessage")}</WriteUsTitleDesc>
          </Grid>
          <Grid item container lg={12} md={12} sx={{ marginTop: "2rem" }}>
            <Grid xs={12} md={12}>
              <Grid sx={{ display: "flex" }}>
                <Grid paddingRight={4} paddingBottom={3} xs={12} md={6}>
                  <CustomTextField
                    sx={{ width: "100%" }}
                    label={t("about.Name")}
                    variant="filled"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  ></CustomTextField>
                </Grid>
                <Grid paddingRight={1} paddingBottom={3} xs={12} md={6}>
                  <CustomTextField
                    sx={{ width: "100%" }}
                    label={t("about.Surname")}
                    variant="filled"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                  ></CustomTextField>
                </Grid>
              </Grid>
              <Grid paddingRight={1} paddingBottom={3}>
                <CustomTextField
                  sx={{ width: "100%" }}
                  label={t("about.Eposta")}
                  variant="filled"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></CustomTextField>
              </Grid>
              <Grid paddingRight={1} paddingBottom={3}>
                <CustomTextField
                  sx={{ width: "100%" }}
                  label={t("about.PhoneNumber")}
                  variant="filled"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                ></CustomTextField>
              </Grid>
              <Grid paddingRight={1} paddingBottom={3}>
                <CustomTextArea
                  label={t("about.Write")}
                  sx={{ width: "100%" }}
                  variant="filled"
                  multiline
                  rows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></CustomTextArea>
              </Grid>
              <HeadBannerButton onClick={() => sendForm()}>
                {t("about.button")} <ArrowRight marginLeft />
              </HeadBannerButton>
            </Grid>
          </Grid>
        </InputsContainer>
        <Grid
          xs={12}
          lg={12}
          margin={{
            xs: "2rem auto",
            sm: "2rem auto",
            md: "4rem auto",
            lg: "2rem 0.5rem",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <img
            src={WriteUsImage}
            alt="logo"
            style={{ width: "100%", objectFit: "contain" }}
          />
        </Grid>
      </CoverBox>
    </WriteUsContainer>
  );
}

export default index;
