import { Grid } from "@mui/material";
import styled from "styled-components";
import CustomTypography from "../../../../components/CustomTypography";
import theme from "../../../../theme";

export const Container = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const TechContainer = styled(Grid)`
  width: 1516px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    padding: 2rem 2rem;
    width: 100%;
  }
`;

export const ServicesTitle = styled(CustomTypography)`
  color: ${theme.main.blue};
  margin: 0.5rem 0;
`;

export const ServicesText = styled(CustomTypography)`
  color: ${theme.main.blue};
  margin: 1rem 0 4rem 0;
`;
