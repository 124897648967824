import styled from "styled-components";
import { Grid, Box } from "@mui/material";
import CustomTypography from "../../components/CustomTypography";
import theme from "../../theme";

export const Wrapper = styled(Box)`
  padding-bottom: 1rem;
  height: auto;
  margin: 0rem 0 0 0;
  width: 1516px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    /*    padding: 0 2rem 1rem 2rem !important; */
  }
  .slick-slide > div {
    margin: 0 0 0 0;
  }
`;

export const Container = styled(Grid)`
  width: 100%;
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 2rem 1rem 2rem !important;
  }
  .slick-dots li {
    width: 1.5rem;
  }

  .slick-dots li button::before {
    color: #c4c4c4;
    font-size: 1rem;
    opacity: 1;
  }
  .slick-dots li.slick-active button::before {
    color: #194591;
    opacity: 1;
  }
  .slick-dots li {
    margin: 0;
    padding: 0;
  }
  .slick-dots {
    list-style-type: circle;
    width: fit-content;
  }
`;

export const GridContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: "flex-start";
  margin: 2rem auto;
  display: flex !important;
  max-width: 100%;
  &:hover {
    cursor: pointer;
  }
`;

export const LeftContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const InnerContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid #194591 !important;
  width: 100%;
  z-index: 9999;
`;

export const LogoBox = styled(Box)`
  border: 2px solid #194591;
  border-radius: 10px 10px 0 0;
  border-bottom: 0;
  width: 225px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background-color: white;
  @media (max-width: 900px) {
    width: 150px;
    height: 80px;
  }
  @media (max-width: 900px) {
    width: 100px;
    height: 60px;
  }
`;

export const TextContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 1200px) {
    align-items: center;
    justify-content: center;
  }
`;

export const ImgContainer = styled(Grid)`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  @media (max-width: 1200px) {
    margin-top: 1rem;
  }
  @media (max-width: 600px) {
    margin-top: 1rem;
  }
`;

export const Text = styled(Grid)`
  display: flex;
  justify-content: center;
  width: max-content;
  flex-direction: column;
`;

export const StyledTypography = styled(CustomTypography)`
  color: ${theme.main.blue};
`;
