import styled from "@emotion/styled";
import theme from "../../theme";
import CustomTypography from "../CustomTypography";

export const ServicesTitle = styled(CustomTypography)`
  color: ${theme.main.blue};
  margin: 0.5rem 0;
  font-family: "Open Sans";
  width: 50%;
`;
