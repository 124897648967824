/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Grid, Hidden } from "@mui/material";
import React, { useEffect, useState } from "react";
import { JourneyContainer, ImageBox } from "./style";
import { API_BASE_URL } from "../../../../constants/api";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";
import Loading from "../../../../components/Loading";

function index() {
  const { i18n, t } = useTranslation();
  const [images, setImages] = useState([]);
  useEffect(() => {
    fetch(
      `${API_BASE_URL}${i18n.language}/landing/photo/?photo_category=westerops`
    )
      .then((res) => res.json())
      .then((result) => {
        setImages(result.results);
      });
  }, [i18n.language]);
  return (
    <>
      <AnimatePresence>
        {!images.length && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              backgroundColor: "rgba(0,0,0,0.7)",
              width: "1000vw",
              height: "1000vw",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: "999999999",
            }}
          >
            <Loading />
          </motion.div>
        )}
      </AnimatePresence>
      <JourneyContainer>
        <Hidden smDown>
          <Grid container spacing={{ sm: 2, md: 3 }}>
            {images.map((item, index) => (
              <Grid item sm={6} md={4} key={index}>
                <ImageBox>
                  <img
                    src={item.album_image}
                    alt="logo"
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                </ImageBox>
              </Grid>
            ))}
          </Grid>
        </Hidden>

        <Hidden smUp>
          <Grid container spacing={{ xs: 2 }}>
            {images.map((item, index) => (
              <Grid item xs={12} key={index}>
                <ImageBox>
                  <img
                    src={item.album_image}
                    alt="logo"
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                </ImageBox>
              </Grid>
            ))}
          </Grid>
        </Hidden>
      </JourneyContainer>
    </>
  );
}

export default index;
