import styled from "styled-components";
import { Button } from "@mui/material";

export default styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: none;
  margin-right: 2rem;
  height: 40px;
  border-radius: ${({ theme }) => theme.main.borderRadius};
  font-weight: bold;
  font-family: "Open Sans", sans-serif !important;
  box-shadow: ${({ theme, type }) => {
    switch (type) {
      case "link-bordered":
        return "none";
      case "link":
        return "theme.main.shadow";
      default:
        break;
    }
  }};

  font-size: ${({ type }) => {
    switch (type) {
      case "link-bordered":
        return "16px !important";
      default:
        break;
    }
  }};

  padding: ${({ type }) => {
    switch (type) {
      case "default-secondary":
        return "1rem";
      case "cyan":
        return "1rem";
      default:
        break;
    }
  }};

  border-radius: ${({ type, theme }) => {
    switch (type) {
      case "link-bordered":
        return "0px";
      default:
        break;
    }
  }};

  background: ${({ type, theme }) => {
    switch (type) {
      case "primary":
        return theme.main.primaryColor;
      case "secondary":
        return theme.main.secondaryColor;
      case "default-secondary":
        return theme.main.white;
      case "cyan":
        return theme.main.cyan;
      case "link":
        return "transparent";
      case "link-bordered":
        return "transparent";
      default:
        break;
    }
  }};
  color: ${({ type, theme }) => {
    switch (type) {
      case "secondary":
      case "cyan":
        return "#fff";
      case "default-secondary":
        return theme.main.secondaryColor;
      case "link":
        return theme.main.extraSmokeColor;
      case "link-bordered":
        return theme.main.extraSmokeColor;
      default:
        break;
    }
  }};
  border-color: ${({ type, theme }) => {
    switch (type) {
      case "secondary":
      case "default-secondary":
        return theme.main.secondaryColor;
      case "cyan":
        return theme.main.cyan;
      case "link-bordered":
        return "transparent";
      default:
        break;
    }
  }};

  background: ${({ type, theme }) => {
    switch (type) {
      case "primary":
        return theme.main.primaryFocus;
      case "secondary":
      case "default-secondary":
        return theme.main.secondaryFocus;
      case "cyan":
        return theme.main.cyan;
      case "link":
        return "transparent";
      case "link-bordered":
        return "transparent";
      default:
        break;
    }
  }};
  color: ${({ type, theme }) => {
    switch (type) {
      case "primary":
      case "secondary":
      case "cyan":
      case "default-secondary":
        return "#fff";
      case "link":
        return theme.main.primaryColor;
      case "link-bordered":
        return theme.main.secondaryColor;
      default:
        break;
    }
  }};
  border-color: ${({ type, theme }) => {
    switch (type) {
      case "primary":
        return theme.main.primaryFocus;
      case "secondary":
        return theme.main.secondaryColor;
      case "default-secondary":
        return theme.main.secondaryColor;
      case "cyan":
        return theme.main.cyan;
      case "link":
        return "transparent";
      case "link-bordered":
        return "transparent";
      default:
        break;
    }
  }};

  border-bottom: ${({ type, theme }) => {
    switch (type) {
      case "link-bordered":
        return `2px solid${theme.main.secondaryColor}`;
      default:
        break;
    }
  }};

  &:hover {
    background-color: #ea9079;
  }
`;
