import { Grid, Box } from "@mui/material";
import styled from "styled-components";

export const Container = styled(Grid)``;

export const JourneyContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 1516px;
  margin: 0 auto;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    padding: 0 2rem;
    width: 100%;
  }
`;

export const ImageBox = styled(Box)`
  &:hover {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  -webkit-filter: grayscale(70%);
  filter: grayscale(70%);
`;
