/* eslint-disable no-unused-vars */
import React from "react";
import { useTranslation } from "react-i18next";
import ContentContainer from "../../../../components/ContentContainer";
import { Top, StyledTypography } from "./style";
import BlogPagePic from "../../../../assets/blogPageHeadBanner.png";
import { Box, Grid, Typography } from "@mui/material";

const HeadBanner = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Top>
        <Box
          className="container"
          container
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
          marginTop={{ md: "1rem", xs: "2rem" }}
          flexDirection={{
            xl: "row",
            lg: "row",
            xs: "column",
          }}
        >
          <Grid className="slogan-container" item xs={12} lg={5.9} xl={6}>
            <div>
              <div className="slogan">
                <p className="text" style={{ width: "fit-content" }}>
                  <span className="bold" style={{ width: "fit-content" }}>
                    {" "}
                    {t("blog.messages.part1")}{" "}
                  </span>
                  {t("blog.messages.part2")}
                  <span>{t("blog.messages.part3")}</span>
                </p>
              </div>
              <div className="slogan" style={{ width: "fit-content" }}>
                <p className="text" style={{ width: "fit-content" }}>
                  {t("blog.messages.part4")}
                </p>
              </div>
            </div>
          </Grid>
          <Grid marginTop="2rem" item xs={12} lg={6}>
            <img
              width="100%"
              className="container-img"
              src={BlogPagePic}
              alt="WesteropsImage"
            />
          </Grid>
        </Box>
      </Top>
    </>
  );
};

export default HeadBanner;
