import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { CustomTabs, StyledTab, TechnologiesGrid } from "./style";
import { API_BASE_URL } from "../../constants/api";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Grid style={{ display: "flex" }}>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const TabPanels = ({ variant }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const { i18n } = useTranslation();
  const [tools, setTools] = useState([]);
  useEffect(() => {
    fetch(`${API_BASE_URL}${i18n.language}/tool/tecCategory/listAllInfo/`)
      .then((res) => res.json())
      .then((result) => {
        setTools(result);
      });
  }, [i18n.language]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <CustomTabs
        value={value}
        onChange={handleChange}
        variant={variant}
        scrollButtons="none"
        aria-label="scrollable auto tabs example"
        style={{
          width: "100%",
          borderBottom: "2px solid #194591",
          margin: "0 auto",
        }}
      >
        {tools?.map((item, index) => (
          <StyledTab label={item.name} {...a11yProps(index)} key={index} />
        ))}
      </CustomTabs>

      <TechnologiesGrid index={value} onChange={handleChangeIndex}>
        {tools?.map((tool, index) => (
          <TabPanel
            value={value}
            index={index}
            dir={theme.direction}
            key={index}
          >
            <Grid
              display="flex"
              justifyContent={{
                xs: "flex-start",
                md: "flex-start",
                lg: "center",
              }}
              flexWrap={{ xs: "nowrap", md: "nowrap", lg: "wrap" }}
              overflow={{ xs: "scroll", md: "none" }}
              width="100%"
            >
              {tool.tec_category.map((item, index) => (
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                  key={index}
                  margin={{ xs: "1rem", md: "1.5rem" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <img src={item.tec_image} width={"80px"} alt="logo" />
                  </Box>
                  <Typography
                    sx={{
                      marginTop: variant === "fullWidth" ? "16px" : "12px",
                      fontFamily: "Open Sans",
                      fontSize: variant === "fullWidth" ? "14px" : "12px",
                      color: "#4A4A4A",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.name}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </TabPanel>
        ))}
      </TechnologiesGrid>
    </Box>
  );
};

export default TabPanels;
