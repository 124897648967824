import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Slash from "../../assets/Slash";
import { ImageBox } from "./style";

function index({ city, title, color }) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <Slash color={color} />
        <Typography
          sx={{
            color: { color },
            fontSize: "30px",
            fontFamily: "Open Sans",
            fontWeight: "600",
            marginLeft: "1.5rem",
          }}
        >
          {title}
        </Typography>
      </Box>

      <Grid sx={{ marginBottom: "7rem" }} container spacing={{ md: 3 }}>
        {city.map((item, index) => (
          <Grid item md={4} key={index}>
            <ImageBox>
              <img
                src={item.album_image}
                alt="logo"
                style={{ width: "100%", borderRadius: "8px" }}
              />
            </ImageBox>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default index;
