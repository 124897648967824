import styled from "styled-components";
import { Grid } from "@mui/material";

export const Container = styled(Grid)`
  margin: 4rem auto 0 auto;
  border-bottom: 2px solid #194591;
`;

export const MyContainer = styled(Grid)``;

export const MyBox = styled(Grid)`
  /*  border: 1px solid red; */
  height: auto;
  width: 150px;
  display: flex;
  align-items: center;
  align-items: flex-start;
`;
