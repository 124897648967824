import styled from "styled-components";
import { between } from "polished";
import { Box, Grid, Typography } from "@mui/material";

export const DetailsStyles = styled(Box)`
  padding-top: 80px;

  .hr-link {
    color: #60b7e9;
    cursor: pointer;
  }
`;
export const CareerDetailContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
`;
export const StyledCard = styled(Box)`
  padding: 50px 0px;
  width: 100%;
  height: 800px;
`;

export const DetailsHeader = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const Responsibilities = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: ${between("5px", "15px")};
  padding-left: ${between("5px", "30px")};
  padding-right: ${between("5px", "15px")};
`;

export const Qualifications = styled(Box)`
  display: flex;
  flex-direction: column;
  padding-right: ${between("5px", "30px")};
  padding-left: ${between("5px", "30px")};
  margin-top: ${between("5px", "15px")};
`;

export const DetailsFooter = styled(Box)`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans";
  font-size: 12px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Left = styled(Box)`
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: normal;
  margin-right: 50px;
`;

export const Right = styled(Box)`
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: normal;
`;
export const CustomTypography = styled(Typography)`
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;
