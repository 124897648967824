import styled from "styled-components";
import { Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import { Tabs } from "@mui/material";

export const Container = styled(Grid)`
  padding-top: 6rem;
  width: 1516px;
  @media (max-width: 1516px) {
    width: 71rem;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0 1.5rem;
  }
  .MuiGrid-root::-webkit-scrollbar {
    display: none;
  }
  .MuiGrid-root {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  /* width: 88%; */
  margin: 0rem auto 0 auto;
  .css-19kzrtu {
    padding: 0;
  }
`;

export const MyContainer = styled(Grid)``;

export const MyBox = styled(Grid)`
  margin-bottom: 2.5rem;
  height: 200px;
  width: 290px;
  display: flex;
  align-items: center;
  align-items: flex-start;
`;

export const StyledTab = styled(Tab)`
  font-family: "Open Sans", sans-serif;
  text-transform: none;
  border: 2px solid #194591;
  border-bottom: 0px;
  color: ${({ selected }) => (selected ? "#FFFFFF" : "#194591")} !important;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
  background-color: ${({ selected }) => (selected ? "#194591" : "#FFFFFF")};
  height: ${({ selected }) => (selected ? "4rem" : "3rem")};
  width: 100%;
  padding: 1rem 1.5rem;
  @media (max-width: 1024px) {
    padding: 1rem 1.5rem;
  }
  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
  }
  @media (max-width: 600px) {
    padding: 1.5rem 0.5rem;
  }
`;

export const CustomTabs = styled(Tabs)`
  .MuiTabs-flexContainer {
    align-items: flex-end;
    gap: 2rem;
    margin-top: 8.25rem;
    @media (max-width: 768px) {
      gap: 1.5rem;
    }
    @media (max-width: 600px) {
      gap: 1rem;
    }
  }
  .MuiTabs-indicator {
    height: 0px;
  }
`;
