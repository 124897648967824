import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../constants/routes";
import { useLocalStorage } from ".";

const useHeaderLogic = () => {
  const { storedValue: localLang, setValue } = useLocalStorage("lang");
  const { t, i18n } = useTranslation();

  const handleChange = (event) => {
    i18n.changeLanguage(event);
    setValue(event);
  };

  const pages = [
    {
      name: t("buttons.services"),
      path: ROUTES.SERVICES,
    },
    {
      name: t("buttons.portfolio"),
      path: ROUTES.PORTFOLIO,
    },
    {
      name: t("buttons.about"),
      path: ROUTES.ABOUT,
    },
    {
      name: t("buttons.tech_partners"),
      path: ROUTES.PARTNER_COMPONIES,
    },
    {
      name: t("buttons.blog"),
      path: ROUTES.BLOG,
    },
    {
      name: t("buttons.career"),
      path: ROUTES.CAREER,
    },
  ];

  useEffect(() => {
    if (localLang) {
      i18n.changeLanguage(localLang);
    }
  }, [localLang, i18n]);

  return {
    language: i18n.language,
    handleChange,
    pages,
    t,
  };
};

export default useHeaderLogic;
