import { Grid, Hidden } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container, ServicesTitle, TechContainer } from "./style";
import PartnersTabPanels from "../../../../components/PartnersTabPanel";

const TechPartners = () => {
  const { t } = useTranslation();

  return (
    <Container container>
      <TechContainer
        margin={{ xs: "2rem auto", md: "4rem auto" }}
        item
        container
      >
        <ServicesTitle variant="mediumTitle" style={{ paddingBottom: "2rem" }}>
          {t("techPartners.title")}
        </ServicesTitle>

        <Hidden lgDown>
          <Grid container justifyContent="flex-start">
            <PartnersTabPanels variant="fullWidth" />
          </Grid>
        </Hidden>

        <Hidden lgUp>
          <Grid container justifyContent="flex-start">
            <PartnersTabPanels variant="scrollable" />
          </Grid>
        </Hidden>
      </TechContainer>
    </Container>
  );
};

export default TechPartners;
