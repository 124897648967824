/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Loading from "../../../components/Loading";
import OrangeImg from "../../../assets/career/OrangeImg.svg";
import GreenImg from "../../../assets/career/GreenImg.svg";
import {
  DetailsHeader,
  DetailsFooter,
  Responsibilities,
  Qualifications,
  StyledCard,
  Left,
  Right,
  CareerDetailContainer,
} from "./styles";
import { rgb } from "polished";
import { API_BASE_URL } from "../../../constants/api";
import CustomTypography from "../../../components/CustomTypography";
import axios from "axios";
import { Box } from "@mui/material";

const CareerDetails = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [job, setJob] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios({
          url:
            API_BASE_URL +
            i18n.language +
            `/staff/job/list` +
            (i18n.language === "tr" ? `Tr/` : "En/"),
          method: "GET",
        });
        setJob(response.data.filter((item) => item.id === Number(jobId))[0]);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    };
    getData();
  }, [i18n.language]);

  if (job && job.detail) {
    navigate("/jobs");
  }
  if (!job) {
    return <Loading />;
  }

  return (
    <>
      <CareerDetailContainer
        padding={{
          xs: "4.5rem 2rem 5.5rem",
          md: "4.5rem 2rem 5.5rem",
          lg: "4.5rem 3rem 5.5rem",
          xl: "4.5rem 12rem 5.5rem",
        }}
        item
        container
      >
        <Helmet>
          <title>{t("jobs.subTitle")}</title>
          <meta name="description" content={t("jobs.description")} />
        </Helmet>
        <StyledCard>
          <DetailsHeader justify="start">
            <CustomTypography
              variant="careerDetailXLarge"
              style={{
                fontFamily: "Open Sans",
                fontWeight: "bold",
                color: rgb(35, 63, 141),
              }}
            >
              {job?.position}
            </CustomTypography>
            <CustomTypography
              variant="careerDetailLarge"
              style={{
                fontFamily: "Open Sans",
                fontWeight: "normal",
                color: rgb(33, 167, 249),
                marginTop: 12,
              }}
            >
              {job?.work_style}
            </CustomTypography>
            <CustomTypography
              variant="careerDetailMedium"
              style={{
                fontFamily: "Open Sans",
                fontWeight: "normal",
                color: rgb(127, 127, 127),
                marginTop: 24,
              }}
            >
              {job?.location}
            </CustomTypography>
            <DetailsFooter>
              <Left>
                <CustomTypography
                  variant="careerDetailMedium"
                  style={{ marginTop: 16 }}
                  color="#4A4A4A"
                >
                  {t("jobs.sendCV")}
                </CustomTypography>
                <div>
                  <CustomTypography
                    variant="careerDetailMedium"
                    fontWeight="semi-bold"
                    className="hr-link"
                    color="#21A7F9"
                    onClick={() =>
                      (window.location.href = `mailto: hr@westerops.com?subject = ${job?.referans_code}`)
                    }
                  >
                    hr@westerops.com
                  </CustomTypography>
                  <CustomTypography
                    variant="careerDetailMedium"
                    color="#4A4A4A"
                  >
                    &nbsp;with
                  </CustomTypography>
                  <CustomTypography
                    variant="careerDetailMedium"
                    color="#4A4A4A"
                  >
                    &nbsp;ref {job?.referans_code?.split("ref.")[1]}
                  </CustomTypography>
                </div>
              </Left>

              <Right>
                <Responsibilities justify="center">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "7px",
                    }}
                  >
                    <img
                      src={OrangeImg}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    <CustomTypography
                      variant="careerDetailSubTitle"
                      sx={{
                        fontFamily: "Open Sans",
                        fontWeight: "bolder",
                        color: rgb(255, 116, 76),
                      }}
                    >
                      {t("jobs.youWillDo")}
                    </CustomTypography>
                  </Box>
                  <CustomTypography
                    variant="careerDetailDesc"
                    color="#4A4A4A"
                    style={{ paddingLeft: "30px" }}
                  >
                    <Trans>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: job.job_description,
                        }}
                      />
                    </Trans>
                  </CustomTypography>
                </Responsibilities>
                <Qualifications justify="center">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "7px",
                    }}
                  >
                    <img
                      src={GreenImg}
                      alt=""
                      style={{ marginRight: "10px" }}
                    />
                    <CustomTypography
                      variant="careerDetailSubTitle"
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "bolder",
                        color: rgb(1, 210, 214),
                      }}
                    >
                      {t("jobs.qualifications")}
                    </CustomTypography>
                  </Box>
                  <CustomTypography
                    variant="careerDetailDesc"
                    color="#4A4A4A"
                    style={{ paddingLeft: "30px" }}
                  >
                    <Trans>
                      <div
                        dangerouslySetInnerHTML={{ __html: job.requirements }}
                      />
                    </Trans>
                  </CustomTypography>
                </Qualifications>
              </Right>
            </DetailsFooter>
          </DetailsHeader>
        </StyledCard>
      </CareerDetailContainer>
    </>
  );
};

export default CareerDetails;
