import { Box, Grid } from "@mui/material";
import styled from "styled-components";
import theme from "../../theme";
import CustomTypography from "../CustomTypography";

export const Wrapper = styled(Box)`
  padding-bottom: 1rem;
  width: 100%;
  margin: 3rem 0 0 -1rem;
  padding: 0;
  display: flex;

  .slick-slide > div {
    margin: 0 2rem 0 0;
  }
  .slick-dots li button::before {
    color: #c4c4c4;
    opacity: 1;
    font-size: 1rem;
  }

  .slick-dots li.slick-active button::before {
    color: #194591;
    opacity: 1;
  }
  .slick-dots li {
    width: 1.5rem;
    margin: 0;
    padding: 0;
  }
  .slick-dots {
    width: 150px;
    list-style-type: circle;
  }
`;

export const Container = styled(Box)`
  width: 100%;
`;

export const ServiceCard = styled(Grid)`
  max-width: 300px !important;
  height: 180px !important;
  cursor: pointer;
  position: relative;
  margin: 5rem 2rem 2rem 1rem;
  background-color: ${theme.main.white};
  border-radius: 5px;
  filter: drop-shadow(0.5rem 0.5rem 0.5rem #bfbfbf);
  &:hover {
    /* cursor: pointer; */
    background-color: #fff3f0;
  }
`;

export const SubTitle = styled(CustomTypography)`
  color: ${theme.main.blue};
  margin: 4.5rem 1rem 1rem;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 20px;
`;

export const SubText = styled(CustomTypography)`
  color: ${theme.main.blue};
  margin: 0 1rem 1rem;
  font-size: 12px;
  line-height: 20px;
`;

export const SubImage = styled.img`
  position: absolute;
  bottom: 72%;
  left: 10%;
`;
