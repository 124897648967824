import React from "react";
import { Grid } from "@mui/material";
import { ArrowRight } from "../../../../assets/icons";
import { useTranslation } from "react-i18next";
import {
  HeadBannerButton,
  Container,
  ButtonContainer,
  LeftContainer,
  RightContainer,
  StyledTypography,
  Text,
} from "./style";
import HeadBannerLogo from "../../../../assets/mainpage/headbanner.png";
import { useNavigate } from "react-router-dom";

const HeadBanner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Grid container>
        <Container>
          <LeftContainer>
            <Text justifyContent="flex-start" alignItems="center">
              <StyledTypography variant="largeText">
                {t("slogan1.part1")}{" "}
                <StyledTypography variant="largeBoldText">
                  {t("slogan1.part2")}
                </StyledTypography>{" "}
                {t("slogan1.part3")}{" "}
                <StyledTypography variant="largeBoldText">
                  {t("slogan1.part4")}
                </StyledTypography>{" "}
              </StyledTypography>
            </Text>
            <Text justifyContent="flex-start" alignItems="center">
              <StyledTypography variant="largeText">
                {t("slogan1.part5")}{" "}
                <StyledTypography variant="largeBoldText">
                  {t("slogan1.part6")}
                </StyledTypography>{" "}
              </StyledTypography>
            </Text>
            <ButtonContainer item container>
              <HeadBannerButton
                onClick={() => {
                  navigate(`/getoffer`);
                }}
              >
                {t("slogan1.buttonText")} <ArrowRight marginLeft />
              </HeadBannerButton>
            </ButtonContainer>
          </LeftContainer>

          <RightContainer item justifyContent="center" alignItems="center">
            <img src={HeadBannerLogo} alt="logo" style={{ width: "100%" }} />
          </RightContainer>
        </Container>
      </Grid>
    </>
  );
};

export default HeadBanner;
