import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { ENLanguage } from "./en";
import { TRLanguage } from "./tr";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available
    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    resources: {
      en: {
        common: ENLanguage,
      },
      tr: {
        common: TRLanguage,
      },
    },
  });

export default i18n;
