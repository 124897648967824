import styled from "@emotion/styled";
import { Box } from "@mui/material";
import theme from "../../theme";
import CustomTypography from "../CustomTypography";

export const ServicesTitle = styled(CustomTypography)`
  color: ${theme.main.blue};
  font-family: "Open Sans";
  white-space: nowrap;
`;
export const TitlesBar = styled(Box)`
  display: flex;
  gap: 32px;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 1.5rem 0rem;
  &::-webkit-scrollbar {
    display: none;
  }
`;
