/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import slash from "../../../../assets/getOffer/slash.svg";
import { Container } from "../../style";
import selectedInput from "../../../../assets/getOffer/selectedInput.svg";
import input from "../../../../assets/getOffer/input.svg";
import axios from "axios";
import { API_BASE_URL } from "../../../../constants/api";
import { useTranslation } from "react-i18next";

const Technologies = ({ requestData, setRequestData, variant, data }) => {
  const [techData, setTechData] = useState();
  const { i18n } = useTranslation();
  const getData = async () => {
    try {
      const response = await axios({
        url:
          API_BASE_URL +
          i18n.language +
          `/estimate/dataCategory/${
            i18n.language === "tr" ? `listDataTr/` : "listDataEn/"
          }`,
        method: "GET",
      });
      setTechData(response.data);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const handleChange = (id) => {
    if (!requestData?.[8]?.find((item) => item === id)) {
      let temp = [...requestData];
      if (!temp[8]) temp[8] = [id];
      else temp[8] = [...temp[8], id];
      setRequestData(temp);
    } else {
      let temp = [...requestData];
      let index = temp[8].indexOf(id);
      if (index > -1) {
        temp[8].splice(index, 1);
      }
      setRequestData(temp);
    }
  };
  useEffect(() => {
    getData();
  }, [i18n.language]);
  return (
    <Grid
      sx={{ width: "100%" }}
      container
      xl={12}
      style={{ margin: "0rem auto 0 auto" }}
    >
      <Container
        container
        style={{
          marginTop: "4rem",
          width: "100%",
          borderBottom: "2px solid #194591",
        }}
      >
        <img src={slash} alt="slash" />
        <h1 style={{ color: "#194591", fontSize: "30px", marginLeft: "1rem" }}>
          {data?.name}
        </h1>
      </Container>
      <Grid sx={{ width: "100%" }}>
        <div style={{ marginTop: "2.5rem", marginBottom: "2rem" }}>
          <span style={{ color: "#194591", fontSize: "18px" }}>
            {data?.sub_steps?.[0].name}
          </span>
        </div>
        <Grid container style={{ display: "flex", flexDirection: "row" }}>
          <Grid
            item
            lg={3}
            md={4}
            sm={4}
            xs={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span
              style={{
                color: "#194591",
                fontSize: "14px",
                marginBottom: "1.5rem",
              }}
            >
              Backend :
            </span>
            {techData?.[0]?.data_category.map((item, index) => (
              <Box
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "10px",
                }}
                onClick={() => handleChange(item?.id)}
              >
                <Box>
                  <img
                    src={
                      requestData?.[8]?.some((e) => e === item.id)
                        ? selectedInput
                        : input
                    }
                    alt="image"
                  />
                </Box>
                <span
                  style={{
                    margin: "0px 0 0 10px",
                    color: requestData?.[8]?.some((e) => e === item.id)
                      ? "#FF744C"
                      : "#194591",
                  }}
                >
                  {item.name}
                </span>
              </Box>
            ))}
          </Grid>
          <Grid
            item
            lg={3}
            md={4}
            sm={4}
            xs={6}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span
              style={{
                color: "#194591",
                fontSize: "14px",
                marginBottom: "1.5rem",
              }}
            >
              Frontend :
            </span>
            {techData?.[1]?.data_category.map((item, index) => (
              <Box
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "10px",
                }}
                onClick={() => handleChange(item?.id)}
              >
                <Box>
                  <img
                    src={
                      requestData?.[8]?.some((e) => e === item.id)
                        ? selectedInput
                        : input
                    }
                    alt="image"
                  />
                </Box>
                <span
                  style={{
                    margin: "0px 0 0 10px",
                    color: requestData?.[8]?.some((e) => e === item.id)
                      ? "#FF744C"
                      : "#194591",
                  }}
                >
                  {item.name}
                </span>
              </Box>
            ))}
          </Grid>
          <Grid item lg={3} md={4} sm={4} xs={6}>
            <Box
              onClick={() =>
                handleChange(techData?.[2]?.data_category?.[0]?.id)
              }
              style={{ display: "flex", alignItems: "center" }}
            >
              <img
                src={
                  requestData?.[8]?.some(
                    (e) => e === techData?.[2]?.data_category?.[0]?.id
                  )
                    ? selectedInput
                    : input
                }
                alt="image"
              />
              <span
                style={{
                  color: requestData?.[8]?.some(
                    (e) => e === techData?.[2]?.data_category?.[0]?.id
                  )
                    ? "#FF744C"
                    : "#194591",
                  marginLeft: 9,
                }}
              >
                {techData?.[2]?.data_category[0].name}
              </span>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Technologies;
