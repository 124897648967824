import { Box, Grid } from "@mui/material";
import styled from "styled-components";
import CustomTypography from "../CustomTypography";

export const Wrapper = styled(Box)`
  padding-bottom: 1rem;
  width: 100%;
  display: flex;

  .slick-slide > div {
    /* margin: 0 4.5rem 0 -3.5rem; */
    margin: 0 0 0 2rem;
  }
`;

export const Container = styled(Box)`
  width: 100%;
  .slick-slider {
    margin-bottom: 2rem;
  }
  .slick-dots li {
    width: 1rem;
  }

  .slick-dots li button::before {
    color: #c4c4c4;
    font-size: 1rem;
    opacity: 1;
  }

  .slick-dots li.slick-active button::before {
    color: #194591;
    opacity: 1;
  }
  .slick-dots li {
    margin: 0;
    padding: 0;
  }
  .slick-dots {
    list-style-type: circle;
    width: fit-content;
    margin-top: 2rem;
    margin-left: 31px;
    bottom: -57px;
  }
`;

export const Card = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  text-align: left;
  border: 1px solid white;
  border-radius: 0.5rem;
  background-color: #fff !important;
  box-shadow: ${({ theme }) => theme.main.shadowCard};
`;

export const SubTitle = styled(CustomTypography)`
  font-family: Open Sans;
  font-size: 1rem;
  font-weight: bold;
  color: #ff744c;
  margin-bottom: 0;
`;

export const SubText = styled(CustomTypography)`
  align-items: flex-start;
  font-family: Open Sans;
  font-size: 12px;
  margin-top: 12px;
  color: #194591;
`;
