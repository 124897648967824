import { useEffect, useRef } from "react";
import Slider from "react-slick";
import { Container, Wrapper, Card, SubTitle, SubText } from "./style";
import SliderArrows from "../SliderArrows";

const BenefitCarousel = ({ benefitsData }) => {
  const settings = {
    className: "center",
    infinite: false,
    slidesToShow: 3,
    dots: true,
    dotsClass: "slick-dots",
    arrows: false,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          className: "center",
          infinite: false,
          slidesToShow: 2,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          className: "center",
          infinite: false,
          initialSlide: 4,
          slidesToShow: 1.3,
          dots: true,
          dotsClass: "slick-dots",
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const slider = useRef();
  useEffect(() => {
    slider?.current.slickGoTo(0, true);
  }, [benefitsData]);
  return (
    <Wrapper>
      <Container>
        <Slider ref={slider} {...settings}>
          {benefitsData?.map((item, index) => (
            <Card sx={{ height: "167px !important" }} key={index}>
              <SubTitle> {item.title} </SubTitle>
              <SubText variant="smallText">{item.description}</SubText>
            </Card>
          ))}
        </Slider>
        <SliderArrows slider={slider} />
      </Container>
    </Wrapper>
  );
};

export default BenefitCarousel;
